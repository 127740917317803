import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import MenuNavigator from "../../../components/MenuNavigator";
import ImageUploadBtn from "../../../components/ImageUploadBtn";
import { addNewFabric, getAllCatalogues } from "../../../api/api";

const AddNewFabric = () => {
  // const [fabricType, setfabricType] = useState('');
  const [selectedCatalogues, setSelectedCatalogues] = useState();
  const fabricTypeOptions = ["Upholstry", "Sheers", "Curtains", "Contract"];
  const [fabricId, setFabricId] = useState("");
  const [catalogueOptions, setCatalogueOptions] = useState([]);
  const [fabricImage, setFabricImage] = useState("");
  const navigate = useNavigate();
  const toast = useRef(null);

  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Success!",
      detail: "Fabric Added",
    });
  };
  const showFailure = () => {
    toast.current.show({
      severity: "error",
      summary: "Server Issue!",
      detail: "Server Issue, Please Try Again",
    });
  };

  const [formData, setFormData] = useState({});

  useEffect(() => {
    getAllCatalogues()
      .then((data) => {
        console.log("first", data);
        return data?.allCatalogues?.map((item) => ({
          catalogueId: item._id,
          catalogueName: item.name,
        }));
      })
      .then((data) => {
        setCatalogueOptions(data);
        console.log("then", data);
      });
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      URL: "www.google.com",
      collectionName: "",
      color: "",
      composition: "",
      quality: "",
      fabricWidth: "",
      weight: "",
      patternRepeat: "",
      fabricCare: "",
      usage: "",
      catalogueAffiliation: [],
      architectCutLengthPrice: "",
      architectRollLengthPrice: "",
      retailerCutLengthPrice: "",
      retailerRollLengthPrice: "",
      channelPartnerCutLengthPrice: "",
      channelPartnerRollLengthPrice: "",
      fabricType: "",
      HSN: "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.name) {
        errors.name = "This field is mandatory";
      }
      if (!data.collectionName) {
        errors.collectionName = "This field is mandatory";
      }
      if (!data.color) {
        errors.color = "This field is mandatory";
      }
      if (!data.composition) {
        errors.composition = "This field is mandatory";
      }
      if (!data.quality) {
        errors.quality = "This field is mandatory";
      }
      if (!data.fabricWidth) {
        errors.fabricWidth = "This field is mandatory";
      }
      if (!data.weight) {
        errors.weight = "This field is mandatory";
      }
      if (!data.HSN) {
        errors.HSN = "This field is mandatory";
      }
      if (!data.patternRepeat) {
        errors.patternRepeat = "This field is mandatory";
      }
      if (!data.fabricCare) {
        errors.fabricCare = "This field is mandatory";
      }
      if (!data.usage) {
        errors.usage = "This field is mandatory";
      }
      if (!data.architectCutLengthPrice) {
        errors.architectCutLengthPrice = "This field is mandatory";
      }
      if (!data.architectRollLengthPrice) {
        errors.architectRollLengthPrice = "This field is mandatory";
      }
      if (!data.retailerCutLengthPrice) {
        errors.retailerCutLengthPrice = "This field is mandatory";
      }
      if (!data.retailerRollLengthPrice) {
        errors.retailerRollLengthPrice = "This field is mandatory";
      }
      if (!data.channelPartnerCutLengthPrice) {
        errors.channelPartnerCutLengthPrice = "This field is mandatory";
      }
      if (!data.channelPartnerRollLengthPrice) {
        errors.channelPartnerRollLengthPrice = "This field is mandatory";
      }
      if (!data.fabricType) {
        errors.fabricType = "This field is mandatory";
      }
      return errors;
    },
    onSubmit: (data) => {
      // console.log("data :", {
      //   ...data,
      //   catalogueAffiliation: data.catalogueAffiliation.map(
      //     (catalogue) => catalogue.catalogueId
      //   ),
      // });

      if (fabricImage === "") {
        toast.current.show({
          severity: "error",
          summary: "Please select Fabric image!",
          // detail: "Server Issue, Please Try Again",
        });
      } else {
        setFormData(data);
        let formData = new FormData();
        data.catalogueAffiliation.map((catalogue) =>
          formData.append("catalogueAffiliation", catalogue.catalogueId)
        );
        formData.append("name", data.name);
        formData.append("URL", data.URL);
        formData.append("collectionName", data.collectionName);
        formData.append("color", data.color);
        formData.append("composition", data.composition);
        formData.append("quality", data.quality);
        formData.append("fabricWidth", data.fabricWidth);
        formData.append("weight", data.weight);
        formData.append("patternRepeat", data.patternRepeat);
        formData.append("fabricCare", data.fabricCare);
        formData.append("usage", data.usage);
        formData.append(
          "architectCutLengthPrice",
          data.architectCutLengthPrice
        );
        formData.append(
          "architectRollLengthPrice",
          data.architectRollLengthPrice
        );
        formData.append("retailerCutLengthPrice", data.retailerCutLengthPrice);
        formData.append(
          "retailerRollLengthPrice",
          data.retailerRollLengthPrice
        );
        formData.append(
          "channelPartnerCutLengthPrice",
          data.channelPartnerCutLengthPrice
        );
        formData.append(
          "channelPartnerRollLengthPrice",
          data.channelPartnerRollLengthPrice
        );
        formData.append("fabricType", data.fabricType);
        formData.append("HSN", data.HSN);
        formData.append("image", fabricImage, fabricImage.name);
        // addNewFabric({
        //   ...data,
        //   catalogueAffiliation: data.catalogueAffiliation.map(
        //     (catalogue) => catalogue.catalogueId
        //   ),
        // })
        addNewFabric(formData)
          .then((data) => {
            console.log(data);
            if (data.name) {
              // if (fabricImage !== "") {
              //   console.log("fabData", data);
              //   uploadFabricImage(data._id);
              // } else showSuccess();
              // setFabricId(data._id);
              showSuccess();
              setTimeout(() => {
                navigate("/fabrics");
              }, 1000);
              // formik.resetForm();
            } else showFailure();
          })
          .catch((err) => {
            console.log("err", err?.response?.data);
          });
        formik.resetForm();
      }
    },
  });

  const catalogueAffiliationHandler = (e) => {
    setSelectedCatalogues(e.value);
    formik.handleChange(e);
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const uploadFabricImage = async (id) => {
    // console.log("click");
    const fd = new FormData();
    fd.append("image", fabricImage.file, fabricImage.file.name);
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/fabrics/upload/638ef363655aca386eec2c28`,
      {
        method: "POST",
        headers: {
          Authorization: window.localStorage.getItem("userId"),
        },
        body: fd,
      }
    );
    console.log("fab", response);

    if (response.status === 200 && response.ok === true) {
      // setLoading(false);
      showSuccess();
      // message.success("Successfully Uploaded", 2);
    } else {
      // setLoading(false);
      // message.error("Error Uploading File", 2);
      showFailure();
    }
  };

  return (
    <div>
      <MenuNavigator />
      <Divider align="center">
        <Toast ref={toast}></Toast>
        <h2 className="p-text-center">Add New Fabric</h2>
      </Divider>
      <form onSubmit={formik.handleSubmit} className="p-fluid">
        <div
          className="p-fluid"
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <div style={{ width: "35%" }}>
            <div className="p-field">
              <label htmlFor="name">
                Design Name<span style={{ color: "red" }}> *</span>
              </label>
              <InputText
                id="name"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("name"),
                })}
              />
              {getFormErrorMessage("name")}
            </div>

            <div className="p-field">
              <label htmlFor="collectionName">
                Collection Name<span style={{ color: "red" }}> *</span>
              </label>
              <InputText
                id="collectionName"
                type="text"
                value={formik.values.collectionName}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("collectionName"),
                })}
              />
              {getFormErrorMessage("collectionName")}
            </div>

            <div className="p-field">
              <label htmlFor="color">
                Colour<span style={{ color: "red" }}> *</span>
              </label>
              <InputText
                id="color"
                type="text"
                value={formik.values.color}
                onChange={formik.handleChange}
                className={classNames({ "p-error": isFormFieldValid("color") })}
              />
              {getFormErrorMessage("color")}
            </div>

            <div className="p-field">
              <label htmlFor="composition">
                Composition<span style={{ color: "red" }}> *</span>
              </label>
              <InputText
                id="composition"
                type="text"
                value={formik.values.composition}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("composition"),
                })}
              />
              {getFormErrorMessage("composition")}
            </div>

            <div className="p-field">
              <label htmlFor="quality">
                Quality<span style={{ color: "red" }}> *</span>
              </label>
              <InputText
                id="quality"
                type="text"
                value={formik.values.quality}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("quality"),
                })}
              />
              {getFormErrorMessage("quality")}
            </div>
            <div className="p-field">
              <label htmlFor="fabricWidth">
                Fabric Width (cm)<span style={{ color: "red" }}> *</span>
              </label>
              <InputText
                id="fabricWidth"
                type="text"
                value={formik.values.fabricWidth}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("fabricWidth"),
                })}
              />
              {getFormErrorMessage("fabricWidth")}
            </div>
            <div className="p-field">
              <label htmlFor="weight">
                Weight (GLM)<span style={{ color: "red" }}> *</span>
              </label>
              <InputText
                id="weight"
                type="text"
                value={formik.values.weight}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("weight"),
                })}
              />
              {getFormErrorMessage("weight")}
            </div>
            <div className="p-field">
              <label htmlFor="patternRepeat">
                Pattern Repeat (cm)<span style={{ color: "red" }}> *</span>
              </label>
              <InputText
                id="patternRepeat"
                type="text"
                value={formik.values.patternRepeat}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("patternRepeat"),
                })}
              />
              {getFormErrorMessage("patternRepeat")}
            </div>
          </div>
          <div style={{ width: "35%" }}>
            <div className="p-field">
              <label htmlFor="fabricCare">
                Fabric Care<span style={{ color: "red" }}> *</span>
              </label>
              <InputText
                id="fabricCare"
                type="text"
                value={formik.values.fabricCare}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("fabricCare"),
                })}
              />
              {getFormErrorMessage("fabricCare")}
            </div>
            <div className="p-field">
              <label htmlFor="usage">
                Usage<span style={{ color: "red" }}> *</span>
              </label>
              <InputText
                id="usage"
                type="text"
                value={formik.values.usage}
                onChange={formik.handleChange}
                className={classNames({ "p-error": isFormFieldValid("usage") })}
              />
              {getFormErrorMessage("usage")}
            </div>
            <div className="p-field">
              <label htmlFor="HSN">
                HSN<span style={{ color: "red" }}> *</span>
              </label>
              <InputText
                id="HSN"
                type="text"
                value={formik.values.HSN}
                onChange={formik.handleChange}
                className={classNames({ "p-error": isFormFieldValid("HSN") })}
              />
              {getFormErrorMessage("HSN")}
            </div>

            <div className="p-field">
              <label htmlFor="catalogueAffiliation">
                Catalogue Affiliation
              </label>
              <MultiSelect
                // value={selectedCatalogues}
                options={catalogueOptions}
                optionLabel="catalogueName"
                id="catalogueAffiliation"
                name="catalogueAffiliation"
                value={formik.values.catalogueAffiliation}
                onChange={formik.handleChange}
                // className={classNames({
                //   'p-error': isFormFieldValid('catalogueAffiliation'),
                // })}
                placeholder="Select a Catalogue"
                display="chip"
              />
              {getFormErrorMessage("catalogueAffiliation")}
            </div>
            <div className="p-field">
              <label htmlFor="architectFabricPrice">
                Architect Fabric Pricing<span style={{ color: "red" }}> *</span>
              </label>
              <div styles={{ display: "flex" }}>
                <InputText
                  id="architectCutLengthPrice"
                  placeholder="Cut Length Price"
                  type="number"
                  value={formik.values.architectCutLengthPrice}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-error": isFormFieldValid("architectCutLengthPrice"),
                  })}
                  min={0}
                  style={{ width: "50%" }}
                />
                <InputText
                  id="architectRollLengthPrice"
                  placeholder="Roll Length Price"
                  type="number"
                  value={formik.values.architectRollLengthPrice}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-error": isFormFieldValid("architectRollLengthPrice"),
                  })}
                  min={0}
                  style={{ width: "50%" }}
                />
              </div>
              {getFormErrorMessage("architectFabricPrice")}
            </div>
            <div className="p-field">
              <label htmlFor="retailerFabricPrice">
                Retailer Fabric Pricing<span style={{ color: "red" }}> *</span>
              </label>
              <div styles={{ display: "flex" }}>
                <InputText
                  id="retailerCutLengthPrice"
                  placeholder="Cut Length Price"
                  type="number"
                  value={formik.values.retailerCutLengthPrice}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-error": isFormFieldValid("retailerCutLengthPrice"),
                  })}
                  min={0}
                  style={{ width: "50%" }}
                />
                <InputText
                  id="retailerRollLengthPrice"
                  placeholder="Roll Length Price"
                  type="number"
                  value={formik.values.retailerRollLengthPrice}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-error": isFormFieldValid("retailerRollLengthPrice"),
                  })}
                  min={0}
                  style={{ width: "50%" }}
                />
              </div>
              {getFormErrorMessage("retailerFabricPrice")}
            </div>
            <div className="p-field">
              <label htmlFor="channelPartnerFabricPrice">
                Channel Partner Fabric Pricing
                <span style={{ color: "red" }}> *</span>
              </label>
              <div styles={{ display: "flex" }}>
                <InputText
                  id="channelPartnerCutLengthPrice"
                  placeholder="Cut Length Price"
                  type="number"
                  value={formik.values.channelPartnerCutLengthPrice}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-error": isFormFieldValid("channelPartnerCutLengthPrice"),
                  })}
                  min={0}
                  style={{ width: "50%" }}
                />
                <InputText
                  id="channelPartnerRollLengthPrice"
                  placeholder="Roll Length Price"
                  type="number"
                  value={formik.values.channelPartnerRollLengthPrice}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-error": isFormFieldValid(
                      "channelPartnerRollLengthPrice"
                    ),
                  })}
                  min={0}
                  style={{ width: "50%" }}
                />
              </div>
              {getFormErrorMessage("channelPartnerFabricPrice")}
            </div>
            {/* <div className="p-field">
            <label htmlFor="staffFabricPrice">Staff Fabric Pricing</label>
            <InputText id="staffFabricPrice" type="text" />
          </div> */}
            <div className="p-field">
              <label htmlFor="fabricType">
                Fabric Type<span style={{ color: "red" }}> *</span>
              </label>
              {/* <InputText id="creditPeriod" type="text" /> */}
              <Dropdown
                id="fabricType"
                // value={fabricType}
                options={fabricTypeOptions}
                // onChange={(e) => setfabricType(e.value)}
                placeholder="Select Type of Fabric"
                name="fabricType"
                value={formik.values.fabricType}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("fabricType"),
                })}
              />
              {getFormErrorMessage("fabricType")}
            </div>
          </div>
        </div>
        <div
          style={{ textAlign: "center", marginBottom: "100px" }}
          className="p-field"
        >
          <label style={{ fontSize: "25px" }} htmlFor="uploadCatalogueImage">
            Upload Fabric Image
          </label>
          <ImageUploadBtn
            txt="Select Image"
            intent="fabrics"
            imageUploaded
            id={fabricId}
            setEvent={setFabricImage}
          />
        </div>
        <div style={{ textAlign: "center", margin: "30px 0px" }}>
          <Button
            type="submit"
            // type="button"
            // onClick={uploadFabricImage}
            style={{ width: "20%", background: "#287F80" }}
            label="Add New Fabric"
            className="p-block text-center"
            // disabled={!(formik.isValid && formik.dirty)}
          ></Button>
        </div>
      </form>

      {/* <Divider align="center">
        <Toast ref={toast}></Toast>
        <h2 className="p-text-center">Upload Image</h2>
      </Divider>
      <div
        style={{ textAlign: "center", marginBottom: "100px" }}
        className="p-field"
      >
        <label style={{ fontSize: "25px" }} htmlFor="uploadCatalogueImage">
          Upload Fabric Image
        </label>
        <ImageUploadBtn
          txt="Select Image"
          intent="fabrics"
          imageUploaded
          id={fabricId}
          setEvent={setFabricImage}
        />
      </div> */}
    </div>
  );
};

export default AddNewFabric;
