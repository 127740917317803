import React from "react";
import { useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import {
  incrementCatelogueQuantity,
  decrementCatelogueQuantity,
} from "../../../../redux/cart/cartSlice";

function CataloguesCart({ cateloguesTotal }) {
  const dispatch = useDispatch();
  const currentUser = JSON.parse(window.localStorage.getItem("userDetails"));
  const cateloguesCart = useSelector(
    (state) => state.persistedReducer.cartReducer.catelogues
  );
  console.log(cateloguesCart);
  const quantityBody = (data) => {
    return (
      <div className="flex justify-content-center align-items-center">
        <Button
          onClick={() => dispatch(decrementCatelogueQuantity(data))}
          className="mx-2 border-circle p-button-outlined"
          label="-"
        ></Button>
        <div className="text-xl mx-1 font-bold">{data.qty}</div>
        <Button
          onClick={() => dispatch(incrementCatelogueQuantity(data))}
          className="mx-2 border-circle p-button-outlined"
          label="+"
        ></Button>
      </div>
    );
  };
  const sum = cateloguesCart.reduce((accumulator, object) => {
    return accumulator + object.totalPriceWithQty;
  }, 0);
  const costBody = (data) => {
    return (
      <div className="flex justify-content-center align-items-center font-bold">
        Rs {data.totalPriceWithQty}
      </div>
    );
  };
  const totalProductAmount = () => {
    const discount = parseFloat(currentUser.discountPercentage) / 100;

    const discountedPrice = sum - sum * discount;
    return Number(discountedPrice);
  };

  const totalProductAmountWithGST = () => {
    let productAmount = totalProductAmount();
    cateloguesTotal(Math.round(productAmount + productAmount * 0.12));
    return Math.round(productAmount + productAmount * 0.12);
  };
  let footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Total:" colSpan={3} />
        <Column footerClassName="text-center" footer={sum} />
      </Row>
      <Row>
        <Column
          footer={`Total with Discount (${parseFloat(
            currentUser.discountPercentage
          )})%`}
          colSpan={3}
        />
        <Column
          footerClassName="text-center"
          footer={Math.round(totalProductAmount())}
        />
      </Row>
      <Row>
        <Column footer="Total with GST (12%):" colSpan={3} />
        <Column
          footerClassName="text-center"
          footer={totalProductAmountWithGST()}
        />
      </Row>
    </ColumnGroup>
  );
  return (
    <div>
      {cateloguesCart.length !== 0 && (
        <DataTable
          footerColumnGroup={footerGroup}
          size="small"
          className="m-3"
          colSpan={11}
          showGridlines
          value={cateloguesCart}
        >
          <Column field="name" header="Catelogue Name"></Column>
          <Column
            headerStyle={{ width: "300px" }}
            field="HSN"
            header="Catelogue HSN"
          ></Column>
          <Column
            headerStyle={{ width: "300px" }}
            body={quantityBody}
            header="Quantity"
          ></Column>
          <Column
            headerStyle={{ width: "200px" }}
            body={costBody}
            header="Cost"
          ></Column>
        </DataTable>
      )}
    </div>
  );
}

export default CataloguesCart;
