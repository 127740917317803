import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Divider } from "primereact/divider";
import { Paginator } from "primereact/paginator";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import MenuNavigator from "../../../components/MenuNavigator";
import { getMyOrders } from "../../../api/api";

function MyFullfilledOrders() {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [ordersList, setOrdersList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const navigate = useNavigate();
  // console.log({ "state": ordersList })

  useEffect(() => {
    getMyOrders().then((data) => {
      console.log(data);
      setOrdersList(data.orders);
      setDataCount(data.orderCount);
    });
  }, []);

  const productType = (rowData) => {
    if ("fabric" in rowData) {
      return "Fabric";
    } else if ("catalogue" in rowData) {
      return "Catalogue";
    } else {
      return "MTM";
    }
  };

  const payNowTemplate = (rowData) => {
    if (rowData.orderType === "credit") {
      return (
        <span
          style={{
            backgroundColor: "#434343",
            color: "white",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          Credit
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: "#6a11cb",
            color: "white",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          Paid
        </span>
      );
    }
  };

  const productTemplate = (rowData) => {
    if (productType(rowData) === "Fabric") {
      return (
        <div style={{ backgroundColor: "#ECCFFF" }} className="p-1 w-full">
          <span
            style={{
              color: "#694382",
              textTransform: "uppercase",
              padding: ".25em 1rem",
              borderRadius: "3px",
              fontWeight: "700",
              letterSpacing: ".3px",
            }}
          >
            {productType(rowData)}
          </span>
        </div>
      );
    } else if (productType(rowData) === "Catalogue") {
      return (
        <div style={{ backgroundColor: "#FFD8B2" }} className="p-1 w-full">
          <span
            style={{
              color: "#805B36",
              textTransform: "uppercase",
              padding: ".25em 1rem",
              borderRadius: "3px",
              fontWeight: "700",
              letterSpacing: ".3px",
            }}
          >
            {productType(rowData)}
          </span>
        </div>
      );
    } else {
      return (
        <div className="p-1 w-full" style={{ backgroundColor: "#B3E5FC" }}>
          <span
            style={{
              color: "#23547B",
              textTransform: "uppercase",
              padding: ".25em 1rem",
              borderRadius: "3px",
              fontWeight: "700",
              letterSpacing: ".3px",
            }}
          >
            {productType(rowData)}
          </span>
        </div>
      );
    }
  };
  const fullfilledTemplate = (rowData) => {
    if (rowData.trackingId) {
      return (
        <span
          style={{
            backgroundColor: "#FFD8B2",
            color: "#805B36",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          FullFilled
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: "#B3E5FC",
            color: "#23547B",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          In Progress
        </span>
      );
    }
  };
  const totalAmmountTemplete = (rowData) => {
    return Number(rowData?.totalAmount).toFixed(2);
  };

  const handlePageClick = async (e) => {
    setFirst(e.first);
    setRows(e.rows);
    let currentPage = e.page + 1;
    // console.log(currentPage)
    let data = await getMyOrders(currentPage);
    setOrdersList(data.orders);
  };

  return (
    <div>
      <MenuNavigator />
      <Divider align="center">
        <h2 className="p-text-center">FullFilled Orders</h2>
      </Divider>
      <DataTable
        className="mx-4"
        showGridlines
        value={ordersList
          .filter((obj) => {
            if (obj.trackingId) {
              return obj;
            }
          })
          .sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          })}
        onRowClick={(e) => {
          console.log(e.data);
          if (e.data.name === "fabric") {
            navigate("/fabricOrderDetail", { state: e.data });
          } else if (e.data.name === "catalogue") {
            navigate("/catalogueOrderDetail", { state: e.data });
          } else {
            navigate("/MTMOrderDetail", { state: e.data });
          }
        }}
        selectionMode="single"
      >
        <Column field="companyName" header="Company Name"></Column>
        <Column
          headerStyle={{ textAlign: "center" }}
          bodyStyle={{ textAlign: "center", width: "4rem" }}
          body={productTemplate}
          header="Product"
        ></Column>
        <Column field="contactNumber" header="Contact Number"></Column>
        <Column field="modeOfTransport" header="Mode Of Transport"></Column>
        <Column field="pincode" header="Pincode"></Column>
        <Column header="Total Amount" body={totalAmmountTemplete}></Column>
        <Column field="qty" header="Quantity"></Column>
        <Column field="paymentId" header="Payment ID"></Column>
        <Column
          bodyStyle={{ textAlign: "center" }}
          header="Payment Type"
          body={payNowTemplate}
        ></Column>
        <Column
          bodyStyle={{ textAlign: "center" }}
          body={fullfilledTemplate}
          header="Fullfilled Status"
        ></Column>
      </DataTable>
      <Paginator
        rows={rows}
        totalRecords={dataCount}
        first={first}
        onPageChange={handlePageClick}
      ></Paginator>
    </div>
  );
}

export default MyFullfilledOrders;
