import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { useNavigate } from "react-router-dom";
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";

import MenuNavigator from "../../../components/MenuNavigator";

import {
  getAllCatalogues,
  getAllFabrics,
  getAllUserList,
  getAllUsers,
  getCatalogueDetails,
  getCatalogues,
  getFabricDetails,
  getFabrics,
  getMtmDetails,
  getUserDetails,
} from "../../../api/api";
import FormList from "antd/lib/form/FormList";

const AdminMTMO = () => {
  const navigate = useNavigate();
  const [tableValues, setTableValues] = useState([]);

  const currentUser = JSON.parse(window.localStorage.getItem("userDetails"));
  const [fabPrice, setFabPrice] = useState(0);
  const [ArrayOfFabrics, setAllFabrics] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [checked, setChecked] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [stitchPrice, setStitchPrice] = useState("0");
  const [instalationPrice, setInstalationPrice] = useState("0");
  const [added, setAdded] = useState(false);
  const [discountPer, setDiscountPer] = useState(0);
  const [userData, setUserData] = useState(null);

  const [catalogueTableFieldOptions, setcatalogueTableFieldOptions] = useState(
    []
  );

  const [fabricTableFieldOptions, setFabricTableFieldOptions] = useState([]);

  const formik = useFormik({
    initialValues: {
      id: (Math.random() + 1).toString(36).substring(7),
      sl: "",
      roomType: "",
      window: "",
      catalogue: "",
      fabric: "",
      accessory: "",
      remark: "",
      stitch: "",
      lining: "",
      type: "",
      fullness: "",
      measurement: "",
      height: "",
      width: "",
      panelHeight: "0",
      perPanelQuantity: "0",
      noOFPanel: "0",
      quantity: 0,
      price: 0,
      discount: 0,
      installationCost: "0",
      installationType: "",
      cost: 0,
      liningCost: 0,
      stitchCost: 0,
      userType: "",
      user: "",
    },

    validate: (data) => {
      let errors = {};

      if (!data.sl) {
        errors.sl = "Required";
      }
      if (!data.roomType) {
        errors.roomType = "Required";
      }
      if (!data.window) {
        errors.window = "Required";
      }
      if (!data.catalogue) {
        errors.catalogue = "Required";
      }
      if (!data.fabric) {
        errors.fabric = "Required";
      }
      if (!data.stitch) {
        errors.stitch = "Required";
      }
      // if (!data.accessory) {
      //   errors.accessory = "Required";
      // }

      // if (!data.stitchPrice) {
      //   errors.stitchPrice = "Required";
      // }
      if (!data.lining) {
        errors.lining = "Required";
      }
      if (!data.type) {
        errors.type = "Required";
      }
      if (!data.height) {
        errors.height = "Required";
      }
      if (!data.width) {
        errors.width = "Required";
      }
      if (!data.fullness) {
        errors.fullness = "Required";
      }
      if (!data.measurement) {
        errors.measurement = "Required";
      }
      if (!data.installationCost) {
        errors.installationCost = "Required";
      }
      if (!data.installationType) {
        errors.installationType = "Required";
      }
      // if (!data.installationPrice) {
      //   errors.installationPrice = "Required";
      // }
      if (!data.stitchCost) {
        errors.stitchCost = "Required";
      }
      if (!data.userType) {
        errors.userType = "Required";
      }
      if (!data.user) {
        errors.user = "Required";
      }

      return errors;
    },
    onSubmit: (data) => {
      console.log("submitted", data);
      const { user, ...nData } = data;
      const val = {
        ...nData,
        installationPrice: instalationPrice,
        stitchPrice: stitchPrice,
      };
      console.log("submitted", val);
      setTableValues([...tableValues, val]);
      formik.handleReset();
      setFabPrice(0);
      setInstalationPrice("0");
      setStitchPrice("0");
    },
  });
  // console.log(tableValues);
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const usertype = formik.values.userType.concat("FabricPrice");
  const roomTableFieldOptions = [
    "Foyer",
    "G F L V H",
    "Mezznine F L V H",
    "1st F L V H",
    "2nd F L V H",
    "Formal L V H",
    "Informal L V H",
    "D N H",
    "P B R",
    "M B R",
    "S B R",
    "D B R",
    "K B R 1",
    "K B R 2",
    "Home Theatre",
    "GYM Room",
    "Bar",
    "Office",
    "G B R",
    "Kitchen",
    "Party Room",
    "Conference Room",
    "Gazebo Room",
    "Steam Room",
    "Stairs",
    "Cabin",
    "Corridor",
    "Suits Room",
    "Luxe Room",
    "Dressing Room",
    "Board Room",
    "Electric Room",
    "H R Room",
    "Manager Room",
    "Store Room",
  ];

  const windowTableFieldOptions = [
    "W-1",
    "W-2",
    "W-3",
    "W-4",
    "W-5",
    "W-6",
    "W-7",
    "W-8",
    "W-9",
    "W-10",
    "W-11",
    "W-12",
    "W-13",
    "W-14",
    "W-15",
    "W-16",
    "W-17",
    "W-18",
    "W-19",
    "W-20",
    "W-21",
    "W-22",
    "W-23",
    "W-24",
    "W-25",
    "W-26",
    "W-27",
    "W-28",
    "W-29",
    "W-30",
    "W-31",
    "W-32",
    "W-33",
    "W-34",
    "W-35",
    "W-36",
    "W-37",
    "W-38",
    "W-39",
    "W-40",
    "W-41",
    "W-42",
    "W-43",
    "W-44",
    "W-45",
    "W-46",
    "W-47",
    "W-48",
    "W-49",
    "W-50",
  ];
  const accessoryTableFieldOptions = ["M track", "Soundless", "SS rod"];
  const stitchTableFieldOptions = [
    "Eyelet",
    "Pleated",
    "Eliza",
    "Roman Blinds",
    "Blind System",
  ];
  const liningTableFieldOptions = [
    "None",
    "Liva Dimout",
    "Dimout",
    "Satin",
    "3 Pass Blackout",
  ];
  const installationType = ["In Bangalore", "Outside Bangalore"];
  const typeTableFieldOptions = ["Main", "Shear"];
  const fullnessTableFieldOptions = ["200", "250"];
  const measurementTableFieldOptions = ["in", "cm", "mm"];
  useEffect(() => {
    getCatalogues()
      .then((data) => {
        return data.allCatalogues.map((catalogue) => catalogue);
      })
      .then((catalogueNames) => {
        setcatalogueTableFieldOptions(catalogueNames);
      });
    getFabric();
  }, []);
  const getFabric = async () => {
    getFabrics()
      .then((data) => {
        // console.log("fabData", data.allFabrics);
        setAllFabrics(data.allFabrics);
        return data.allFabrics.map((fabric) => fabric);
      })
      .then((fabricNames) => {
        console.log("fabName", fabricNames);
        setFabricTableFieldOptions(fabricNames);
      });
  };
  const getFabricunderCatalogue = async (id) => {
    getCatalogueDetails(id)
      .then((data) => {
        setAllFabrics(data.fabricAffiliation);
        return data.fabricAffiliation.map((fabric) => fabric);
      })
      .then((fabricNames) => {
        setFabricTableFieldOptions(fabricNames);
        // if (fabricNames.length === 0) {
        //   getFabrics();
        // } else {
        //   setFabricTableFieldOptions(fabricNames);
        // }
      });
  };
  const getUserList = async (id) => {
    console.log("userType", id);
    getAllUserList(id)
      .then((data) => {
        console.log("data", data);
        setUserOptions(data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const setDiscount = async (id) => {
    getUserDetails(id)
      .then((res) => {
        console.log("data", res);
        setDiscountPer(res?.discountPercentage);
        setUserData(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    getFabricDetails(formik.values.fabric).then((res) => {
      // console.log("res", res);
      formik.values.price =
        res[formik.values.userType.concat("CutLengthPrice")] *
        formik.values.quantity;
      // setFabPrice(
      //   res[formik.values.userType.concat("CutLengthPrice")] *
      //     formik.values.quantity
      // );
    });
  }, [formik.values["userType"]]);
  const getStitchingPrice = async (val) => {
    const param = `mainType=Stitching&subType=${val}`;
    getMtmDetails(param)
      .then((res) => {
        console.log("mtm Details", res);
        setStitchPrice(res[0]?.price);
        // formik.values.stitchCost = res[0]?.price;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getInstallationPrice = async (val) => {
    const param = `mainType=Installation&subType=${val}`;
    getMtmDetails(param)
      .then((res) => {
        console.log("mtm Details", res[0]?.price);
        setInstalationPrice(res[0]?.price);
        // formik.values.installationCost = res[0]?.price;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const slnoFieldTemplate = (rowData, index) => {
    return index.rowIndex + 1;
  };

  // const onChangeHandler = (rowId, type, value) => {
  //   const row = formik.values[rowId];
  //   row[type] = value;

  //   const newMadeToMeasureValues = JSON.parse(JSON.stringify(formik.values));
  //   newMadeToMeasureValues[rowId] = row;
  //   formik.values = newMadeToMeasureValues;
  //   setMadeToMeasureValues(formik.values);
  // };
  // const handleStich = () => {
  //   formik.handleChange;
  //   console.log("click");
  // };
  const showError = (type) => {
    if (formik.errors.isArray()) {
      if (formik.errors[type]) {
        return <div>{formik.errors.type}</div>;
      }
    }
  };
  const roomTableFieldTemplate = (rowData, { rowIndex }) => {
    return (
      <div>
        <Dropdown
          id="roomType"
          name="roomType"
          value={formik.values.roomType}
          options={roomTableFieldOptions}
          onChange={formik.handleChange}
          placeholder="Select a Room"
        />
        {getFormErrorMessage("roomType")}
      </div>
    );
  };

  const windowFieldTemplate = (rowData, { rowIndex }) => {
    return (
      <div>
        <Dropdown
          id="window"
          name="window"
          value={formik.values["window"]}
          options={windowTableFieldOptions}
          onChange={formik.handleChange}
          placeholder="Select a Window"
        />
        {getFormErrorMessage("window")}
      </div>
    );
  };

  const catalogueFieldTemplate = (rowData, { rowIndex }) => {
    return (
      <div>
        <Dropdown
          value={formik.values.catalogue}
          id="catalogue"
          name="catalogue"
          optionLabel="name"
          options={catalogueTableFieldOptions}
          optionValue="_id"
          onChange={formik.handleChange}
          placeholder="Select a Catalogue"
        />
        {getFormErrorMessage("catalogue")}
      </div>
    );
  };

  const fabricFieldTemplate = (rowData, { rowIndex }) => {
    return (
      <div>
        <Dropdown
          id="fabric"
          name="fabric"
          value={formik.values.fabric}
          optionLabel="name"
          options={fabricTableFieldOptions}
          optionValue="_id"
          onChange={(e) => {
            formik.handleChange(e);
            getFabPrice(e.target.value);
          }}
          placeholder="Select a Fabric"
        />
        {getFormErrorMessage("fabric")}
      </div>
    );
  };

  const accessoryFieldTemplate = (rowData, { rowIndex }) => {
    return (
      <div>
        <Dropdown
          id="accessory"
          name="accessory"
          value={formik.values["accessory"]}
          options={accessoryTableFieldOptions}
          onChange={formik.handleChange}
          placeholder="Select an Accessory"
        />
        {getFormErrorMessage("accessory")}
      </div>
    );
  };

  const remarkFieldTemplate = (rowData, { rowIndex }) => {
    // return <InputText />;
    return (
      <div>
        <InputTextarea
          id="remark"
          name="remark"
          value={formik.values["remark"]}
          onChange={formik.handleChange}
          style={{ minWidth: "20rem" }}
          rows={3}
        />
        {getFormErrorMessage("remark")}
      </div>
    );
  };

  const calculateSQT = () => {
    return (formik.values.height * formik.values.width) / 144;
  };
  const calculateRFT = () => {
    return formik.values.width / 12;
  };

  const calculateStitchCost = (calculateSQT, calculateRFT) => {
    if (formik.values.stitch === "Eyelet") {
      return 150 * formik.values.noOFPanel;
    } else if (formik.values.stitch === "Pleated") {
      return 245 * formik.values.noOFPanel;
    } else if (formik.values.stitch === "Eliza") {
      return 245 * formik.values.noOFPanel;
    } else if (formik.values.stitch === "Roman Blinds") {
      return Math.round(
        (165 * (formik.values.height * formik.values.width)) / 144
      );
    } else if (formik.values.stitch === "Blind System") {
      return Math.round(365 * (formik.values.width / 12));
    }
  };

  const stitchCostFieldTemplate = (rowData, { rowIndex }) => {
    return (
      <div>
        <InputText
          disabled
          id="stitchCost"
          style={{ width: "80px" }}
          name="stitchCost"
          value={formik.values["stitchCost"]}
          onChange={formik.handleChange}
        />
      </div>
    );
  };
  const stitchFieldTemplate = (rowData, { rowIndex }) => {
    return (
      <div>
        <Dropdown
          id="stitch"
          name="stitch"
          value={formik.values["stitch"]}
          options={stitchTableFieldOptions}
          onChange={(e) => {
            console.log("click");
            formik.handleChange(e);
          }}
          // onChange={handleStich}
          placeholder="Select a Stitch"
        />
        {getFormErrorMessage("stitch")}
      </div>
    );
  };

  const stitchPricingTemplate = (rowData, { rowIndex }) => {
    return (
      <div>
        <InputText
          id="stitchPrice"
          name="stitchPrice"
          // value={formik.values.stitchPrice}
          style={{ width: "100px" }}
          // onChange={(e) => {
          //     formik.values.panelHeight = panelHeight(e.target.value).toFixed(3)
          //     formik.values.perPanelQuantity = perPanelQuantity(formik.values.panelHeight).slice(0, 5)
          //     formik.values.quantity = formik.values.noOFPanel * formik.values.perPanelQuantity
          //     formik.handleChange(e)
          //     getFabPrice(formik.values.fabric)

          // }}
          onChange={formik.handleChange}
        />
        {getFormErrorMessage("price")}
      </div>
    );
  };

  const typeFieldTemplate = (rowData, { rowIndex }) => {
    return (
      <div>
        <Dropdown
          id="type"
          name="type"
          value={formik.values["type"]}
          options={typeTableFieldOptions}
          onChange={formik.handleChange}
          placeholder="Select a Type"
        />
        {getFormErrorMessage("type")}
      </div>
    );
  };

  const fullnessFieldTemplate = (rowData, { rowIndex }) => {
    return (
      <div>
        <Dropdown
          id="fullness"
          name="fullness"
          value={formik.values.fullness}
          options={fullnessTableFieldOptions}
          onChange={(e) => {
            formik.values.noOFPanel = Panels(
              e.target.value,
              formik.values.width
            );
            formik.handleChange(e);
          }}
          placeholder="Select Fullness"
        />
        {getFormErrorMessage("fullness")}
      </div>
    );
  };

  const measurementUnitFieldTemplate = (rowData, { rowIndex }) => {
    return (
      <div>
        <Dropdown
          id="measurement"
          name="measurement"
          value={formik.values["measurement"]}
          options={measurementTableFieldOptions}
          onChange={formik.handleChange}
          placeholder="Select Measurement Type"
        />
        {getFormErrorMessage("measurement")}
      </div>
    );
  };

  const windowHeightFieldTemplate = (rowData, { rowIndex }) => {
    return (
      <div>
        <InputText
          id="height"
          name="height"
          value={formik.values.height}
          style={{ width: "100px" }}
          onChange={(e) => {
            formik.values.panelHeight = panelHeight(e.target.value).toFixed(3);
            formik.values.perPanelQuantity = perPanelQuantity(
              formik.values.panelHeight
            ).slice(0, 5);
            formik.values.quantity =
              formik.values.noOFPanel * formik.values.perPanelQuantity;
            formik.handleChange(e);
            getFabPrice(formik.values.fabric);
          }}
        />
        {getFormErrorMessage("height")}
      </div>
    );
  };

  const windowWidthFieldTemplate = (rowData, { rowIndex }) => {
    return (
      <div>
        <InputText
          id="width"
          style={{ width: "100px" }}
          name="width"
          value={formik.values.width}
          onChange={(e) => {
            formik.values.noOFPanel = Panels(
              formik.values.fullness,
              e.target.value
            );
            formik.values.quantity =
              formik.values.noOFPanel * formik.values.perPanelQuantity;
            formik.handleChange(e);
            getFabPrice(formik.values.fabric);
          }}
        />
        {getFormErrorMessage("width")}
      </div>
    );
  };

  const totalValue = (formikValues) => {
    let total = 0;
    for (var i = 0; i < formikValues.length; i++) {
      total = total + formikValues[i].cost;
    }
    return total;
  };
  const fabricPrice = (id) => {
    if (id === "") {
      return 0;
    } else {
      // return ((ArrayOfFabrics.find(obj => obj.name === currentFabric))[usertype])
      return 0;
    }
  };
  const calculateCost = (
    perPanelQuantity,
    fabrics,
    fabricName,
    totalQuantity
  ) => {
    const fabPrice = fabricPrice(fabrics, fabricName);

    return totalQuantity * fabPrice;
  };

  const perPanelQuantity = (perPanelHeight) => {
    // const wastage = panelHeight * 0.15;

    // return wastage + panelHeight;
    const wastage = (perPanelHeight * 0.15).toFixed(4).slice(0, -1);
    const PPQ = parseFloat(wastage) + perPanelHeight;

    return roundValue(PPQ, 3);
  };
  const Panels = (fullness, width) => {
    if (fullness === "250") {
      return width / 20;
    } else if (fullness === "200") {
      return width / 25;
    } else {
      return 0;
    }
  };
  const roundValue = (value, digit = 2) => {
    if (digit === 3) {
      return Math.round(value * 1000 + Number.EPSILON) / 1000;
    } else {
      return Math.round(value * 100 + Number.EPSILON) / 100;
    }
  };
  const panelHeight = (height) => {
    return roundValue(height / 39.26 + 0.35, 3);
  };

  const totalPriceTemplate = (rowDta, { rowIndex }) => {
    return (
      <div>
        <InputText
          disabled
          value={formik.values.price}
          style={{ width: "80px" }}
          id="price"
          name="price"
          onChange={formik.handleChange}
          // value={(calculateCost(formik.values.perPanelQuantity, ArrayOfFabrics, formik.values[0] ? formik.values[0].name : "", calculateQuantity(formik.values.noOFPanel, formik.values.perPanelQuantity).toFixed(2)))}
        />
      </div>
    );
  };
  const noOfPanelsTemplate = (rowDta, { rowIndex }) => {
    return (
      <div>
        <InputText
          disabled
          value={formik.values.noOFPanel}
          style={{ width: "80px" }}
          id="noOFPanel"
          name="noOFPanel"
          onChange={(e) => {
            formik.handleChange(e);
          }}
          // value={(Panels(formik.values.fullness, formik.values.width))}
        />
      </div>
    );
  };
  const deleteButton = (rowDta, { rowIndex }) => {
    return (
      <div>
        <Button
          style={{ background: "#287F80" }}
          onClick={() => {
            console.log("first", rowIndex);
            // tableValues.splice(rowIndex, 1);
            tableValues.splice(rowIndex, 1);
            setUpdated(!updated);
          }}
          type={"button"}
        >
          Delete
        </Button>
      </div>
    );
  };
  const perPanelHeightTemplate = (rowDta, { rowIndex }) => {
    return (
      <div>
        <InputText
          disabled
          value={formik.values.panelHeight}
          style={{ width: "80px" }}
          id="panelHeight"
          name="panelHeight"
          onChange={formik.handleChange}
          // value={Number(panelHeight(formik.values.height).toFixed(3))}
        />
      </div>
    );
  };
  const discountTemplate = (rowDta, { rowIndex }) => {
    return (
      <div>
        <InputText
          disabled
          style={{ width: "80px" }}
          id="discount"
          name="discount"
          onChange={formik.handleChange}
          value={formik.values.discount}
        />
      </div>
    );
  };

  const totalCostTemplate = (rowDta, { rowIndex }) => {
    return (
      <div>
        <InputText
          disabled
          value={formik.values.cost}
          style={{ width: "80px" }}
          id="cost"
          name="cost"
          onChange={formik.handleChange}
          // value={totalCost(formik.values.discount, formik.values.price)}
        />
      </div>
    );
  };
  const PerPanelQuantityTemplate = (rowDta, { rowIndex }) => {
    return <div></div>;
  };
  const getFabPrice = (id) => {
    getFabricDetails(id).then((res) => {
      // const type = formik.values.userType.concat("FabricPrice");
      // if (type === "architectFabricPrice") {
      //   console.log(
      //     "first",
      //     res?.architectFabricPrice,
      //     type,
      //     formik.values.quantity
      //   );
      //   formik.values.price =
      //     Number(res?.architectFabricPrice) * formik.values.quantity;
      //   setFabPrice(Number(res?.architectFabricPrice) * formik.values.quantity);
      // } else if (type === "retailerFabricPrice") {
      //   console.log(
      //     "first",
      //     res?.retailerFabricPrice,
      //     type,
      //     formik.values.quantity
      //   );
      //   formik.values.price =
      //     Number(res?.retailerFabricPrice) * formik.values.quantity;
      //   setFabPrice(Number(res?.retailerFabricPrice) * formik.values.quantity);
      // } else if (type === "channelPartnerFabricPrice") {
      //   console.log(
      //     "first",
      //     res?.channelPartnerFabricPrice,
      //     type,
      //     formik.values.quantity
      //   );
      //   formik.values.price =
      //     Number(res?.channelPartnerFabricPrice) * formik.values.quantity;
      //   setFabPrice(
      //     Number(res?.channelPartnerFabricPrice) * formik.values.quantity
      //   );
      // }
      formik.values.price =
        res[formik.values.userType.concat("RollLengthPrice")] *
        formik.values.quantity;
      setFabPrice(
        res[formik.values.userType.concat("RollLengthPrice")] *
          formik.values.quantity
      );
      // console.log(
      //   "fabPrice :",
      //   res[formik.values.userType.concat("CutLengthPrice")] *
      //     formik.values.quantity,
      //   res[formik.values.userType.concat("CutLengthPrice")],
      //   formik.values.quantity,
      //   formik.values.userType.concat("CutLengthPrice")
      // );
    });
  };

  const QuantityTemplate = (rowDta, { rowIndex }) => {
    return (
      <div>
        <InputText
          disabled
          value={formik.values.noOFPanel * formik.values.perPanelQuantity}
          style={{ width: "80px" }}
          id="quantity"
          name="quantity"
          onChange={(e) => {
            formik.handleChange(e);
            formik.values.liningCost = calculateLiningCost(
              formik.values.lining,
              e.target.value
            );
          }}
          // value={calculateQuantity(formik.values.noOFPanel, formik.values.perPanelQuantity)}
        />
      </div>
    );
  };
  const installationTypeTemplate = (rowDta, { rowIndex }) => {
    return (
      <div>
        <Dropdown
          style={{ width: "200px" }}
          id="installationType"
          value={formik.values.installationType}
          name="installationType"
          options={installationType}
          placeholder="Installation Type"
          onChange={(e) => {
            formik.values.installationCost = calculateInstallationCost(
              e.target.value
            );

            formik.handleChange(e);
          }}
          // value={formik.values.installationType}
        />
        {getFormErrorMessage("installationType")}
      </div>
    );
  };

  const installationPricingTemplate = (rowData, { rowIndex }) => {
    return (
      <div>
        <InputText
          id="installationPrice"
          name="installationPrice"
          // value={formik.values.installationPrice}
          style={{ width: "100px" }}
          // onChange={(e) => {
          //     formik.values.panelHeight = panelHeight(e.target.value).toFixed(3)
          //     formik.values.perPanelQuantity = perPanelQuantity(formik.values.panelHeight).slice(0, 5)
          //     formik.values.quantity = formik.values.noOFPanel * formik.values.perPanelQuantity
          //     formik.handleChange(e)
          //     getFabPrice(formik.values.fabric)

          // }}
          onChange={formik.handleChange}
        />
        {getFormErrorMessage("height")}
      </div>
    );
  };

  const calculateInstallationCost = (values, rowIndex) => {
    if (values === "In Bangalore") {
      return 750;
    } else {
      return 1250;
    }
  };
  const installationCostTemplate = (rowDta, { rowIndex }) => {
    return (
      <div>
        <InputText
          disabled
          value={formik.values.installationCost}
          style={{ width: "120px" }}
          id="installationCost"
          name="installationCost"
          onChange={formik.handleChange}
          // value={formik.values.installationCost}
        />
      </div>
    );
  };
  const liningFieldTemplate = (rowData, { rowIndex }) => {
    return (
      <div>
        <Dropdown
          id="lining"
          name="lining"
          value={formik.values["lining"]}
          options={liningTableFieldOptions}
          onChange={(e) => {
            formik.handleChange(e);
            formik.values.liningCost = calculateLiningCost(
              e.target.value,
              formik.values.quantity
            );
          }}
          placeholder="Select a Lining"
        />
        {getFormErrorMessage("lining")}
      </div>
    );
  };

  const calculateLiningCost = (values, quantity) => {
    console.log("total cost", values, quantity);
    if (values === "") {
      return 0;
    } else if (values === "Liva Dimout") {
      return 135 * quantity;
    } else if (values === "Dimout") {
      return 135 * quantity;
    } else if (values === "Satin") {
      return 85 * quantity;
    } else if (values === "3 Pass Blackout") {
      return 240 * quantity;
    }
  };
  const liningCostTemplate = (rowDta, { rowIndex }) => {
    return (
      <div>
        <InputText
          disabled
          style={{ width: "90px" }}
          id="liningCost"
          name="liningCost"
          onChange={formik.handleChange}
          value={formik.values.liningCost}
        />
      </div>
    );
  };
  const submitTemplate = () => {
    return (
      <Button style={{ background: "#287F80" }} type="submit">
        Add
      </Button>
    );
  };
  formik.values.discount = discountPer;
  formik.values.price = fabPrice;
  // formik.values.stitchCost = calculateStitchCost();
  // formik.values.stitchCost = Number(
  //   stitchPrice * formik.values.noOFPanel
  // ).toFixed(2);
  if (formik.values.stitch === "Roman Blinds") {
    formik.values.stitchCost = Math.round(
      (stitchPrice * (formik.values.height * formik.values.width)) / 144
    );
  } else if (formik.values.stitch === "Blind System") {
    formik.values.stitchCost = Math.round(
      stitchPrice * (formik.values.width / 12)
    );
  } else {
    formik.values.stitchCost = Number(stitchPrice * formik.values.noOFPanel);
  }
  formik.values.installationCost = instalationPrice;
  formik.values.liningCost = calculateLiningCost(
    formik.values.lining,
    formik.values.perPanelQuantity
  );

  formik.values.cost = Math.round(
    (Number(instalationPrice) +
      formik.values.stitchCost +
      formik.values.liningCost +
      fabPrice) *
      ((100 - formik.values.discount) / 100)
  ).toFixed(0);
  // console.log(
  //   "total cost",
  //   formik.values.cost,
  //   instalationPrice,
  //   stitchPrice,
  //   formik.values.liningCost,
  //   fabPrice
  // );
  formik.values.price = fabPrice?.toFixed(2);
  formik.values.liningCost = formik.values.liningCost?.toFixed(2);
  formik.values.perPanelQuantity = Number(
    formik.values.perPanelQuantity
  )?.toFixed(2);
  formik.values.quantity = Number(formik.values.quantity)?.toFixed(2);
  formik.values.panelHeight = Number(formik.values.panelHeight)?.toFixed(2);
  const header = (
    <div className="table-header">
      Products
      <Button icon="pi pi-refresh" />
    </div>
  );
  function getTotal() {
    var Total = 0;
    for (var i = 0; i < tableValues.length; i++) {
      Total = Total + Number(tableValues[i].cost);
    }
    return parseInt(Total);
  }

  const userTypeFieldTemplate = (rowData, { rowIndex }) => {
    return (
      <div>
        <Dropdown
          id="userType"
          name="userType"
          value={formik.values["userType"]}
          options={["retailer", "channelPartner", "architect"]}
          onChange={(e) => {
            formik.handleChange(e);
          }}
          placeholder="Select a UserType"
        />
        {getFormErrorMessage("userType")}
      </div>
    );
  };

  return (
    <div>
      <MenuNavigator />

      <Divider align="center">
        <h2 className="p-text-center">Made to Measure Order</h2>
      </Divider>

      <form onSubmit={formik.handleSubmit} className="">
        <div className="datatable-templating-demo" style={{ fontSize: 15 }}>
          <div className="card">
            <div className="flex border-round surface-200 p-3 m-3">
              <div className="w-8 font-bold p-3 flex align-items-center justify-content-center">
                <div className="inputTable  ">
                  <div className="flex">
                    <div className="my-2 mr-3">
                      <label htmlFor="name">
                        SL <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>
                        <InputText
                          id="sl"
                          type="text"
                          placeholder="Enter SL"
                          style={{ width: "14rem" }}
                          value={formik.values.sl}
                          onChange={formik.handleChange}
                        />
                        <div>{getFormErrorMessage("sl")}</div>
                      </div>
                    </div>
                    <div className="my-2 mr-3">
                      <label htmlFor="name">
                        Room <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>
                        <Dropdown
                          id="roomType"
                          name="roomType"
                          style={{ width: "14rem" }}
                          value={formik.values.roomType}
                          options={roomTableFieldOptions}
                          onChange={formik.handleChange}
                          placeholder="Select a Room"
                        />
                        <div>{getFormErrorMessage("roomType")}</div>
                      </div>
                    </div>
                    <div className="my-2 mr-3">
                      <label htmlFor="name">
                        UserType <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>
                        <Dropdown
                          id="userType"
                          name="userType"
                          style={{ width: "14rem" }}
                          value={formik.values["userType"]}
                          options={["retailer", "channelPartner", "architect"]}
                          onChange={(e) => {
                            formik.handleChange(e);
                            getUserList(e.target.value);
                          }}
                          placeholder="Select a UserType"
                        />
                        <div>{getFormErrorMessage("userType")}</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="my-2 mr-3">
                      <label htmlFor="name">
                        User <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>
                        <Dropdown
                          id="user"
                          name="user"
                          optionLabel="name"
                          optionValue="_id"
                          style={{ width: "14rem" }}
                          value={formik.values["user"]}
                          options={userOptions}
                          onChange={(e) => {
                            formik.handleChange(e);
                            setDiscount(e.target.value);
                          }}
                          placeholder="Select a user"
                        />
                        <div>{getFormErrorMessage("user")}</div>
                      </div>
                    </div>
                    <div className="my-2 mr-3">
                      <label htmlFor="name">
                        Window <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>
                        <Dropdown
                          id="window"
                          name="window"
                          style={{ width: "14rem" }}
                          value={formik.values["window"]}
                          options={windowTableFieldOptions}
                          onChange={formik.handleChange}
                          placeholder="Select a Window"
                        />
                        <div>{getFormErrorMessage("window")}</div>
                      </div>
                    </div>
                    <div className="my-2 mr-3">
                      <label htmlFor="name">
                        Catalogue <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>
                        <Dropdown
                          value={formik.values.catalogue}
                          id="catalogue"
                          name="catalogue"
                          style={{ width: "14rem" }}
                          optionLabel="name"
                          options={catalogueTableFieldOptions}
                          optionValue="_id"
                          onChange={(e) => {
                            formik.handleChange(e);
                            getFabricunderCatalogue(e.target.value);
                          }}
                          placeholder="Select a Catalogue"
                        />
                        <div>{getFormErrorMessage("catalogue")}</div>
                      </div>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="my-2 mr-3">
                      <label htmlFor="name">
                        Fabric <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>
                        <Dropdown
                          id="fabric"
                          name="fabric"
                          style={{ width: "14rem" }}
                          value={formik.values.fabric}
                          optionLabel="name"
                          optionValue="_id"
                          options={fabricTableFieldOptions}
                          onChange={(e) => {
                            formik.handleChange(e);
                            getFabPrice(e.target.value);
                          }}
                          placeholder="Select a Fabric"
                        />
                        <div>{getFormErrorMessage("fabric")}</div>
                      </div>
                    </div>
                    <div className="my-2 mr-3">
                      <label htmlFor="name">Remark</label>
                      <div>
                        <div>
                          <InputTextarea
                            id="remark"
                            name="remark"
                            style={{ width: "14rem" }}
                            placeholder="Remarks (Optional)"
                            value={formik.values["remark"]}
                            onChange={formik.handleChange}
                            // style={{ minWidth: "20rem" }}
                            rows={1}
                          />
                          <div>{getFormErrorMessage("remark")}</div>
                        </div>
                      </div>
                    </div>
                    <div className="my-2 mr-3">
                      <label htmlFor="name">
                        Stitch <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>
                        <Dropdown
                          id="stitch"
                          name="stitch"
                          style={{ width: "14rem" }}
                          value={formik.values["stitch"]}
                          // value={stitchType}
                          options={stitchTableFieldOptions}
                          onChange={(e) => {
                            formik.handleChange(e);
                            // setStitchType(e.target.value);
                            // formik.values.stitchPrice = getStitchingPrice(
                            //   e.target.value
                            // );
                            getStitchingPrice(e.target.value);
                            console.log("click", e.target.value);
                          }}
                          placeholder="Select a Stitch"
                        />
                        <div>{getFormErrorMessage("stitch")}</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="my-2 mr-3">
                      <label htmlFor="name">
                        Stitch Pricing <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>
                        <div>
                          <InputText
                            id="stitchPrice"
                            name="stitchPrice"
                            placeholder="Enter Price"
                            // value={formik.values.stitchPrice}
                            value={stitchPrice}
                            style={{ width: "14rem" }}
                            onChange={formik.handleChange}
                            disabled={true}
                          />
                          <div>{getFormErrorMessage("stitchPrice")}</div>
                        </div>
                      </div>
                    </div>
                    <div className="my-2 mr-3">
                      <label htmlFor="name">
                        Lining <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>
                        <Dropdown
                          id="lining"
                          name="lining"
                          style={{ width: "14rem" }}
                          value={formik.values["lining"]}
                          options={liningTableFieldOptions}
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.values.liningCost = calculateLiningCost(
                              e.target.value,
                              formik.values.quantity
                            );
                          }}
                          placeholder="Select a Lining"
                        />
                        <div>{getFormErrorMessage("lining")}</div>
                      </div>
                    </div>
                    <div className="my-2 mr-3">
                      <label htmlFor="name">
                        Type <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>
                        <Dropdown
                          id="type"
                          name="type"
                          style={{ width: "14rem" }}
                          value={formik.values["type"]}
                          options={typeTableFieldOptions}
                          onChange={formik.handleChange}
                          placeholder="Select a Type"
                        />
                        <div>{getFormErrorMessage("type")}</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="my-2 mr-3">
                      <label htmlFor="name">
                        Installation Type{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>
                        <Dropdown
                          // style={{ width: "200px" }}
                          id="installationType"
                          style={{ width: "14rem" }}
                          value={formik.values.installationType}
                          name="installationType"
                          options={installationType}
                          placeholder="Installation Type"
                          onChange={(e) => {
                            // formik.values.installationCost =
                            //   calculateInstallationCost(e.target.value);

                            // formik.values.installationPrice =
                            getInstallationPrice(e.target.value);
                            formik.handleChange(e);
                          }}
                        />
                        <div>{getFormErrorMessage("installationType")}</div>
                      </div>
                    </div>
                    <div className="my-2 mr-3">
                      <label htmlFor="name">
                        Installation Pricing{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>
                        <div>
                          <InputText
                            id="installationPrice"
                            name="installationPrice"
                            placeholder="Enter Price"
                            style={{ width: "14rem" }}
                            // value={formik.values.installationPrice}
                            value={instalationPrice}
                            // style={{ width: "100px" }}
                            onChange={formik.handleChange}
                            disabled={true}
                          />
                          <div>{getFormErrorMessage("installationPrice")}</div>
                        </div>
                      </div>
                    </div>

                    <div className="my-2 mr-3">
                      <label htmlFor="name">
                        Fullness <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>
                        <Dropdown
                          id="fullness"
                          name="fullness"
                          style={{ width: "14rem" }}
                          value={formik.values.fullness}
                          options={fullnessTableFieldOptions}
                          onChange={(e) => {
                            formik.values.noOFPanel = Math.round(
                              Panels(e.target.value, formik.values.width)
                            );
                            formik.handleChange(e);
                          }}
                          placeholder="Select Fullness"
                        />
                        <div>{getFormErrorMessage("fullness")}</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="my-2 mr-3">
                      <label htmlFor="name">
                        Measurement Unit <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>
                        <Dropdown
                          id="measurement"
                          name="measurement"
                          style={{ width: "14rem" }}
                          value={formik.values["measurement"]}
                          options={measurementTableFieldOptions}
                          onChange={formik.handleChange}
                          placeholder="Select Measurement Type"
                        />
                        <div>{getFormErrorMessage("measurement")}</div>
                      </div>
                    </div>
                    <div className="my-2 mr-3">
                      <label htmlFor="name">
                        Window Height <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>
                        <div>
                          <InputText
                            id="height"
                            name="height"
                            placeholder="Height"
                            value={formik.values.height}
                            style={{ width: "14rem" }}
                            onChange={(e) => {
                              formik.values.panelHeight = panelHeight(
                                e.target.value
                              );
                              formik.values.perPanelQuantity = perPanelQuantity(
                                formik.values.panelHeight
                              );
                              formik.values.quantity =
                                formik.values.noOFPanel *
                                formik.values.perPanelQuantity;
                              formik.handleChange(e);
                              getFabPrice(formik.values.fabric);
                            }}
                          />
                          <div>{getFormErrorMessage("height")}</div>
                        </div>
                      </div>
                    </div>
                    <div className="my-2 mr-3">
                      <label htmlFor="name">
                        Window Width <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>
                        <div>
                          <InputText
                            id="width"
                            // style={{ width: "100px" }}
                            name="width"
                            style={{ width: "14rem" }}
                            placeholder="Width"
                            value={formik.values.width}
                            onChange={(e) => {
                              formik.values.noOFPanel = Math.round(
                                Panels(formik.values.fullness, e.target.value)
                              );
                              formik.values.quantity =
                                formik.values.noOFPanel *
                                formik.values.perPanelQuantity;
                              formik.handleChange(e);
                              getFabPrice(formik.values.fabric);
                            }}
                          />
                          <div>{getFormErrorMessage("width")}</div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="my-2 mr-3">
                      <label htmlFor="name">
                        Accessory <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>
                        <Dropdown
                          id="accessory"
                          name="accessory"
                          style={{ width: "14rem" }}
                          value={formik.values["accessory"]}
                          options={accessoryTableFieldOptions}
                          onChange={formik.handleChange}
                          placeholder="Select an Accessory"
                        />
                        <div>{getFormErrorMessage("accessory")}</div>
                      </div>
                    </div> */}
                  </div>

                  {/* <div
                  className="field-checkbox my-5"
                  style={{ fontSize: 18, height: "2rem" }}
                >
                  <Checkbox
                    inputId="binary"
                    checked={checked}
                    onChange={(e) => setChecked(e.checked)}
                  />
                  <label htmlFor="binary">Accessories</label>
                </div> */}
                </div>
              </div>
              <div className="w-5 font-bold  p-2 flex align-items-center justify-content-center">
                <div>
                  <div className="flex">
                    <div className="m-2">
                      <div>Per Panel Height</div>
                      <InputText
                        disabled
                        value={formik.values.panelHeight}
                        style={{ width: "8rem" }}
                        id="panelHeight"
                        name="panelHeight"
                        onChange={formik.handleChange}
                        // value={Number(panelHeight(formik.values.height).toFixed(3))}
                      />
                    </div>
                    <div className="m-2">
                      <div>Per PanelQuantity</div>
                      <InputText
                        disabled
                        style={{ width: "8rem" }}
                        id="perPanelQuantity"
                        value={formik.values.perPanelQuantity}
                        name="perPanelQuantity"
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        // value={Number(perPanelQuantity(formik.values.panelHeight)).toFixed(3)}
                      />
                    </div>
                    <div className="m-2 ">
                      <div>No of Panels</div>
                      <InputText
                        disabled
                        value={formik.values.noOFPanel}
                        style={{ width: "8rem" }}
                        id="noOFPanel"
                        name="noOFPanel"
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        // value={(Panels(formik.values.fullness, formik.values.width))}
                      />
                    </div>
                  </div>

                  <div className="flex">
                    <div className="m-2">
                      <div>Total Quantity</div>
                      <InputText
                        disabled
                        style={{ width: "8rem" }}
                        id="perPanelQuantity"
                        value={formik.values.quantity}
                        name="perPanelQuantity"
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        // value={Number(perPanelQuantity(formik.values.panelHeight)).toFixed(3)}
                      />
                    </div>
                    <div className="m-2">
                      <div>Price</div>
                      <InputText
                        disabled
                        value={formik.values.price}
                        style={{ width: "8rem" }}
                        id="price"
                        name="price"
                        onChange={formik.handleChange}
                        // value={(calculateCost(formik.values.perPanelQuantity, ArrayOfFabrics, formik.values[0] ? formik.values[0].name : "", calculateQuantity(formik.values.noOFPanel, formik.values.perPanelQuantity).toFixed(2)))}
                      />
                    </div>
                    <div className="m-2">
                      <div>Installation Cost</div>
                      <InputText
                        disabled
                        value={formik.values.installationCost}
                        style={{ width: "8rem" }}
                        id="installationCost"
                        name="installationCost"
                        onChange={formik.handleChange}
                        // value={formik.values.installationCost}
                      />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="m-2">
                      <div>Stitch Cost</div>
                      <InputText
                        disabled
                        id="stitchCost"
                        style={{ width: "8rem" }}
                        name="stitchCost"
                        value={Number(formik.values.stitchCost).toFixed(2)}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="m-2">
                      <div>Lining Cost</div>
                      <InputText
                        disabled
                        style={{ width: "8rem" }}
                        id="liningCost"
                        name="liningCost"
                        onChange={formik.handleChange}
                        value={formik.values.liningCost}
                      />
                    </div>

                    <div className="m-2">
                      <div>Discount</div>
                      <InputText
                        disabled
                        style={{ width: "8rem" }}
                        id="discount"
                        name="discount"
                        onChange={formik.handleChange}
                        value={formik.values.discount}
                      />
                    </div>
                  </div>
                  <div className="m-2">
                    <div>Total Cost (Rs)</div>
                    <InputText
                      disabled
                      value={formik.values.cost}
                      style={{ width: "8rem" }}
                      id="cost"
                      name="cost"
                      onChange={formik.handleChange}
                      // value={totalCost(formik.values.discount, formik.values.price)}
                    />
                  </div>
                  {/* <div className="m-2">
                    <div>Submit</div>
                    <Button type="submit">Add</Button>
                  </div> */}
                  <div className="m-2 text-center ">
                    <Button className="" type="submit" label="Add"></Button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="text-center text-2xl font-bold mt-3">
              {" "}
              Made to Measure Values{" "}
            </div> */}

            <Divider align="center">
              <h2 className="p-text-center">Made to Measure Data</h2>
            </Divider>
            <DataTable className="text-xs" value={tableValues} showGridlines>
              <Column field="id" header="Id" />

              <Column field="roomType" header="Room"></Column>
              <Column field="window" header="Window" />

              {/* <Column field="accessory" header="Accessory" /> */}

              <Column field="stitch" header="Stitch" />

              <Column header="Lining" field="lining" />

              <Column header="Type" field="type" />
              <Column header="Installation Type" field="installationType" />

              <Column header="Fullness" field="fullness" />

              <Column header="Window Height" field="height" />
              <Column header="Window Width" field="width" />
              <Column header="Per Panel" field="panelHeight" />
              <Column header="Per Panel Quantity" field="perPanelQuantity" />
              <Column header="No of Panels" field="noOFPanel" />

              <Column header="Discount" field="discount" />

              <Column header="Total Cost" field="cost" />
              <Column body={deleteButton} />
            </DataTable>
          </div>
        </div>
        <div>
          {/* <div
            style={{
              marginTop: "40px",
              textAlign: "center",
              fontSize: "25px",
              fontWeight: "700",
            }}
          >
            Total Cost : {getTotal().toFixed(2)}Rs
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            style={{ margin: "20px 0px", width: "25%", background: "#287F80" }}
            type="submit"
            onClick={(e) => {
              navigate("/MTMaddress", {
                state: {
                  stored: tableValues,
                  totalCost: getTotal().toFixed(2),
                  currentUser,
                },
              });
            }}
            label="Get Quotation"
            className="p-block text-center"
          ></Button> */}
        </div>

        {/* <div className="datatable-templating-demo" > */}
        {/* <div className="card" >

                        <DataTable
                            value={[formik.values]}
                            first={0}
                            dataKey="roweeee"
                            responsiveLayout="scroll"

                        >
                            <Column header="SL" body={slnoFieldTemplate}></Column>
                            <Column 
                                header="Room"
                                body={roomTableFieldTemplate}
                            ></Column>
                            <Column header="UserType" body={userTypeFieldTemplate} />

                            <Column header="Window" body={windowFieldTemplate} />
                            <Column field="" header="Catalogue" body={catalogueFieldTemplate} />
                            <Column header="Fabric" body={fabricFieldTemplate} />
                            <Column header="Accessory" body={accessoryFieldTemplate} />
                            <Column header="Remark" body={remarkFieldTemplate} />
                            <Column header="Stitch" body={stitchFieldTemplate} />
                            <Column header="Stitch Pricing" body={stitchPricingTemplate}></Column>

                            <Column header="Lining" body={liningFieldTemplate} />

                            <Column header="Type" body={typeFieldTemplate} />
                            <Column header="Installation Type" body={installationTypeTemplate} />
                            <Column header="Installation Pricing" body={installationPricingTemplate}></Column>

                            <Column header="Fullness" body={fullnessFieldTemplate} />
                            <Column
                                header="Measurement Unit"
                                body={measurementUnitFieldTemplate}
                            />
                            <Column header="Window Height" body={windowHeightFieldTemplate} />
                            <Column header="Window Width" body={windowWidthFieldTemplate} />
                            <Column header="Per Panel Height" body={perPanelHeightTemplate} />
                            <Column header="Per Panel Quantity" body={PerPanelQuantityTemplate} />
                            <Column header="No of Panels" body={noOfPanelsTemplate} />
                            <Column header="Total Quantity" body={QuantityTemplate} />
                            <Column header="Price" body={totalPriceTemplate} />
                            <Column header="Installation Cost" body={installationCostTemplate} />
                            <Column header="Stitch Cost" body={stitchCostFieldTemplate} />
                            <Column header="Lining Cost" body={liningCostTemplate} />
                            <Column header="Discount" body={discountTemplate} />


                            <Column header="Total Cost" body={totalCostTemplate} />
                            <Column header="Submit"  body={submitTemplate} />
                        </DataTable>
                        <Divider align="center">
                            <h2 className="p-text-center">Made to Measure Data</h2>
                        </Divider>
                        <DataTable
                            value={tableValues}
                            showGridlines

                        >
                            <Column field="id" header="Id" />

                            <Column
                                field="roomType"
                                header="Room"

                            ></Column>
                            <Column field="window" header="Window" />

                            <Column field="accessory" header="Accessory" />

                            <Column field="stitch" header="Stitch" />

                            <Column header="Lining" field="lining" />

                            <Column header="Type" field="type" />
                            <Column header="Installation Type" field="installationType" />

                            <Column header="Fullness" field="fullness" />

                            <Column header="Window Height" field="height" />
                            <Column header="Window Width" field="width" />
                            <Column header="Per Panel Height" field="panelHeight" />
                            <Column header="Per Panel Quantity" field="perPanelQuantity" />
                            <Column header="No of Panels" field="noOFPanel" />

                            <Column header="Discount" field="discount" />


                            <Column header="Total Cost" field="cost" />


                        </DataTable>
                    </div> */}
        {/* </div> */}
        <div>
          <div
            style={{
              marginTop: "40px",
              textAlign: "center",
              fontSize: "25px",
              fontWeight: "700",
            }}
          >
            Total Cost : {getTotal().toFixed(2)}Rs
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            style={{ margin: "20px 0px", width: "25%", background: "#287F80" }}
            type="submit"
            onClick={(e) => {
              if (tableValues.length > 0) {
                navigate("/adminMtmDelivery", {
                  state: {
                    stored: tableValues,
                    totalCost: getTotal().toFixed(2),
                    currentUser: userData,
                  },
                });
              }
            }}
            label="Get Quotation"
            className="p-block text-center"
          ></Button>
        </div>
      </form>
    </div>
  );
};

export default AdminMTMO;
