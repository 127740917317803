import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllFabrics } from "../../api/api";
import axios from "axios";

const headers = {
    "Content-Type": "application/json",
    Authorization: window.localStorage.getItem("userId"),
};

export const fetchFabrics = createAsyncThunk(
    "fabrics/fetchFabrics",
    async (page) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/fabrics?page=${page}`,
                { headers }
            );
            return response.data;
        } catch (err) {
            return err.message;
        }
    }
);

const initialState = {
    allfabrics: [],
    count: 0,
    isLoading: false,
    error: "",
};

const fabricsSlice = createSlice({
    name: "fabrics",
    initialState,
    extraReducers: {
        [fetchFabrics.pending]: (state, action) => {
            state.isLoading = true;
        },
        [fetchFabrics.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.allfabrics = action.payload.allFabrics
            state.count = action.payload.fabricCount

        },
        [fetchFabrics.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = "Fetch Error";
        },
    },
});

export const selectAllFabrics = (state) => state.allfabrics;
export const fabricLoading = (state) => state.isLoading;
export const fabricError = (state) => state.error;

export default fabricsSlice.reducer;
