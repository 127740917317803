import React, { useContext } from "react";

import { useNavigate, Navigate } from "react-router-dom";

const CheckIsAdmin = () => {
  
  if (window.localStorage.getItem("userType")) {
    if (window.localStorage.getItem("userType") === "admin") {
      return true;
    }
    return false;
  }
};
const CheckIsAdmin2 = () => {
  
  if (window.localStorage.getItem("userType")) {
    if (window.localStorage.getItem("userType") === "admin"|| window.localStorage.getItem("userType") === "sales"|| window.localStorage.getItem("userType") === "accounts" ) {
      return true;
    }
    return false;
  }
};

function IsAdmin({ children }) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="m-4">
        <div className="">
          {CheckIsAdmin() ? children : <Navigate to="/login" />}
        </div>
      </div>
    </div>
  );
}
function IsAdmin2({ children }) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="m-4">
        <div className="">
          {CheckIsAdmin2() ? children : <Navigate to="/login" />}
        </div>
      </div>
    </div>
  );
}

export { IsAdmin, CheckIsAdmin,CheckIsAdmin2,IsAdmin2 };
