import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import MenuNavigator from "../../../components/MenuNavigator";
import { Badge } from "primereact/badge";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import e from "cors";
import { getAllOrders } from "../../../api/api";

const AllOrders = () => {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [ordersList, setOrdersList] = useState([]);
  const [dataCount, setDataCount] = useState(0);

  useEffect(() => {
    getAllOrders().then((data) => {
      setOrdersList(data.orders);
      console.log(data);
      setDataCount(data.orderCount);
    });
  }, []);

  console.log(ordersList);
  const navigate = useNavigate();
  // const { state } = useLocation();
  // const ordersList = state

  // console.log("test", ordersList)
  // console.log(ordersList.length)
  const productType = (rowData) => {
    // console.log(rowData);
    if ("fabric" === rowData.name) {
      return "Fabric";
    } else if ("catalogue" === rowData.name) {
      return "Catalogue";
    } else {
      return "MTM";
    }
  };
  // console.log(state)
  const paymentStatus = (rowData) => {
    if (rowData.paymentStatus === "pending") {
      return (
        <span
          style={{
            backgroundColor: "#434343",
            color: "white",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          Pending
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: "#6a11cb",
            color: "white",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          Paid
        </span>
      );
    }
  };

  const createdAtTemplate = (data) => {
    return (
      <div>
        {data.createdAt.toString().slice(0, 10)} /{" "}
        {data.createdAt.toString().slice(11, 16)}{" "}
      </div>
    );
  };

  const productTemplate = (rowData) => {
    if (productType(rowData) === "Fabric") {
      return (
        <span
          style={{
            backgroundColor: "#ECCFFF",
            color: "#694382",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          {productType(rowData)}
        </span>
      );
    } else if (productType(rowData) === "Catalogue") {
      return (
        <span
          style={{
            backgroundColor: "#FFD8B2",
            color: "#805B36",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          {productType(rowData)}
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: "#B3E5FC",
            color: "#23547B",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          {productType(rowData)}
        </span>
      );
    }
  };
  const fullfilledTemplate = (rowData) => {
    if (rowData.trackingId) {
      return (
        <span
          style={{
            backgroundColor: "#FFD8B2",
            color: "#805B36",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          FullFilled
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: "#B3E5FC",
            color: "#23547B",
            textTransform: "uppercase",
            padding: ".25em 0.50em",
            borderRadius: "3px",
            fontWeight: "700",   
            letterSpacing: ".3px",
          }}
        >
          Not Yet
        </span>
      );
    }
  };
  const totalAmountTemplate = (data) => {
    return <div>{parseFloat(data.totalAmount).toFixed(2)}</div>;
  };
  const handlePageClick = async (e) => {
    setFirst(e.first);
    setRows(e.rows);
    let currentPage = e.page + 1;
    // console.log(currentPage)
    let data = await getAllOrders(currentPage);
    setOrdersList(data.orders);
  };

  return (
    <div>
      <MenuNavigator />
      <Divider align="center">
        <h2 className="p-text-center">List of All Orders</h2>
      </Divider>

      <div className="card">
        <DataTable
          value={ordersList.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          })}
          showGridlines
          onRowClick={(e) => {
            console.log(e.data);
            if (e.data.name === "fabric") {
              navigate("/fabricOrderDetail", { state: e.data });
            } else if (e.data.name === "catalogue") {
              navigate("/catalogueOrderDetail", { state: e.data });
            } else {
              navigate("/MTMOrderDetail", { state: e.data });
            }
          }}
          selectionMode="single"
          responsiveLayout="scroll"
        >
          <Column body={createdAtTemplate} header="Placed At"></Column>
          <Column field="orderId" header="Order ID"></Column>
          <Column field="companyName" header="Company Name"></Column>
          <Column
            bodyClassName="text-center"
            body={productTemplate}
            header="Product Type"
          ></Column>
          <Column field="contactNumber" header="Contact Number"></Column>
          <Column field="modeOfTransport" header="Mode Of Transport"></Column>
          <Column body={totalAmountTemplate} header="Total Amount"></Column>
          <Column
            field="razorpayMeta.razorpay_payment_id"
            header="Payment ID"
          ></Column>
          <Column
            bodyStyle={{ textAlign: "center" }}
            header="Payment Status"
            body={paymentStatus}
          ></Column>
          <Column
            bodyStyle={{ textAlign: "center" }}
            body={fullfilledTemplate}
            header="Fullfilled Status"
          ></Column>
        </DataTable>
        <Paginator
          rows={rows}
          totalRecords={dataCount}
          first={first}
          onPageChange={handlePageClick}
        ></Paginator>
      </div>
    </div>
  );
};

export default AllOrders;
