import React, { useEffect, useState, useRef } from "react";
import MenuNavigator from "../../../components/MenuNavigator";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { useLocation, useNavigate } from "react-router-dom";
import { displayRazorpay } from "../PaymentIntegration";
import { Toast } from "primereact/toast";
import { createOrder } from "../../../api/api";
import { Dialog } from "primereact/dialog";

function MTMQuotation() {
  const paymentOption = JSON.parse(
    window.localStorage.getItem("userDetails")
  ).paymentOption;

  const toast = useRef(null);
  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Payment Recieved",
      detail:
        "Your Order is Placed. You will be redirected to the Made to Measure Page",
    });
  };

  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("state", state);

  const expiredOrders = JSON.parse(
    window.localStorage.getItem("expiredOrders")
  );
  const userDetails = state.currentUser;
  const delivery = state.delivery;
  const totalProductAmountWithGST = () => {
    let productAmount = parseFloat(state.totalCost);
    return Math.round(0.12 * productAmount + productAmount).toFixed(2);
  };
  const products = [...state.stored];
  const withGST = totalProductAmountWithGST();
  const data = { ...state, finalAmount: withGST, showSuccess };
  const headerTemplate = <span>Breakup</span>;
  const move = () => {
    navigate("/userDashboard");
  };

  const paynowButton = () => (
    <Button
      style={{ width: "150px", margin: "10px", background: "#287F80" }}
      label="Pay Now"
      onClick={() => {
        if (expiredOrders.length > 0) {
          onClick("displayBasic");
        } else {
          console.log("data", data);
          createOrder({
            orderType: "paynow",
            name: "madeToMeasure",
            companyName: data.delivery.companyName,
            deliveryAddress: data.delivery.deliveryAddress,
            state: data.delivery.state,
            pincode: data.delivery.pincode,
            contactNumber: data.delivery.contactNumber,
            modeOfTransport: data.delivery.modeOfTransport,
            optionalTransport: data.delivery.optionalTransport || "",
            qty: "1",
            subOrders: data.stored.map((obj) => ({
              roomType: obj.roomType,
              window: obj.window,
              catalogue: obj.catalogue,
              fabric: obj.fabric,
              accessory: obj.accessory,
              remark: obj.remark,
              stitch: obj.stitch,
              lining: obj.lining,
              type: obj.type,
              fullness: obj.fullness,
              measurement: obj.measurement,
              height: obj.height,
              width: obj.width,
              installationType: obj.installationType,
            })),
          })
            .then((res) => {
              console.log("res", res);
              displayRazorpay({
                data: res,
                finalAmount: res.razorpayOrder.amount,
                success: showSuccess,
                error: error,
                navigate: move,
              });
            })
            .catch(() => error("Couldn't able to Create Order"));
        }
      }}
    ></Button>
  );

  const creditButton = () => (
    <Button
      type="submit"
      style={{ width: "150px", margin: "10px", background: "#287F80" }}
      label="Take Credit"
      onClick={() => {
        if (expiredOrders.length > 0) {
          onClick("displayBasic");
        } else {
          createOrder({
            orderType: "credit",
            user: data.currentUser._id,
            name: "madeToMeasure",
            companyName: data.delivery.companyName,
            deliveryAddress: data.delivery.deliveryAddress,
            state: data.delivery.state,
            pincode: data.delivery.pincode,
            contactNumber: data.delivery.contactNumber,
            modeOfTransport: data.delivery.modeOfTransport,
            optionalTransport: data.delivery.optionalTransport
              ? data.delivery.optionalTransport
              : "",
            qty: "0",
            totalAmount: "",
            paymentStatus: "pending",
            subOrders: data.stored.map((obj) => ({
              roomType: obj.roomType,
              window: obj.width,
              catalogue: obj.catalogue,
              fabric: obj.fabric,
              accessory: obj.accessory,
              remark: obj.remark,
              stitch: obj.stitch,
              lining: obj.lining,
              type: obj.type,
              fullness: obj.fullness,
              measurement: obj.measurement,
              height: obj.height,
              width: obj.width,
              panelHeight: obj.panelHeight,
              perPanelQuantity: obj.perPanelQuantity,
              noOFPanel: obj.noOFPanel,
              quantity: obj.quantity,
              price: obj.price,
              discountPercentage: obj.discount,
              cost: obj.cost,
            })),
          }).then((res) => {
            if (res.orderType === "credit") {
              navigate("/credit");
            }
          });
        }
      }}
    ></Button>
  );

  const advancebutton = () => (
    <Button
      type="submit"
      style={{ width: "150px", margin: "10px", background: "#287F80" }}
      label="Pay Advance"
      onClick={() => {
        if (expiredOrders.length > 0) {
          onClick("displayBasic");
        } else {
          createOrder({
            orderType: "advance",
            name: "madeToMeasure",
            companyName: data.delivery.companyName,
            deliveryAddress: data.delivery.deliveryAddress,
            state: data.delivery.state,
            pincode: data.delivery.pincode,
            contactNumber: data.delivery.contactNumber,
            modeOfTransport: data.delivery.modeOfTransport,
            optionalTransport: data.delivery.optionalTransport
              ? data.delivery.optionalTransport
              : "",
            qty: "1",
            subOrders: data.stored.map((obj) => ({
              roomType: obj.roomType,
              window: obj.window,
              catalogue: obj.catalogue,
              fabric: obj.fabric,
              accessory: obj.accessory,
              remark: obj.remark,
              stitch: obj.stitch,
              lining: obj.lining,
              type: obj.type,
              fullness: obj.fullness,
              measurement: obj.measurement,
              height: obj.height,
              width: obj.width,
              panelHeight: obj.panelHeight,
              perPanelQuantity: obj.perPanelQuantity,
              noOFPanel: obj.noOFPanel,
              quantity: obj.quantity,
              price: obj.price,
              discountPercentage: obj.discount,
              cost: obj.cost,
            })),
          })
            .then((res) => {
              console.log(res);
              displayRazorpay({
                data: res,
                finalAmount: res.newOrder.advanceAmount,
                success: showSuccess,
                error: error,
              });
            })
            .catch(() => error("Couldn't able to Create Order"));
        }
      }}
    ></Button>
  );

  let footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Total:" colSpan={11} />
        <Column footer={state.totalCost} />
      </Row>
      <Row>
        <Column footer="Total with GST (12%):" colSpan={11} />

        <Column footer={totalProductAmountWithGST} />
      </Row>
    </ColumnGroup>
  );

  const [displayBasic, setDisplayBasic] = useState(false);

  const [position, setPosition] = useState("center");

  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
  };
  const error = (message) => {
    toast.current.show({
      severity: "error",
      summary: message,
      detail: "Please try Again",
    });
  };
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => onHide(name)}
          className="p-button-text"
        />
        <Button
          label="Pay Now"
          icon="pi pi-check"
          onClick={() => {
            onHide(name);
            navigate("/expiredOrders");
          }}
          autoFocus
        />
      </div>
    );
  };
  const FabricTable = (
    <DataTable
      colSpan={12}
      showGridlines
      value={products}
      responsiveLayout="scroll"
      header={headerTemplate}
      // footer={footerTemplate}
      footerColumnGroup={footerGroup}
    >
      <Column header="Sr. No." body={1} exportable></Column>
      <Column field="roomType" header="Room Type"></Column>
      <Column field="window" header="Window"></Column>

      <Column field="height" header="Height"></Column>
      <Column field="width" header="Width"></Column>
      <Column field="panelHeight" header="Panel Height"></Column>
      <Column field="perPanelQuantity" header="Per Panel Quantity"></Column>
      <Column field="noOFPanel" header="No of Panels"></Column>
      <Column field="quantity" header="Quantity"></Column>
      <Column field="price" header="Price"></Column>
      <Column field="discount" header="Discount(%)"></Column>

      <Column field="cost" header="Total Amount"></Column>
    </DataTable>
  );

  return (
    <div>
      <MenuNavigator />
      <Divider align="center">
        <Toast ref={toast}></Toast>
        <h2 className="p-text-center">Quotation</h2>
      </Divider>
      <Dialog
        header="Payment Alert"
        visible={displayBasic}
        style={{ width: "50vw" }}
        footer={renderFooter("displayBasic")}
        onHide={() => onHide("displayBasic")}
      >
        <p>Please Clear Your OutStanding Amount to Make New Orders</p>
      </Dialog>

      <div className="p-fluid">
        <Panel header="Order Details">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div style={{ marginTop: "20px" }}>
              <p>
                <strong>Date:</strong> {new Date().toString()}
              </p>
              <p>
                <strong>To, Company Name:</strong> {userDetails.companyName}
              </p>
              <p>
                <strong> Customer Name :</strong> {userDetails.name}
              </p>
              <p>
                <strong> Contact Number:</strong> {userDetails.mobileNumber}
              </p>
              <p>
                <strong> Address: </strong> {userDetails.address}
              </p>
              <p>
                <strong> From: (Batavia Exim)</strong>
              </p>
              <h3>
                <strong style={{ color: "red" }}>
                  {" "}
                  Current OutStanding :{" "}
                </strong>
                {userDetails.currentOutstanding}Rs
              </h3>
            </div>
            <div>
              <p style={{ textAlign: "center" }}>
                <h2>Delivery Address</h2>
              </p>
              <p>
                <strong>Company Name: </strong> {delivery.companyName}
              </p>
              <p>
                <strong>Address: </strong> {delivery.deliveryAddress}
              </p>
              <p>
                <strong> State: </strong> {delivery.state}
              </p>
              <p>
                <strong> Pincode: </strong> {delivery.pincode}
              </p>
              <p>
                <strong>Contact: </strong>
                {delivery.contactNumber}
              </p>
              <p style={{ display: "flex" }}>
                <strong>Transport:&nbsp; </strong>
                {delivery.modeOfTransport === "Any Other" ? (
                  <div> {delivery.optionalTransport}</div>
                ) : (
                  <div> {delivery.modeOfTransport}</div>
                )}
              </p>
            </div>
          </div>
        </Panel>
        <div className="card"></div>
      </div>
      <div style={{ textAlign: "center" }}>
        {FabricTable}
        <div
          style={{
            justifyContent: "space-between",
            marginTop: "30px",
            marginBottom: "100px",
          }}
        >
          {paynowButton()}
          {console.log(paymentOption)}
          {paymentOption === "advance" && advancebutton()}
          {paymentOption === "credit" && creditButton()}
        </div>
      </div>
    </div>
  );
}

export default MTMQuotation;
