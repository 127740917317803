import React, { useState, useEffect } from "react";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { DataTable } from "primereact/datatable";
import MenuNavigator from "../../../components/MenuNavigator";
import { getAllCatalogues } from "../../../api/api";
import { Paginator } from "primereact/paginator";

const ListOfAllCatalogues = () => {
  const navigate = useNavigate();

  const [catalogueList, setCatalogueList] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  useEffect(() => {
    getAllCatalogues(currentPage,searchTerm).then((data) => {
      console.log(data);
      setCatalogueList(data.allCatalogues);
      setCount(data?.catalogueCount)
    });
  }, [currentPage,searchTerm]); // eslint-disable-line react-hooks/exhaustive-deps
  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
    setCurrentPage(event.page + 1);
  };
  const imageBodyTemplate = (rowData) => {
    return (
      <img
        width="75px"
        style={{ width: "100px", height: "100px" }}
        src={rowData.image ? rowData.image : rowData.URL}
        onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
        alt={rowData.URL}
        onClick={(event) => {
          navigate(`/catalogues/${rowData._id}`);
        }}
        className="product-image"
      />
      // JSON.stringify(rowData.data.image)
    );
  };

  return (
    <div>
      <MenuNavigator />

      <h2 className="p-text-center">All Catalogues</h2>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          label="Add new Catalogue"
          className="p-block  p-mb-2"
          style={{ alignSelf: "auto", background: "#287F80" }}
          onClick={() => {
            navigate("/catalogues/new");
          }}
        />

        <div className="col-12" style={{ width: "30%" }}>
          <div className="p-inputgroup">
            <InputText
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Enter Catalogue"
            />
            <Button label="Search" style={{ background: "#287F80" }} />
          </div>
        </div>
      </div>
      <div>
        <div className="card">
          <DataTable
            value={
              catalogueList
                ? catalogueList.filter((val) => {
                    if (searchTerm === "") {
                      return val;
                    } else if (
                      val.name.toLowerCase().includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    }
                  })
                : []
            }
            responsiveLayout="scroll"
            sortable
            filterDisplay
            stripedRows
            showGridlines
            rowHover
            onRowClick={(event) => {
              // console.log(event);
              navigate(`/catalogues/${event.data._id}`);
            }}
          >
            <Column
              body={(rowData) => rowData.name}
              header="Name"
              expander
            ></Column>
            <Column
              field="image"
              header="Image"
              body={imageBodyTemplate}
            ></Column>
            <Column body={(rowData) => rowData.HSN} header="HSN"></Column>
          </DataTable>
          <Paginator
            first={basicFirst}
            rows={basicRows}
            totalRecords={count}
            onPageChange={onBasicPageChange}
          ></Paginator>
        </div>
      </div>
    </div>
  );
};

export default ListOfAllCatalogues;
