import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Divider } from 'primereact/divider';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import MenuNavigator from '../../../../components/MenuNavigator';
import { postTrackingId } from "../../../../api/api"
import { Toast } from 'primereact/toast';


function MTMOrderDetail() {
    const { state } = useLocation()
    const toast = useRef(null);
    const navigate = useNavigate()
    console.log(new Date(state.createdAt).toString())
    const fabricDetails = { ...state.fabric, qty: state.qty }
    const [trackingId, setTrackingId] = useState("")
    const showSuccess = () => {
        toast.current.show({
            severity: "success",
            summary: "Success!",
            detail: "Tracking ID Updated, you will redirect to pending orders page",
        });
    };
    const headerTemplate = <div style={{

        backgroundColor: "#B3E5FC",
        color: "#23547B",
        padding: ".25em 2rem",
        borderRadius: "3px",
        fontWeight: "700",
        letterSpacing: "2px",
        fontSize: "25px"
    }}><div>Order Placed at : {(new Date(state.createdAt).toString()).slice(0, 15)} | Time:{(new Date(state.createdAt).toString()).slice(16, 24)} </div>
        <div>Tracking ID :{state.trackingId} </div>
    </div>;

    const price = (product)=>{
      return  <div>
          {Number(product?.price).toFixed(2)}
        </div>
    }
    return (
        <div>
            <MenuNavigator />
            <Divider align="center">
                <Toast ref={toast} />
                <h2 className="p-text-center">Made to Measure Order Details</h2>
            </Divider>
            <div style={{ textAlign: 'center', marginBottom: "20px" }}>
                {headerTemplate}
            </div>
            <DataTable showGridlines value={state.subOrders} responsiveLayout="scroll"
                style={{ textAlign: "center" }}
            >
                <Column field="catalogue.name" header="Catalogue"></Column>
                <Column field="fabric.name" header="Fabric"></Column>

                <Column field="roomType" header="Room Type"></Column>

                <Column field="window" header="Window"></Column>


                {/* <Column field="accessory" header="Accessory"></Column> */}
                <Column field="stitch" header="Stitch"></Column>
                <Column field="lining" header="Lining"></Column>
                <Column field="type" header="Type"></Column>
                <Column field="fullness" header="Fullness"></Column>
                <Column field="measurement" header="Measurement"></Column>
                <Column field="height" header="Height"></Column>
                <Column field="width" header="Width"></Column>
                {/* <Column field="panelHeight" header="Per Panel Height"></Column>
                <Column field="perPanelQuantity" header="Per Panel Quantity"></Column> */}
                <Column field="noOFPanel" header="No of Panels"></Column>
                <Column field="quantity" header="Quantity"></Column>
                <Column field="price" header="Price" body={price}></Column>
                <Column field="discountPercentage" header="Discount"></Column>
                <Column field="cost" header="Final Cost"></Column>
            </DataTable>


            <Panel>

                <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <div>
                        <h3 style={{ textAlign: "center" }}>
                            <h2>Delivery Address</h2>
                        </h3>
                        <h3>
                            <strong>Company Name: </strong> {state.companyName}
                        </h3>
                        <h3>
                            <strong>Address: </strong> {state.deliveryAddress}
                        </h3>
                        <h3>
                            <strong> State: </strong> {state.state}
                        </h3>
                        <h3>
                            <strong> Pincode: </strong> {state.pincode}
                        </h3>
                        <h3>
                            <strong>Contact: </strong>{state.contactNumber}
                        </h3>
                        <h3 style={{ display: "flex" }}>
                            <strong>Transport:&nbsp; </strong>{state.modeOfTransport === "Any Other" ? <div> {state.optionalTransport}</div> : <div> {state.modeOfTransport}</div>}
                        </h3>
                    </div>
                    <div>
                        <h3 style={{ textAlign: "center", marginTop: "60px" }}>
                            <h2>Payment Information</h2>
                        </h3>
                        <h3>
                            <strong style={{ color: "green" }}> Amount : </strong>
                            {state.totalAmount}Rs
                        </h3>
                        <h3 style={{ display: "flex" }}>
                            <strong style={{ color: "green" }}> Order Type : </strong>
                            {state.orderType === "paynow" ? <div> Pay Now</div> : <div>{state.orderType}</div>}
                        </h3>
                        <h3 style={{ display: "flex" }}>
                            <strong style={{ color: "green" }}> Payment Status : </strong>
                            {state.paymentStatus === "done" ? <div> Paid</div> : <div></div>}
                        </h3>
                        <h3 style={{ display: "flex" }}>
                            <strong style={{ color: "green" }}> Payment ID : </strong>
                            {/* {state.paymentId.length > 1 ? <div> {state.paymentId}</div> : <div></div>} */}
                        </h3>
                    </div>

                </div>

                {(window.localStorage.getItem("userType") === "admin"||window.localStorage.getItem("userType") === "sales"||window.localStorage.getItem("userType") === "accounts")&& state.orderStatus!=="shipped" && 
                <div style={{ display: "flex", justifyContent: "center", margin: "30px" }}>
                    <InputText
                        style={{ marginRight: "20px" }}
                        placeholder='Enter Tracking ID'
                        value={trackingId}
                        onChange={(e) => setTrackingId(e.target.value)}
                    />
                    <Button
                        onClick={() => {
                            postTrackingId(state._id, { "trackingId": trackingId }).then((res) => {
                                if (res.trackingId) {
                                    showSuccess()
                                    setTimeout(()=>{
                                        navigate("/pendingOrders")
                                    },3000)
                                }
                            })
                        }}
                    >Mark as Shipped</Button>
                </div>}
            </Panel>



        </div>
    )
}

export default MTMOrderDetail