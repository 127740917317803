import React, { useState } from 'react'
import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { useLocation, useNavigate } from "react-router-dom";
import MenuNavigator from '../../../components/MenuNavigator'
function DeliveryAddress() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const sState = state
    const modeOfTransport = [
        "SM Air",
        "SM Surface",
        "SMGC Logistics",
        "VRL Logistics",
        "VRL Bus",
        "SRC Bus",
        "G Shantilal",
        "Any Other"
    ]
    console.log(sState)

    const [formData, setFormData] = useState({});
    const [showMessage, setShowMessage] = useState(false);
    const data = JSON.parse(localStorage.getItem("userDetails"));

    const formik = useFormik({
        initialValues: {
            companyName: data.companyName,
            deliveryAddress: data.deliveryAddress1,
            state: data.deliveryState,
            pincode: data.deliveryPincode,
            contactNumber: data.mobileNumber,
            modeOfTransport: null,
            optionalTransport:""
        },
        validate: (data) => {
            console.log("Validated->", data)
            let errors = {};
            if (!data.companyName) {
                errors.companyName = 'Required.';
            }

            if (!data.deliveryAddress) {
                errors.deliveryAddress = 'Required.';
            }
            if (!data.pincode) {
                errors.pincode = 'Required';
            }
            if (!data.state) {
                errors.state = 'Required';
            }

            if (!data.contactNumber) {
                errors.contactNumber = 'Required';
            }
            if (!data.modeOfTransport) {
                errors.modeOfTransport = 'Required';
            }if (data.modeOfTransport==="Any Other"&& !data.optionalTransport) {
                errors.optionalTransport = 'Required';
            }
            console.log("Errors->", errors)
            return errors;
        },
        onSubmit: (delivery) => {
            setFormData(delivery);

            setShowMessage(true);
            console.log("test", Object.keys(sState))
            formik.resetForm();

            navigate("/MTMQuotation", {
                state: { ...sState, delivery },
            });



        }
    });
    console.log(sState[0])
    return (
        <div>
            <MenuNavigator />
            <div>
                <Divider align="center">
                    <h2 style={{ textAlign: "center" }}>Delivery Details</h2>
                </Divider>

                <div style={{ display: "flex", justifyContent: "space-evenly" }} className="p-fluid" >
                    <img style={{ height: "350px" }} alt="" src="https://img.freepik.com/free-vector/address-illustration-concept_114360-301.jpg?t=st=1649871081~exp=1649871681~hmac=d67a364e9bbd4cc5c6d2dd78bb9e6a50026bf5c9863f95523bdd6b3343a256a3&w=740" />
                    <form onSubmit={formik.handleSubmit} >

                        <div style={{ marginTop: "0px", margin: "10px 0px" }} className="">
                            <label htmlFor="companyName">Company Name<span style={{ color: "red" }}>*</span></label>
                            <InputText
                                style={{ height: "30px", marginBottom: "0px", }}
                                id="companyName"
                                name="companyName"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.companyName}
                            />
                            <div style={{ color: "red" }}>  {formik.errors.companyName}</div>
                        </div>
                        <div style={{ margin: "10px 0px" }} className="">
                            <label htmlFor="deliveryAddress">Delivery Address<span style={{ color: "red" }}>*</span></label>
                            <InputText
                                style={{ height: "30px", marginBottom: "0px" }}
                                id="deliveryAddress"
                                name="deliveryAddress"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.deliveryAddress}
                            />
                            <div style={{ color: "red" }}>  {formik.errors.deliveryAddress}</div>
                        </div>
                        <div style={{ margin: "10px 0px" }} className="">
                            <label htmlFor="state">State<span style={{ color: "red" }}>*</span></label>
                            <InputText
                                style={{ height: "30px", marginBottom: "0px" }}
                                id="state"
                                type="text"
                                name="state"
                                onChange={formik.handleChange}
                                value={formik.values.state}
                            />
                            <div style={{ color: "red" }}>  {formik.errors.state}</div>
                        </div>
                        <div style={{ margin: "10px 0px" }} className="">
                            <label htmlFor="pincode">Pincode<span style={{ color: "red" }}>*</span></label>
                            <InputText
                                style={{ height: "30px", marginBottom: "0px" }}
                                id="pincode"
                                type="text"
                                name="pincode"
                                onChange={formik.handleChange}
                                value={formik.values.pincode}
                            />
                            <div style={{ color: "red" }}>  {formik.errors.pincode}</div>
                        </div>
                        <div style={{ margin: "10px 0px" }} className="">
                            <label htmlFor="contactNumber">Contact Number<span style={{ color: "red" }}>*</span></label>
                            <InputText
                                style={{ height: "30px", marginBottom: "0px" }}
                                id="contactNumber"
                                name="contactNumber"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.contactNumber}
                            />
                            <div style={{ color: "red" }}>  {formik.errors.contactNumber}</div>
                        </div>
                        <div style={{ margin: "10px 0px" }} className="">
                            <label htmlFor="modeOfTransport">Mode Of Transport<span style={{ color: "red" }}>*</span></label>
                            <Dropdown
                                style={{ height: "30px", marginBottom: "0px" }}
                                id="modeOfTransport"

                                onChange={formik.handleChange}
                                options={modeOfTransport}
                                value={formik.values.modeOfTransport}
                            />
                            <div style={{ color: "red" }}>  {formik.errors.modeOfTransport}</div>
                        </div>
                        {formik.values.modeOfTransport === "Any Other" && <div style={{ margin: "10px 0px" }} className="">
                            <label htmlFor="optionalTransport">Transport</label>
                            <InputText
                                style={{ height: "30px", marginBottom: "0px" }}
                                id="optionalTransport"
                                name="optionalTransport"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.optionalTransport}
                            />
                            <div style={{ color: "red" }}>  {formik.errors.optionalTransport}</div>
                        </div>}

                        <div style={{ textAlign: "center" }}>
                            <Button
                                label="Get Quotation"
                                style={{ margin: "20px 0px", width: "50%", }}

                            ></Button>
                        </div>

                    </form>

                </div>

            </div>

        </div>
    )
}

export default DeliveryAddress