import axios from "axios";

const login = async (email, password, userType) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/auth`,
    { email, password, userType },
    { headers: { "Content-Type": "application/json" } }
  );
  return response;
};

const getAllUsers = async (currentPage, limit, user) => {
  let url = "";
  if (user !== "User Type") {
    url = `&userType=${user}`;
  }
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/users?page=${currentPage}&limit=${limit}${url}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const searchUsers = async (user) => {
  const url = `?name=${user}`;
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/users${url}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const getAllUserList = async (user) => {
  let url = "";
  if (user !== "User Type") {
    url = `?userType=${user}`;
  }
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/users/allUsers${url}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};

const getAllFabrics = async (page, search) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/fabrics/?page=${page}&limit=${10}&name=${search}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const getFabrics = async (page, limit) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/fabrics/?page=${page}&limit=${limit}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};

const getAllCatalogues = async (page,search) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/catalogues/?page=${page}&limit=${10}&name=${search}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const getCatalogues = async (page,limit) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/catalogues/?page=${page}&limit=${limit}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const updateCatalogue = async (id, params) => {
  console.log("params", params);
  const response = await axios.put(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/catalogues/${id}`,
    params,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const updateFabric = async (id, params) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/fabrics/${id}`,
    params,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const deleteCatalogue = async (id) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/catalogues/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const deleteFabric = async (id) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/fabrics/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const addNewUser = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/users`,
    params,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response;
};

const addNewFabric = async (params) => {
  console.log("data", params);
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/fabrics`,
    // { ...params },
    params,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const getProfileDetail = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/auth`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const getMtmTypes = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/mtm/all`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const getMtmDetails = async (params) => {
  console.log("params :", params);
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/mtm/?${params}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const getUserDetails = async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/users/${id}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const deleteUser = async (id) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/users/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const updateUser = async (id, params) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/users/${id}`,
    { ...params },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const updateProfile = async (params) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/users/editProfile`,
    { ...params },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const getFabricDetails = async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/fabrics/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};

const getCatalogueDetails = async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/catalogues/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const getUserOrders = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/orders/users`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const getAllOrders = async (currentPage) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/orders/?page=${currentPage}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};

const getAllOrdersByType = async (currentPage, Type) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/orders/orderFilter?page=${currentPage}&type=${Type}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};

const addNewCatalogue = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/catalogues`,
    // { ...params },
    params,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const createFabricOrder = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/orders/fabrics`,
    { ...params },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const createCatalogueOrder = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/orders/catalogues`,
    { ...params },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const createOrder = async (params) => {
  console.log("params", params);
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/orders`,
    { ...params },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const createOrderByAdmin = async (params, id) => {
  console.log("params", params);
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/orders/${id}/createorder`,
    { ...params },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const createMTMOrder = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/orders/madeToMeasure`,
    { ...params },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const postMTMSetting = async (body) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/mtm`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response;
};
const updateMTMSetting = async (body, id) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/mtm/${id}`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response;
};
const payExpiredOrders = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/orders/pay`,
    { ...params },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const postTrackingId = async (orderId, tracking) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/orders/${orderId}/shipped`,
    { ...tracking },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const getOrderDetails = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/orders/users`,
    { ...params },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const getMyOrders = async (currentPage) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/orders/ordersById/?page=${currentPage}`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const getAdminDashboardData = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/users/adminDashboard`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const getUserDashboardData = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/users/userDashboard`,

    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};

const verifyPayment = async (id, data) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/orders/verifyPayment/${id}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};

const createBulkOrder = async (data) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/orders/bulkOrder`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};
const verifyBulkOrderPayment = async (data) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/orders/bulkOrder/verifyPayment`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: window.localStorage.getItem("userId"),
      },
    }
  );
  return response.data;
};

export {
  verifyBulkOrderPayment,
  createBulkOrder,
  deleteUser,
  login,
  getAllUsers,
  getAllFabrics,
  getAllCatalogues,
  updateCatalogue,
  addNewUser,
  addNewCatalogue,
  getUserDetails,
  addNewFabric,
  getFabricDetails,
  getCatalogueDetails,
  getProfileDetail,
  updateUser,
  deleteCatalogue,
  deleteFabric,
  updateFabric,
  createFabricOrder,
  createCatalogueOrder,
  createMTMOrder,
  postMTMSetting,
  getOrderDetails,
  getUserOrders,
  getAllOrders,
  getAllOrdersByType,
  createOrder,
  postTrackingId,
  getMyOrders,
  payExpiredOrders,
  updateProfile,
  verifyPayment,
  getAdminDashboardData,
  getUserDashboardData,
  getMtmTypes,
  getMtmDetails,
  updateMTMSetting,
  getAllUserList,
  searchUsers,
  createOrderByAdmin,
  getCatalogues,
  getFabrics
};
