import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import { createBulkOrder, createOrder } from "../../../../api/api";
import { Button } from "primereact/button";
import { displayRazorpay } from "../../PaymentIntegration";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useLocation, useNavigate } from "react-router-dom";
import { clearCatalogue, clearMTM } from "../../../../redux/cart/cartSlice";
import { useDispatch } from "react-redux";

function CartDelivery() {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const [paymentType, setPaymentType] = useState("");
  const paymentOption = JSON.parse(
    window.localStorage.getItem("userDetails")
  ).paymentOption;
  console.log(window.localStorage.getItem("id"));
  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Payment Recieved",
      detail:
        "Your Order is Placed. You will be redirected to the Fabrics Page",
    });
  };
  const error = (message) => {
    toast.current.show({
      severity: "error",
      summary: message,
      detail: "Please try Again",
    });
  };
  const move = () => {
    if (location.state.isMtm) {
      dispatch(clearMTM());
    } else {
      dispatch(clearCatalogue());
    }
    navigate("/userDashboard");
  };
  console.log("isMtM", location.state.isMtm);
  const fabricsAndCateloguesOrders = (del) => {
    const { optionalTransport, ...delivery } = del;
    let order = [];
    const fabricOrder = state.fabrics.map((item) => {
      return {
        fabric: item._id,
        qty: JSON.stringify(item.qty),
        ...delivery,
        name: "fabric",
        orderType: paymentType,
      };
    });
    const catelogueOrder = state.catelogues.map((item) => {
      return {
        catalogue: item._id,
        qty: JSON.stringify(item.qty),
        ...delivery,
        name: "catalogue",
        orderType: paymentType,
      };
    });
    order = [...fabricOrder, ...catelogueOrder];
    console.log(order);

    createBulkOrder(order).then((res) => {
      console.log(res);
      displayRazorpay({
        data: res,
        finalAmount: res.razorpayOrder.amount,
        success: showSuccess,
        error: error,
        navigate: move,
      }).catch(() => error("Couldn't able to Create Order"));
    });
  };

  const madeToMeasureOrder = (del) => {
    createOrder({
      orderType: paymentType,
      name: "madeToMeasure",
      companyName: del.companyName,
      deliveryAddress: del.deliveryAddress,
      state: del.state,
      pincode: del.pincode,
      contactNumber: del.contactNumber,
      modeOfTransport: del.modeOfTransport,
      optionalTransport: del.optionalTransport || "",
      qty: "1",
      subOrders: state.madeToMeasureCart.map((obj) => ({
        roomType: obj.roomType,
        window: obj.window,
        catalogue: obj.catalogue,
        fabric: obj.fabric,
        accessory: obj.accessory,
        remark: obj.remark,
        stitch: obj.stitch,
        lining: obj.lining,
        type: obj.type,
        fullness: obj.fullness,
        measurement: obj.measurement,
        height: obj.height,
        width: obj.width,
        installationType: obj.installationType,
      })),
    })
      .then((res) => {
        displayRazorpay({
          data: res,
          finalAmount: res.razorpayOrder.amount,
          success: showSuccess,
          error: error,
          navigate: move,
        });
      })
      .catch(() => error("Couldn't able to Create Order"));
  };
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state);
  const modeOfTransport = [
    "SM Air",
    "SM Surface",
    "SMGC Logistics",
    "VRL Logistics",
    "VRL Bus",
    "SRC Bus",
    "G Shantilal",
    "Any Other",
  ];

  const data = JSON.parse(localStorage.getItem("userDetails"));

  const formik = useFormik({
    initialValues: {
      companyName: data.companyName,
      deliveryAddress: data.deliveryAddress1,
      state: data.deliveryState,
      pincode: data.deliveryPincode,
      contactNumber: data.mobileNumber,
      modeOfTransport: null,
      optionalTransport: "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.companyName) {
        errors.companyName = "Required.";
      }

      if (!data.deliveryAddress) {
        errors.deliveryAddress = "Required.";
      }
      if (!data.pincode) {
        errors.pincode = "Required";
      }
      if (!data.state) {
        errors.state = "Required";
      }

      if (!data.contactNumber) {
        errors.contactNumber = "Required";
      }
      if (!data.modeOfTransport) {
        errors.modeOfTransport = "Required";
      }
      if (data.modeOfTransport === "Any Other" && !data.optionalTransport) {
        errors.optionalTransport = "Required";
      }

      return errors;
    },
    onSubmit: (del) => {
      if (Object.keys(state).includes("madeToMeasureCart")) {
        madeToMeasureOrder(del);
      } else {
        fabricsAndCateloguesOrders(del);
      }
    },
  });

  console.log(formik.values);
  return (
    <div>
      <div>
        <Toast ref={toast}></Toast>
        <h2 style={{ textAlign: "center" }}>Delivery Details</h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid flex justify-content-evenly">
            <img
              style={{ height: "450px" }}
              alt=""
              src="https://img.freepik.com/free-vector/address-illustration-concept_114360-301.jpg?t=st=1649871081~exp=1649871681~hmac=d67a364e9bbd4cc5c6d2dd78bb9e6a50026bf5c9863f95523bdd6b3343a256a3&w=740"
            />
            <div>
              <div style={{ margin: "10px 0px" }} className="">
                <label htmlFor="companyName">
                  Company Name<span style={{ color: "red" }}>*</span>
                </label>
                <InputText
                  id="companyName"
                  name="companyName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.companyName}
                />
                <div style={{ color: "red" }}> {formik.errors.companyName}</div>
              </div>
              <div style={{ margin: "10px 0px" }} className="">
                <label htmlFor="deliveryAddress">
                  Delivery Address<span style={{ color: "red" }}>*</span>
                </label>
                <InputText
                  id="deliveryAddress"
                  name="deliveryAddress"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.deliveryAddress}
                />
                <div style={{ color: "red" }}>
                  {" "}
                  {formik.errors.deliveryAddress}
                </div>
              </div>
              <div style={{ margin: "10px 0px" }} className="">
                <label htmlFor="state">
                  State<span style={{ color: "red" }}>*</span>
                </label>
                <InputText
                  id="state"
                  type="text"
                  name="state"
                  onChange={formik.handleChange}
                  value={formik.values.state}
                />
                <div style={{ color: "red" }}> {formik.errors.state}</div>
              </div>
              <div style={{ margin: "10px 0px" }} className="">
                <label htmlFor="pincode">
                  Pincode<span style={{ color: "red" }}>*</span>
                </label>
                <InputText
                  id="pincode"
                  type="text"
                  name="pincode"
                  onChange={formik.handleChange}
                  value={formik.values.pincode}
                />
                <div style={{ color: "red" }}> {formik.errors.pincode}</div>
              </div>
              <div style={{ margin: "10px 0px" }} className="">
                <label htmlFor="contactNumber">
                  Contact Number<span style={{ color: "red" }}>*</span>
                </label>
                <InputText
                  id="contactNumber"
                  name="contactNumber"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.contactNumber}
                />
                <div style={{ color: "red" }}>
                  {" "}
                  {formik.errors.contactNumber}
                </div>
              </div>
              <div style={{ margin: "10px 0px" }} className="">
                <label htmlFor="modeOfTransport">
                  Mode Of Transport<span style={{ color: "red" }}>*</span>
                </label>
                <Dropdown
                  id="modeOfTransport"
                  onChange={formik.handleChange}
                  options={modeOfTransport}
                  value={formik.values.modeOfTransport}
                />
                <div style={{ color: "red" }}>
                  {" "}
                  {formik.errors.modeOfTransport}
                </div>
              </div>
              {formik.values.modeOfTransport === "Any Other" && (
                <div style={{ margin: "10px 0px" }} className="">
                  <label htmlFor="optionalTransport">Transport</label>
                  <InputText
                    style={{ height: "30px" }}
                    id="optionalTransport"
                    name="optionalTransport"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.optionalTransport}
                  />
                  <div style={{ color: "red" }}>
                    {" "}
                    {formik.errors.optionalTransport}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-content-center">
            <Button
              type="submit"
              onClick={() => setPaymentType("paynow")}
              className="w-2 m-3"
              label="Pay Now"
            ></Button>
            {paymentOption === "credit" && (
              <Button
                onClick={() => setPaymentType("credit")}
                className="w-2 m-3"
                label="Buy On Credit"
              ></Button>
            )}
            {paymentOption === "advance" && (
              <Button
                onClick={() => setPaymentType("advance")}
                className="w-2 m-3"
                label="Pay Advance"
              ></Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default CartDelivery;
