import React, { useContext } from "react";

import { useNavigate, Navigate } from "react-router-dom";

function IsUser({ children }) {

  const check = () => {
    if (window.localStorage.getItem("userType")) {
      if (window.localStorage.getItem("userType")) {
        return true;
      }
      return false;
    }
  };

  return <div>
    <div className="">
      <div className="">
        {check() ? children : <Navigate to="/login" />}
      </div>
    </div>

  </div>;
}

export default IsUser;
