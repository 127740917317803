import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import MenuNavigator from "../../../components/MenuNavigator";

import { Toast } from "primereact/toast";
import { addNewUser } from "../../../api/api";
import { MultiSelect } from "primereact/multiselect";

const AddNewMemberForm = () => {
  const [userType, setUserType] = useState(null);
  // const [city, setCity] = useState(null);
  const userTypeOptions = ["admin", "accounts", "sales"];
  const [formData, setFormData] = useState({});
  // const cityOptions = ['Bangalore', 'Surat', 'Mumbai', 'Delhi'];
  const [creditChecked, setCreditChecked] = useState(true);
  const [checked, setChecked] = useState(false);

  const rolls = ["Pricing", "Orders", "Products", "Settings"];
  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Success!",
      detail: "User Added",
    });
  };
  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error!",
      detail: message,
    });
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobileNumber: "",
      userType: null,
      roles: null,
      accessory: "",
      is_made_to_measure: false,
    },
    validate: (data) => {
      let errors = {};

      if (!data.name) {
        errors.name = "Name is required.";
      }
      if (!data.email) {
        errors.email = "Email is required.";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = "Invalid email address. E.g. example@email.com";
      }
      if (!data.mobileNumber) {
        errors.mobileNumber = "This field is mandatory";
      }
      if (!data.userType) {
        errors.userType = "This field is mandatory";
      }
      if (!data.roles) {
        errors.roles = "This field is mandatory";
      }
      return errors;
    },
    onSubmit: (data) => {
      const nData = {
        paymentOption: "paynow",
        companyName: "Batavia Exim",
        address1: "df",
        address2: "fds",
        address3: "fsd",
        address4: "fsd",
        city: "Chennai",
        state: "KA",
        country: "IN",
        pincode: "44567",
        GST: "ertyu",
        deliveryAddress1: "234",
        deliveryAddress2: "432",
        deliveryAddress3: "423",
        deliveryAddress4: "432",
        deliveryState: "AN",
        deliveryCountry: "TZ",
        deliveryPincode: "567",
        aadhar: "42332",
        discountPercentage: 33,
        creditPeriod: 4,
        currentOutstanding: 0,
      };
      console.log("data", data);
      const newData = { ...data, ...nData };
      console.log("data2", newData);

      setFormData(newData);

      addNewUser(newData)
        .then((data) => {
          console.log(data);
          if (Object.keys(data.data).includes("token")) {
            showSuccess();
            formik.resetForm();
          }
        })
        .catch((e) => {
          console.log(e);
          showError("User Already Exists");
        });
    },
  });
 formik.values.is_made_to_measure=checked;
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const toast = useRef(null);
  return (
    // <Envelope>
    <div>
      <Divider align="center">
        <Toast ref={toast}></Toast>
        <h2 className="p-text-center">Add New User</h2>
      </Divider>
      <form onSubmit={formik.handleSubmit} className="p-fluid">
        <div
          className="p-fluid"
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <div style={{ width: "30%" }}>
            <div className="p-field">
              <label htmlFor="email">Email*</label>
              <InputText
                id="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className={classNames({ "p-error": isFormFieldValid("email") })}
              />
              {getFormErrorMessage("email")}
            </div>
            <div className="p-field">
              <label htmlFor="name">Name*</label>
              <InputText
                id="name"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
                className={classNames({ "p-error": isFormFieldValid("name") })}
              />
              {getFormErrorMessage("name")}
            </div>

            <div className="p-field">
              <label htmlFor="mobileNumber">Mobile Number*</label>
              <InputText
                id="mobileNumber"
                type={"number"}
                value={formik.values.mobileNumber}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("mobileNumber"),
                })}
                maxLength={10}
              />

              {getFormErrorMessage("mobileNumber")}
            </div>

            <div className="p-field">
              <label htmlFor="usertype">User Type*</label>
              {/* <InputText id="creditPeriod" type="text" /> */}
              <Dropdown
                // optionLabel="name"
                options={userTypeOptions}
                id="userType"
                name="userType"
                placeholder="Select Type of User"
                value={formik.values.userType}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("userType"),
                })}
              />
              {getFormErrorMessage("userType")}
            </div>
            <div className="p-field">
              <label htmlFor="roles">User Rolls *</label>
              <MultiSelect
                options={rolls}
                placeholder="Select user roll"
                id="roles"
                display="chip"
                value={formik.values.roles}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("roles"),
                })}
              />
              {getFormErrorMessage("roles")}
            </div>
            <div className="p-field">
              <label htmlFor="accessory">Accessory*</label>
              <Dropdown
                options={["Yes", "No"]}
                id="accessory"
                value={formik.values.accessory}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("accessory"),
                })}
              />
              {getFormErrorMessage("paymentOption")}
            </div>
            <div className="p-field flex align-items-center">
              <Checkbox
                onChange={(e) => setChecked(e.checked)}
                checked={checked}
              />
              <label className="ml-2 my-2">Made To Measure</label>
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center", margin: "30px 0px" }}>
          <Button
            label="Create User"
            style={{ width: "20%", background: "#287F80" }}
            className="p-block text-center"
            disabled={!(formik.isValid && formik.dirty)}
          ></Button>
        </div>
      </form>
    </div>
  );
};

export default AddNewMemberForm;
