import { verifyPayment, verifyBulkOrderPayment } from "../../api/api";

const loadScript = (src) => {
  return new Promise((resovle) => {
    const script = document.createElement("script");
    script.src = src;

    script.onload = () => {
      resovle(true);
    };

    script.onerror = () => {
      resovle(false);
    };

    document.body.appendChild(script);
  });
};

export const displayRazorpay = async ({
  success = () => {},
  data,
  finalAmount,
  error = () => {},
  navigate = () => {},
}) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("You are offline... Failed to load Razorpay SDK");
    return;
  }
  console.log(finalAmount);
  const options = {
    order_id: data.razorpayOrder.id,
    key: "rzp_test_4Q4zocsPKwmT2q",
    currency: "INR",
    amount: finalAmount * 1000,
    name: "Batavia",
    description: "Thanks for purchasing",

    handler: function (response) {
      if (Object.keys(data).includes("newOrder")) {
        verifyPayment(data.newOrder._id, response)
          .then((res) => {
            if (res === "Payment Successful") {
              console.log("From Single");
              success();
              setTimeout(() => {
                navigate();
              }, 2000);
            }
          })
          .catch(() => error("Couldn't able to verify Payment"));
      }
      if (Object.keys(data).includes("bulkOrder")) {
        const orderIds = data.bulkOrder.map((item) => item._id);
        verifyBulkOrderPayment({ orderIds, ...response })
          .then((res) => {
            if (res === "Payment Successful") {
              success();
              setTimeout(() => {
                navigate();
              }, 2000);
            }
          })
          .catch(() => error("Couldn't able to verify Payment"));
      }
    },
    prefill: {
      name: "Batavia",
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};
