import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

function RecentOrders({ orders }) {
  const totalAmount = (data) => (
    <div className="text-center">{parseInt(data.totalAmount)} Rs</div>
  );
  return (
    <div
      style={{
        boxShadow: "0px 0px 20px rgb(0,0,0,0.2)",
        borderRadius: "10px",
        margin: "10px",
      }}
      className="p-5"
    >
      <DataTable paginator rows={10} value={orders}>
        <Column
          bodyClassName="capitalize"
          field="orderId"
          header="Order Id"
        ></Column>
        <Column
          bodyClassName="capitalize"
          field="companyName"
          header="Company Name"
        ></Column>
        <Column
          bodyClassName="capitalize"
          field="name"
          header="Product Name"
        ></Column>
        <Column
          bodyClassName="capitalize"
          field="paymentStatus"
          header="Payment Status"
        ></Column>
        <Column
          bodyClassName="capitalize"
          field="orderStatus"
          header="Order Status"
        ></Column>
        <Column
          bodyClassName="capitalize"
          field="orderType"  
          header="Order type"
        ></Column>
        <Column
          bodyClassName="capitalize"
          field="modeOfTransport"
          header="Mode Of Transport"
        ></Column>
        <Column body={totalAmount} header="Total Amount"></Column>
      </DataTable>
    </div>
  );
}

export default RecentOrders;
