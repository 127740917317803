import React, { useState, useEffect, useRef } from "react";
import MenuNavigator from "../../../components/MenuNavigator";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import {
  postMTMSetting,
  getMtmTypes,
  updateMTMSetting,
} from "../../../api/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
function Settings() {
  const [mainType, setMainType] = useState("");
  const [subtype, setSubType] = useState("");
  const [types, setTypes] = useState([]);
  const [price, setPrice] = useState("");
  const [updatedPrice, setUpdatedPrice] = useState([]);
  const [update, setUpdate] = useState(false);
  const mainTypeOptions = ["Installation", "Stitching"];
  const installationTypeOptions = ["In Bangalore", "Outside Bangalore"];
  const stritchingOptions = [
    "Eyelet",
    "Pleated",
    "Eliza",
    "Roman Blinds",
    "Blind System",
  ];

  const toast = useRef(null);

  const handleClick = async () => {
    const body = {
      mainType: mainType,
      subType: subtype,
      price: price,
    };

    const response = await postMTMSetting(body);
    if (response.status === 201) {
      console.log(body);
      setUpdate(!update);
      toast.current.show({
        severity: "success",
        summary: "Submit Successful",
        detail: "",
        life: 3000,
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error Occurred",
        detail: "",
        life: 3000,
      });
    }
  };
  useEffect(() => {
    getMtmTypes().then((res) => {
      const data = [];
      const price = [];
      res.map((item, index) => {
        data.push({ ...item, ind: index });
        price.push({ price: item.price });
      });
      setTypes(data);
      setUpdatedPrice(price);
    });
  }, [update]);
  console.log("data", types);
  const handlePrice = (val, target) => {
    const update = updatedPrice?.map((item, ind) => {
      if (ind === val) {
        return { ...item, price: target };
      } else {
        return item;
      }
    });
    setUpdatedPrice(update);
  };
  const updatePricing = async (ind, id) => {
    const res = await updateMTMSetting({ price: updatedPrice[ind]?.price }, id);
    if (res.status === 201) {
      setUpdate(!update);
      toast.current.show({
        severity: "success",
        summary: "Updated Successfully",
        detail: "",
        life: 3000,
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error Occurred",
        detail: "",
        life: 3000,
      });
    }
  };
  const displayPrice = (rowData) => {
    return (
      <span className="flex flex-row justify-content-between w-9">
        <InputText
          id="price"
          name="price"
          placeholder="Price"
          value={updatedPrice[rowData.ind]?.price}
          style={{ width: "7rem" }}
          onChange={(e) => {
            handlePrice(rowData.ind, e.target.value);
          }}
        />
        <Button
          label="Update"
          onClick={() => {
            updatePricing(rowData.ind, rowData._id);
          }}
          // style={{ width: "10rem" }}
        />
      </span>
    );
  };
  return (
    <div>
      <Toast ref={toast} />
      <MenuNavigator />
      <div className="flex">
        <div className="flex flex-column w-4 m-3 p-3">
          <div className="text-xl text-center m-2">
            Made to Measure Variables
          </div>
          <Dropdown
            className="my-2"
            value={mainType}
            placeholder="Main Type"
            onChange={(e) => setMainType(e.target.value)}
            options={mainTypeOptions}
          />
          <Dropdown
            className="my-2"
            placeholder="Sub Type Type"
            value={subtype}
            onChange={(e) => setSubType(e.target.value)}
            options={
              mainType === "Installation"
                ? installationTypeOptions
                : stritchingOptions
            }
          />

          <InputText
            className="my-2"
            placeholder="Price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          <Button label="Submit" onClick={handleClick} />
        </div>
        <DataTable
          rows={7}
          paginator
          showGridlines
          rowHover
          value={types}
          className="m-5 p-3 w-8"
        >
          <Column field="mainType" header="Main Type"></Column>
          <Column field="subType" header="Sub Type"></Column>
          <Column
            // field="price"
            body={displayPrice}
            header="Price"
          ></Column>
        </DataTable>
      </div>
    </div>
  );
}

export default Settings;
