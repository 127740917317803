
import { useLocation } from "react-router-dom";
import { payExpiredOrders } from "../../../api/api"

const loadScript = (src) => {

  return new Promise((resovle) => {
    const script = document.createElement("script");
    script.src = src;

    script.onload = () => {
      resovle(true);
    };

    script.onerror = () => {
      resovle(false);
    };

    document.body.appendChild(script);
  });
};

export const displayRazorpay = async (data) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("You are offline... Failed to load Razorpay SDK");
    return;
  }

  const options = {
    key: "rzp_test_lv5SM6RhgIHrJp",
    key_secret: "HsCvM0bjFjRjIE4rURTwqrsr",
    currency: "INR",
    amount: data.finalAmount * 100,
    name: "Shloka Concepts",
    description: "Thanks for purchasing",

    handler: function (response) {
      console.log(response)
      if (response.razorpay_payment_id.length > 1) {
        payExpiredOrders(data.orders).then(res => {
          if (res.msg) {
            console.log("Success")
            window.location.reload();

          } else {
            console.log("Error")
          }
        })

      }else{
        console.log("Payment Failed")
      }
    },
    prefill: {
      name: "Shloka Concepts",
    },


  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};

export function PaymentIntegration() {
  const { state } = useLocation();

  return <div className="App"></div>;
}


