import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

function SalesBarChart({ data, labels }) {
  let fabricSales = [];
  let catelogueSales = [];
  let mtmSales = [];
  if (data?.graphDate) {
    const graphData = data?.graphDate.map((item) => item).reverse();
    fabricSales = graphData.map((item) => item.totalFabricSales);
    catelogueSales = graphData.map((item) => item.totalCatalogueSales);
    mtmSales = graphData.map((item) => item.totalMtmSales);
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scale: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  const chartData = {
    labels,
    datasets: [
      {
        label: "Fabrics",
        data: fabricSales,
        backgroundColor: "#FFCA28",
      },
      {
        label: "Catelogues",
        data: catelogueSales,
        backgroundColor: "#EC407A",
      },
      {
        label: "Made To Measure",
        data: mtmSales,
        backgroundColor: "#42A5F5",
      },
    ],
  };

  return (
    <div
      style={{
        boxShadow: "0px 0px 20px rgb(0,0,0,0.2)",
        borderRadius: "10px",
        margin: "10px",
      }}
    >
      <div style={{ height: "300px" }} className="m-4 p-3">
        <Bar options={options} data={chartData} />
      </div>
    </div>
  );
}

export default SalesBarChart;
