import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { TabMenu } from "primereact/tabmenu";
import { useNavigate } from "react-router-dom";
import AddNewUser from "./AddNewUser";
import MenuNavigator from "../../../components/MenuNavigator";
import AddNewMemberForm from "./AddNewMemberForm";

export default function UserTab() {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const items = [
    { label: "Within the Organization", icon: "pi pi-fw pi-home" },
    { label: "Outside the Organization", icon: "pi pi-fw pi-home" },
  ];
  useEffect(() => {}, []);
  return (
    <div className="card">
      <MenuNavigator />
      <TabMenu
        model={items}
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      />
      <div>
        {activeIndex === 0 ? (
          <>
            <AddNewMemberForm />
          </>
        ) : (
          <>
            <AddNewUser />
          </>
        )}
      </div>
    </div>
  );
}
