// import { Route, Router, Switch } from 'react-router';
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { CheckIsAdmin2, IsAdmin, IsAdmin2 } from "./guards/IsAdmin";
import IsUser from "./guards/IsUser";
import "./App.css";
import Envelope from "./layout/Envelope";
import AddNewFabric from "./pages/admin/fabric/AddNewFabric";
import AddNewUser from "./pages/admin/user/AddNewUser";
import ListOfAllUsers from "./pages/admin/user/ListOfAllUsers";
import ListOfAllFabrics from "./pages/admin/fabric/ListOfAllFabrics";
import Login from "./pages/Login";
import FabricDetails from "./pages/admin/fabric/FabricDetails";
import ListOfAllCatalogues from "./pages/admin/catalogue/ListOfAllCatalogues";
import AddNewCatalogue from "./pages/admin/catalogue/AddNewCatalogue";
import CatalogueDetails from "./pages/admin/catalogue/CatalogueDetails";
import Dashboard from "./pages/employee/Dashboard";
import MyPendingOrders from "./pages/customer/myorders/PendingOrders";
import OrderDetails from "./pages/employee/OrderDetails";
import PlaceNewOrder from "./pages/employee/PlaceNewOrder";
import Inventory from "./pages/employee/Inventory";
import UsersPricingDetails from "./pages/customer/UsersPricingDetails";
import ProfileDetails from "./pages/customer/ProfileDetails";
import HistoricalOrders from "./pages/customer/HistoricalOrders";
import PlaceMadeToMeasureOrder from "./pages/customer/mtmo/CustomerMTMO";
import Payment from "./pages/customer/Payment";
import ListOfCatalogues from "./pages/customer/catalogue/ListOfCatalogues";
import CatalogueDetail from "./pages/customer/catalogue/CatalogueDetail";
import ListOfFabrics from "./pages/customer/fabric/ListOfFabrics";
import { FabricDetail } from "./pages/customer/fabric/FabricDetail";
import Shipping from "./pages/customer/Shipping";
import FabricQuotation from "./pages/customer/fabric/FabricQuotation";
import MTMDeliveryAddress from "./pages/customer/mtmo/MTMDeliveryAddress";
import AdminMTMO from "./pages/admin/mtmo/AdminMTMO";
import UserDetails from "./pages/admin/user/UserDetails";
import { UserProvider } from "./pages/UserContext";
import CatalogueQuotation from "./pages/customer/catalogue/CatalogueQuotation";
import Credit from "./pages/customer/Credit";
import MyFullfilledOrders from "./pages/customer/myorders/FullfilledOrders";
import AdminOrders from "./pages/admin/allOrders/AdminOrders";
import MTMQuotation from "./pages/customer/mtmo/MTMQuotation";
import UserProfile from "./pages/admin/UserProfile";
import AdminPricingDetails from "./pages/admin/AdminPricingDetails";
import FabricDelivery from "./pages/customer/fabric/FabricDelivery";
import CatalogueDelivery from "./pages/customer/catalogue/CatalogueDelivery";
import MTMAdminQuotation from "./pages/admin/mtmo/MTMAdminQuotation";
import MTMAdminDeliveryAddress from "./pages/admin/mtmo/MTMAdminDeliveryAddress";
import AllFabricsOrders from "./pages/admin/allOrders/FabricsOrders/AllFabricsOrders";
import AllCatalogueOrders from "./pages/admin/allOrders/CatalogueOrders/AllCatalogueOrders";
import AllMTMOrders from "./pages/admin/allOrders/MTMOrders/AllMTMOrders";
import FabricOrderdetail from "./pages/admin/allOrders/FabricsOrders/FabricOrderdetail";
import CatalogueOrderDetail from "./pages/admin/allOrders/CatalogueOrders/CatalogueOrderDetail";
import AllOrders from "./pages/admin/allOrders/AllOrders";
import MTMOrderDetail from "./pages/admin/allOrders/MTMOrders/MTMOrderDetail";
import MyOrders from "./pages/customer/myorders/MyOrders";
import ExpiredOrders from "./pages/customer/myorders/ExpiredOrders";
import PendingOrders from "./pages/admin/allOrders/PendingOrders";
import FullFilledOrders from "./pages/admin/allOrders/FullFilledOrders";
import AdminDashboard from "./pages/admin/dashboard/AdminDashboard";
import UsersDashboard from "./pages/customer/dashboard/UsersDashboard";
import CustomerCart from "./pages/customer/cart/CustomerCart";
import Settings from "./pages/admin/settings/Settings";
import {
  NestedOutlet,
  DefaultFabricOutlet,
} from "./pages/customer/fabric/FabricOutlet";
import { DefaultCatelogueOutlet } from "./pages/customer/catalogue/CatelogueOutet";
import CartDelivery from "./pages/customer/cart/components/CartDelivery";
import UserTab from "./pages/admin/user/UserTab";

function App() {
  return (
    <Envelope>
      <Router>
        <Routes>
          {/* Admin Routes */}
          <Route
            path="/profile"
            element={<IsAdmin children={<ProfileDetails />} />}
          />
          <Route
            path="/settings"
            element={<IsAdmin children={<Settings />} />}
          />
          <Route
            path="/userprofile"
            element={<IsUser children={<UserProfile />} />}
          />
          <Route
            path="/fullfilledOrders"
            element={<IsAdmin2 children={<FullFilledOrders />} />}
          />
          <Route
            path="/pendingOrders"
            element={<IsAdmin2 children={<PendingOrders />} />}
          />
          <Route
            path="/adminDashboard"
            element={<IsAdmin2 children={<AdminDashboard />} />}
          />
          <Route
            path="/allOrders"
            element={<IsAdmin2 children={<AllOrders />} />}
          />
          <Route
            path="/adminOrders"
            element={<IsAdmin2 children={<AdminOrders />} />}
          />
          <Route
            path="/catalogues"
            element={<IsAdmin2 children={<ListOfAllCatalogues />} />}
          />
          <Route
            path="/catalogues/new"
            element={<IsAdmin2 children={<AddNewCatalogue />} />}
          />
          <Route
            path="/catalogues/:id"
            element={<IsAdmin2 children={<CatalogueDetails />} />}
          />
          <Route
            path="/pricingAdmin/"
            element={<IsAdmin2 children={<AdminPricingDetails />} />}
          />
          <Route
            path="/adminMTMO/"
            // element={<IsAdmin children={<AdminMTMO />} />}
            element={<IsAdmin2 children={<AdminMTMO />} />}
          />
          <Route
            path="/adminMtmDelivery/"
            element={<IsAdmin2 children={<MTMAdminDeliveryAddress />} />}
          />
          <Route
            path="/adminMTMQuotation/"
            element={<IsAdmin2 children={<MTMAdminQuotation />} />}
          />
          <Route
            path="/allFabricsOrders"
            element={<IsAdmin2 children={<AllFabricsOrders />} />}
          />
          <Route
            path="/allCatalogueOrders"
            element={<IsAdmin2 children={<AllCatalogueOrders />} />}
          />
          <Route
            path="/allMTMOrders"
            element={<IsAdmin2 children={<AllMTMOrders />} />}
          />
          <Route
            path="/fabrics"
            element={<IsAdmin2 children={<ListOfAllFabrics />} />}
          />
          <Route
            path="/fabrics/new"
            element={<IsAdmin2 children={<AddNewFabric />} />}
          />
          <Route
            path="/fabrics/:id"
            element={<IsAdmin2 children={<FabricDetails />} />}
          />
          {/* End Admin Route */}
          {/* Customer Route */}
          <Route
            path="catelogue"
            element={<IsUser children={<DefaultCatelogueOutlet />} />}
          >
            <Route
              path="all"
              element={<IsUser children={<ListOfCatalogues />} />}
            ></Route>
            <Route path=":id" element={<IsUser children={<NestedOutlet />} />}>
              <Route
                path=""
                element={<IsUser children={<CatalogueDetail />} />}
              ></Route>
              <Route
                path="Cdelivery"
                element={<IsUser children={<NestedOutlet />} />}
              >
                <Route
                  path=""
                  element={<IsUser children={<CatalogueDelivery />} />}
                />
                <Route
                  path="catalogueQuotation"
                  element={<IsUser children={<CatalogueQuotation />} />}
                ></Route>
              </Route>
            </Route>
          </Route>
          <Route
            path="fabric"
            element={<IsUser children={<DefaultFabricOutlet />} />}
          >
            <Route
              path="all"
              element={<IsUser children={<ListOfFabrics />} />}
            ></Route>
            <Route path=":id" element={<IsUser children={<NestedOutlet />} />}>
              <Route
                path=""
                element={<IsUser children={<FabricDetail />} />}
              ></Route>
              <Route
                path="fdelivery"
                element={<IsUser children={<NestedOutlet />} />}
              >
                <Route
                  path=""
                  element={<IsUser children={<FabricDelivery />} />}
                />
                <Route
                  path="fabricQuotation"
                  element={<IsUser children={<FabricQuotation />} />}
                ></Route>
              </Route>
            </Route>
          </Route>
          <Route path="/pay" element={<IsUser children={<Payment />} />} />
          <Route
            path="/dashboard"
            element={<IsUser children={<Dashboard />} />}
          />
          <Route
            path="/myFullfilledOrders"
            element={<IsUser children={<MyFullfilledOrders />} />}
          />
          <Route
            path="/myOrders"
            element={<IsUser children={<MyOrders />} />}
          />
          <Route
            path="/myPendingOrders"
            element={<IsUser children={<MyPendingOrders />} />}
          />
          <Route
            path="/orders/new"
            element={<IsUser children={<PlaceNewOrder />} />}
          />
          <Route
            path="/orders/:id"
            element={<IsUser children={<OrderDetails />} />}
          />
          <Route path="/credit" element={<IsUser children={<Credit />} />} />
          <Route
            path="/catalogueDelivery"
            element={<IsUser children={<CatalogueDelivery />} />}
          />
          <Route
            path="/MTMaddress"
            element={<IsUser children={<MTMDeliveryAddress />} />}
          />
          <Route
            path="/MTMQuotation"
            element={<IsUser children={<MTMQuotation />} />}
          />
          <Route
            path="/pricingUsers"
            element={<IsUser children={<UsersPricingDetails />} />}
          />{" "}
          <Route
            path="/MTMOrderDetail"
            element={<IsUser children={<MTMOrderDetail />} />}
          />
          <Route
            path="/catalogueOrderDetail"
            element={<IsUser children={<CatalogueOrderDetail />} />}
          />
          <Route
            path="/fabricOrderDetail"
            element={<IsUser children={<FabricOrderdetail />} />}
          />
          <Route
            path="/expiredOrders"
            element={<IsUser children={<ExpiredOrders />} />}
          />
          <Route
            path="/userDashboard"
            element={<IsUser children={<UsersDashboard />} />}
          />
          <Route
            path="/cart"
            element={<IsUser children={<DefaultCatelogueOutlet />} />}
          >
            <Route
              path=""
              element={<IsUser children={<CustomerCart />} />}
            ></Route>
            <Route
              path="cartDelivery"
              element={<IsUser children={<NestedOutlet />} />}
            >
              <Route path="" element={<IsUser children={<CartDelivery />} />} />
            </Route>
          </Route>
          <Route
            path="/madetomeasure"
            element={<IsUser children={<PlaceMadeToMeasureOrder />} />}
          />
          {/*End Customer Route */}
          {/* <Route path="users">
              <Route path="" element={<IsAdmin children={<ListOfAllUsers />} />}></Route>
              <Route path="id" element={<IsAdmin children={<UserDetails />} />} ></Route>
              <Route path="new" element={<IsAdmin children={<AddNewUser />} />}></Route>
            </Route> */}
          <Route
            path="/shipping"
            element={<IsUser children={<Shipping />} />}
          />
          {/* End Customer Route */}
          <Route
            path="/users"
            element={<IsAdmin children={<ListOfAllUsers />} />}
          />
          <Route
            path="/users/new"
            element={<IsAdmin children={<UserTab/>} />}
          />
          <Route
            path="/users/:id"
            element={<IsAdmin children={<UserDetails />} />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </Envelope>
  );
}

export default App;
