import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import MenuNavigator from "../../../components/MenuNavigator";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Dialog } from "primereact/dialog";
import { useLocation, useNavigate } from "react-router-dom";
import { displayRazorpay } from "../PaymentIntegration";
import TakeCredit from "../TakeCredit";
import { createOrder, createOrderByAdmin } from "../../../api/api";
import { useReactToPrint } from "react-to-print";
const CatalogueQuotation = () => {
  const orderRef = useRef(null);
  const navigate = useNavigate();
  const paymentOption = JSON.parse(
    window.localStorage.getItem("userDetails")
  ).paymentOption;
  const { state } = useLocation();
  console.log("new", state);
  const userDetails = state.currentUser;
  const delivery = state.delivery;

  const expiredOrders = JSON.parse(
    window.localStorage.getItem("expiredOrders")
  );
  const toast = useRef(null);
  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Payment Recieved",
      detail:
        "Your Order is Placed. You will be redirected to the Catalogues Page",
    });
  };
  const move = () => {
    navigate("/userDashboard");
  };
  const paynowButton = (
    <Button
      style={{ width: "150px", margin: "10px", background: "#287F80" }}
      label="Pay Now"
      onClick={(e) => {
        e.preventDefault();
        if (expiredOrders.length > 0) {
          onClick("displayBasic");
        } else {
          createOrder({
            orderType: "paynow",
            name: "catalogue",
            catalogue: data.catalogueDetail._id,
            companyName: data.delivery.companyName,
            deliveryAddress: data.delivery.deliveryAddress,
            state: data.delivery.state,
            pincode: data.delivery.pincode,
            contactNumber: data.delivery.contactNumber,
            modeOfTransport: data.delivery.modeOfTransport,
            optionalTransport: data.delivery.optionalTransport
              ? data.delivery.optionalTransport
              : "",
            qty: data.qty,
            totalAmount: data.finalAmount,
          }).then((res) => {
            displayRazorpay({
              data: res,
              finalAmount: res.razorpayOrder.amount,
              success: showSuccess,
              error: error,
              navigate: move,
            }).catch(() => error("Couldn't able to Create Order"));
          });
        }
      }}
    ></Button>
  );

  const advanceButton = (
    <Button
      style={{ width: "150px", margin: "10px", background: "#287F80" }}
      label="Pay Advance"
      onClick={(e) => {
        e.preventDefault();
        if (expiredOrders.length > 0) {
          onClick("displayBasic");
        } else {
          createOrder({
            orderType: "advance",
            name: "catalogue",
            catalogue: data.catalogueDetail._id,
            companyName: data.delivery.companyName,
            deliveryAddress: data.delivery.deliveryAddress,
            state: data.delivery.state,
            pincode: data.delivery.pincode,
            contactNumber: data.delivery.contactNumber,
            modeOfTransport: data.delivery.modeOfTransport,
            optionalTransport: data.delivery.optionalTransport
              ? data.delivery.optionalTransport
              : "",
            qty: data.qty,
            totalAmount: data.finalAmount,
          }).then((res) => {
            displayRazorpay({
              data: res,
              finalAmount: res.razorpayOrder.amount,
              success: showSuccess,
              error: error,
              navigate: move,
            }).catch(() => error("Couldn't able to Create Order"));
          });
        }
      }}
    ></Button>
  );

  const creditButton = (
    <Button
      type="submit"
      style={{ width: "150px", margin: "10px", background: "#287F80" }}
      label="Take Credit"
      onClick={(e) => {
        e.preventDefault();
        if (expiredOrders.length > 0) {
          onClick("displayBasic");
        } else {
          createOrder({
            orderType: "credit",
            name: "catalogue",
            catalogue: data.catalogueDetail._id,
            companyName: data.delivery.companyName,
            deliveryAddress: data.delivery.deliveryAddress,
            state: data.delivery.state,
            pincode: data.delivery.pincode,
            contactNumber: data.delivery.contactNumber,
            modeOfTransport: data.delivery.modeOfTransport,
            optionalTransport: data.delivery.optionalTransport
              ? data.delivery.optionalTransport
              : "",
            qty: data.qty,
            totalAmount: data.finalAmount,
            paymentStatus: "pending",
            paymentId: "credit101",
          }).then((res) => {
            if (res.orderType === "credit") {
              navigate("/credit");
            }
          });
        }
      }}
    ></Button>
  );

  const paymentFailed = () => {
    toast.current.show({
      severity: "error",
      summary: "Payment Failed",
      detail: "Please try Again",
    });
  };

  const totalProductAmount = () => {
    let total = 0;
    const renderPrice =
      state?.currentUser?.userType === "retailer"
        ? state?.catalogueDetail?.retailerCataloguePrice
        : state?.currentUser?.userType === "architect"
        ? state?.catalogueDetail?.architectCataloguePrice
        : state?.catalogueDetail?.channelPartnerCataloguePrice;
    total = state.qty * renderPrice;
    const discount = state?.currentUser?.discountPercentage / 100;
    const discountedPrice = total - total * discount;
    return Math.round(discountedPrice);
  };
  const totalProductAmount2 = () => {
    let total = 0;
    const renderPrice =
      state?.currentUser?.userType === "retailer"
        ? state?.catalogueDetail?.retailerCataloguePrice
        : state?.currentUser?.userType === "architect"
        ? state?.catalogueDetail?.architectCataloguePrice
        : state?.catalogueDetail?.channelPartnerCataloguePrice;
    total = state.qty * renderPrice;
    const discount = state?.currentUser?.discountPercentage / 100;
    const discountedPrice = total - total * discount;
    return discountedPrice;
  };

  const totalProductAmountWithGST = () => {
    let productAmount = totalProductAmount2();
    return Math.round(0.12 * productAmount + productAmount);
  };

  console.log(totalProductAmountWithGST());
  const products = [
    {
      hsn: state.catalogueDetail.HSN,
      productcode: "Code5",
      name: state.catalogueDetail.name,
      quantity: state.qty,
      amount:
        state?.currentUser?.userType === "retailer"
          ? state?.catalogueDetail?.retailerCataloguePrice
          : state?.currentUser?.userType === "architect"
          ? state?.catalogueDetail?.architectCataloguePrice
          : state?.catalogueDetail?.channelPartnerCataloguePrice,
      discount: state.currentUser.discountPercentage,

      totalAmt: totalProductAmount(),
    },
  ];

  const headerTemplate = <span>Breakup</span>;
  const withGST = totalProductAmountWithGST();
  const currentAmtWithCreditAmt = parseInt(withGST) + 0;
  // const currentAmtWithCreditAmt = parseInt(withGST) + parseInt(userDetails.outStanding)
  const data = { ...state, finalAmount: currentAmtWithCreditAmt, showSuccess };

  let footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Total:" colSpan={6} />
        <Column footer={totalProductAmount} colSpan={1} />
      </Row>
      <Row>
        <Column footer="Total with GST (12%):" colSpan={6} />

        <Column footer={totalProductAmountWithGST} colSpan={1} />
      </Row>
      <Row>
        <Column footer="Total with Outstanding Amount:" colSpan={6} />

        <Column footer={currentAmtWithCreditAmt} colSpan={1} />
      </Row>
    </ColumnGroup>
  );

  const FabricTable = (
    <DataTable
      showGridlines
      value={products}
      responsiveLayout="scroll"
      header={headerTemplate}
      // footer={footerTemplate}
      footerColumnGroup={footerGroup}
    >
      <Column header="Sr. No." body={1} exportable></Column>
      <Column field="hsn" header="HSN Code"></Column>
      <Column field="name" header="Catalogue Name"></Column>

      <Column field="quantity" header="Quantity"></Column>

      <Column field="amount" header="Price"></Column>
      <Column field="discount" header="Discount(%)"></Column>

      <Column field="totalAmt" header="Total Amount"></Column>
    </DataTable>
  );
  const [displayBasic, setDisplayBasic] = useState(false);
  const error = (message) => {
    toast.current.show({
      severity: "error",
      summary: message,
      detail: "Please try Again",
    });
  };
  const [position, setPosition] = useState("center");

  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };
  const handlePrintQuotation = useReactToPrint({
    content: () => orderRef.current,
    onAfterPrint: () => {
      navigate("/catelogue/all");
    },
  });
  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => onHide(name)}
          className="p-button-text"
        />
        <Button
          label="Pay Now"
          icon="pi pi-check"
          onClick={() => {
            onHide(name);
            navigate("/expiredOrders");
          }}
          autoFocus
        />
      </div>
    );
  };

  return (
    <div>
      <form className="p-fluid m-5">
        <Divider align="center">
          <Toast ref={toast}></Toast>
          <h2 className="p-text-center">Quotation</h2>
        </Divider>
        <Dialog
          header="Payment Alert"
          visible={displayBasic}
          style={{ width: "50vw", borderRadius: "10px" }}
          footer={renderFooter("displayBasic")}
          onHide={() => onHide("displayBasic")}
        >
          <p>Please Clear Your OutStanding Amount to Make New Orders</p>
        </Dialog>
        <div ref={orderRef}>
          <div className="p-fluid">
            <Panel header="Order Details">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    width: "50%",
                    justifyContent: "center",
                  }}
                >
                  <div style={{}}>
                    <p>
                      <strong>Date:</strong> {new Date().toString()}
                    </p>
                    <p>
                      <strong>To, Company Name:</strong>{" "}
                      {userDetails.companyName}
                    </p>
                    <p>
                      <strong> Customer Name :</strong> {userDetails.name}
                    </p>
                    <p>
                      <strong> Contact Number:</strong>{" "}
                      {userDetails.mobileNumber}
                    </p>
                    <p>
                      <strong> Address: </strong> {userDetails.address1}
                    </p>
                    <p>
                      <strong> From: (Batavia Exim)</strong>
                    </p>
                    <h3>
                      <strong style={{ color: "red" }}>
                        {" "}
                        Current OutStanding :{" "}
                      </strong>
                      {userDetails.currentOutstanding}Rs
                    </h3>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "50%",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ width: "60%" }}>
                    <p style={{}}>
                      <h2>Delivery Address</h2>
                    </p>
                    <p>
                      <strong>Company Name: </strong> {delivery.companyName}
                    </p>
                    <p>
                      <strong>Address: </strong> {delivery.deliveryAddress}
                    </p>
                    <p>
                      <strong> State: </strong> {delivery.state}
                    </p>
                    <p>
                      <strong> Pincode: </strong> {delivery.pincode}
                    </p>
                    <p>
                      <strong>Contact: </strong>
                      {delivery.contactNumber}
                    </p>
                    <p style={{ display: "flex" }}>
                      <strong>Transport:&nbsp; </strong>
                      {delivery.modeOfTransport === "Any Other" ? (
                        <div> {delivery.optionalTransport}</div>
                      ) : (
                        <div> {delivery.modeOfTransport}</div>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </Panel>
            <div className="card"></div>
          </div>
          <div style={{ textAlign: "center" }}>{FabricTable}</div>
        </div>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          {window.localStorage.getItem("userType") === "sales" ||
          window.localStorage.getItem("userType") === "accounts" ? (
            <Button
              style={{ width: "300px", margin: "10px", background: "#287F80" }}
              label="Place Order Behalf of Customer"
              onClick={(e) => {
                e.preventDefault();
                if (expiredOrders.length > 0) {
                  onClick("displayBasic");
                } else {
                  createOrderByAdmin(
                    {
                      orderType: "paynow",
                      name: "catalogue",
                      catalogue: data.catalogueDetail._id,
                      companyName: data.delivery.companyName,
                      deliveryAddress: data.delivery.deliveryAddress,
                      state: data.delivery.state,
                      pincode: data.delivery.pincode,
                      contactNumber: data.delivery.contactNumber,
                      modeOfTransport: data.delivery.modeOfTransport,
                      optionalTransport: data.delivery.optionalTransport
                        ? data.delivery.optionalTransport
                        : "",
                      qty: data.qty,
                      totalAmount: data.finalAmount,
                    },
                    userDetails._id
                  )
                    .then((res) => {
                      console.log("res", res);
                      toast.current.show({
                        severity: "success",
                        summary: "Order Placed",
                        detail:
                          "Your Order is Placed. You will be redirected to the Catalogues Page",
                      });
                      setTimeout(() => {
                        handlePrintQuotation();
                      }, 3000);
                    })
                    .catch((err) => {
                      toast.current.show({
                        severity: "error",
                        summary: "failed to place order!",
                        detail: "Your Order couldn't be placed try again!",
                      });
                    });
                }
              }}
            ></Button>
          ) : (
            <div
              style={{
                justifyContent: "space-between",
                marginTop: "30px",
                marginBottom: "100px",
              }}
            >
              {paynowButton}
              {console.log(paymentOption)}
              {paymentOption === "advance" && advanceButton}
              {paymentOption === "credit" && creditButton()}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default CatalogueQuotation;
