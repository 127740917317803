import React, { useState, useEffect } from "react";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import { getCatalogueDetails } from "../../../api/api";
import { Fieldset } from "primereact/fieldset";
const fabData = [
  {
    img: "https://www.shutterstock.com/image-photo/mauled-greencolored-mixed-fabric-texture-260nw-2104508417.jpg",
    name: "Fiona-03",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4jfj8BQ9SZ6w01kMx_8-VzE86Y3U2zNDd6rpz_Xt4woMM92EKOjwAyjv5Fwi0IMExpzs&usqp=CAU",
    name: "Eliza-09",
  },
  {
    img: "https://www.shutterstock.com/image-photo/mauled-greencolored-mixed-fabric-texture-260nw-2104508417.jpg",
    name: "Fiona-03",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4jfj8BQ9SZ6w01kMx_8-VzE86Y3U2zNDd6rpz_Xt4woMM92EKOjwAyjv5Fwi0IMExpzs&usqp=CAU",
    name: "Eliza-09",
  },
  {
    img: "https://www.shutterstock.com/image-photo/mauled-greencolored-mixed-fabric-texture-260nw-2104508417.jpg",
    name: "Fiona-03",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4jfj8BQ9SZ6w01kMx_8-VzE86Y3U2zNDd6rpz_Xt4woMM92EKOjwAyjv5Fwi0IMExpzs&usqp=CAU",
    name: "Eliza-09",
  },
  {
    img: "https://www.shutterstock.com/image-photo/mauled-greencolored-mixed-fabric-texture-260nw-2104508417.jpg",
    name: "Fiona-03",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4jfj8BQ9SZ6w01kMx_8-VzE86Y3U2zNDd6rpz_Xt4woMM92EKOjwAyjv5Fwi0IMExpzs&usqp=CAU",
    name: "Eliza-09",
  },
  {
    img: "https://www.shutterstock.com/image-photo/mauled-greencolored-mixed-fabric-texture-260nw-2104508417.jpg",
    name: "Fiona-03",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4jfj8BQ9SZ6w01kMx_8-VzE86Y3U2zNDd6rpz_Xt4woMM92EKOjwAyjv5Fwi0IMExpzs&usqp=CAU",
    name: "Eliza-09",
  },
  {
    img: "https://www.shutterstock.com/image-photo/mauled-greencolored-mixed-fabric-texture-260nw-2104508417.jpg",
    name: "Fiona-03",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4jfj8BQ9SZ6w01kMx_8-VzE86Y3U2zNDd6rpz_Xt4woMM92EKOjwAyjv5Fwi0IMExpzs&usqp=CAU",
    name: "Eliza-09",
  },
  {
    img: "https://www.shutterstock.com/image-photo/mauled-greencolored-mixed-fabric-texture-260nw-2104508417.jpg",
    name: "Fiona-03",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4jfj8BQ9SZ6w01kMx_8-VzE86Y3U2zNDd6rpz_Xt4woMM92EKOjwAyjv5Fwi0IMExpzs&usqp=CAU",
    name: "Eliza-09",
  },
  {
    img: "https://www.shutterstock.com/image-photo/mauled-greencolored-mixed-fabric-texture-260nw-2104508417.jpg",
    name: "Fiona-03",
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4jfj8BQ9SZ6w01kMx_8-VzE86Y3U2zNDd6rpz_Xt4woMM92EKOjwAyjv5Fwi0IMExpzs&usqp=CAU",
    name: "Eliza-09",
  },
];
const RenderPrice = (currentUser, fabricDetails) => {
  if (currentUser) {
    if (currentUser.userType === "retailer") {
      return fabricDetails.retailerCataloguePrice;
    } else if (currentUser.userType === "channelPartner") {
      return fabricDetails.channelPartnerCataloguePrice;
    } else {
      return fabricDetails.architectCataloguePrice;
    }
  }
};

export default function CatalogueDetail() {
  const navigate = useNavigate();
  const currentUser = JSON.parse(window.localStorage.getItem("userDetails"));
  let location = useLocation();

  const [qty, setQty] = useState(1);
  const [catalogueDetail, setCatalogueDetail] = useState("");
  const [fabrics, setFAbrics] = useState([]);
  const renderedPrice = RenderPrice(currentUser, catalogueDetail);
  useEffect(() => {
    getCatalogueDetails(location.pathname.split("/")[2]).then((data) => {
      console.log("data", data);
      setFAbrics(data?.fabricAffiliation);
      setCatalogueDetail(data);
    });
  }, [location]);
  console.log("fabrics", fabrics);
  return (
    <div>
      <Fieldset
        legend="Catalogue Detail"
        style={{
          marginTop: "30px",
        }}
      >
        <div className="flex border-round  p-3 m-3">
          <div className="w-6 text-gray-900 font-bold p-3 flex align-items-center justify-content-center">
            <Image
              src={
                catalogueDetail.image
                  ? catalogueDetail.image
                  : "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=826&t=st=1661248188~exp=1661248788~hmac=64bb7d23be8dcef15ecfef304e7d5bfe293f29bbf5b48fd6cadb282e205f9ddb"
              }
              alt="Image"
              width="400rem"
            />
          </div>
          <div className="w-6  font-bold p-3 flex flex-column align-items-center justify-content-center surface-100">
            <div className="w-8 ">
              <div className="fabricTitle text-2xl text-white flex flex-column text-center align-items-center justify-content-center p-3 bg-primary ">
                <div>{catalogueDetail.name}</div>
                <div>
                  <strong
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "15px",
                    }}
                  >
                    Price: ₹{renderedPrice} / Per Metre
                  </strong>
                </div>
              </div>
              <div className="flex text-gray-800">
                <div className="m-2">
                  <div>
                    <p>
                      <strong>HSN :</strong>
                      {catalogueDetail.HSN}
                    </p>
                    <p>
                      <strong>Fabric Composition :</strong>{" "}
                      {catalogueDetail.fabricComposition &&
                        catalogueDetail.fabricComposition.join([","])}{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex justify-content-center">
                <strong className="flex justify-content-center align-items-center mx-2">
                  Quantity :
                </strong>
                <InputText
                  placeholder="Qty"
                  value={qty}
                  style={{ borderColor: "#287F80", width: "100px" }}
                  onChange={(e) => setQty(e.target.value)}
                />
              </div>

              <Button
                className="w-full bg-primary mt-3"
                onClick={() => {
                  navigate("Cdelivery", {
                    state: { catalogueDetail, currentUser, qty, renderedPrice },
                  });
                }}
                label="Buy Now"
              ></Button>
            </div>
          </div>
        </div>

        <div>
          <div className="descriptionContainer w-3"></div>
        </div>
        <div className=" flex flex-row flex-wrap justify-content-center">
          {fabrics?.map((item, index) => (
            <div className="m-5" key={index}>
              <Image src={item?.image} alt="Image" width="140rem" />
              <strong
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "15px",
                }}
              >
                {item?.name}
              </strong>
            </div>
          ))}
        </div>
      </Fieldset>
    </div>
  );
}
