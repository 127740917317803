import React, { useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

function ToBeRecievedChart({ amountRecieved, amountToBeRecieved }) {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const data = {
    labels: ["This Month", "Last Month"],
    datasets: [
      {
        label: "This Month",
        data: [parseInt(amountRecieved), parseInt(amountToBeRecieved)],
        backgroundColor: ["#FF6384", "#36A2EB"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div
      style={{
        boxShadow: "0px 0px 20px rgb(0,0,0,0.2)",
        borderRadius: "10px",
        margin: "10px",
      }}
      className="card justify-content-center w-6"
    >
      <div className="my-4 text-2xl text-center ">To Be Recieved</div>
      <Doughnut data={data} />
      <div className="m-2 flex flex-column align-items-center justify-content-center">
        <div>Amount Recieved : {amountRecieved}</div>
        <div>To Be Recieved : {amountToBeRecieved}</div>
      </div>
    </div>
  );
}

export default ToBeRecievedChart;
