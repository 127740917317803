import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Panel } from 'primereact/panel';
import { Divider } from 'primereact/divider';
import { Fieldset } from 'primereact/fieldset';
const OrderDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [order, setOrder] = useState({
    id: 'sfdsfs-fdsof32ds-sfdd',
    placedBy: 'fdsf@fd423s.com',
    status: 'In Progress',
    ticketAmount: '43322',
    placedOn: new Date().toDateString(),
    items: [
      {
        name: 'produect1',
        id: '4324eee32',
        quantity: '43e2',
        type: 'fdesf',
        details: '423e41',
        price: '21',
      },
      {
        name: 'produc4t2',
        id: '43243422',
        quantity: '442',
        type: 'fdd4ss',
        details: 'sdmfkd4s41',
        price: '323',
      },
    ],
  });
  //   const [orderId, setOrderId] = useState('orderIdfdsfd');
  // useEffect(() => {
  //     return () => {
  //       setOrderId(location.pathname.split('/')[2]);
  //     };
  //   }, [location.pathname]);

  return (
    <div>
      <Divider align="center">
        <h3 className="p-text-center">Order Details : {order.id}</h3>
      </Divider>

      <Panel
        header={`Order (ID): ${order.id} placed on ${order.placedOn} by ${order.placedBy}`}
      >
        <div className="card">
          <h5>{`₹${order.ticketAmount} - ${order.status}`}</h5>
          {order.items.map((item) => (
            <Fieldset legend={item.name} className="p-mt-2">
              <p>
                <b>Price</b> : {item.price}
              </p>
              <p>
                <b>Quantity</b> : {item.quantity}
              </p>
              <p>
                <b>Type</b> : {item.type}
              </p>
              <p>
                <b>Details</b> : {item.details}
              </p>
            </Fieldset>
          ))}
        </div>
      </Panel>
    </div>
  );
};

export default OrderDetails;
