import React, { useState, useEffect } from "react";
import MenuNavigator from "../../../components/MenuNavigator";
import SaleCard from "./components/SaleCard";
import { getAdminDashboardData } from "../../../api/api";
import CreditThisMonthChart from "./components/CreditThisMonthChart";
import ToBeRecievedChart from "./components/ToBeRecievedChart";
import SalesBarChart from "./components/SalesBarChart";
import convertToINR from "./components/convertToINR";
import StatisticsBar from "./components/StatisticsBar";
import RecentOrders from "./components/RecentOrders";

function AdminDashboard() {
  const [dashboardData, setDashboardData] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [lebel,setLebel] = useState([])
  const getMonths = () => {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var today = new Date(); /*from ww w .jav a  2s.  co m*/
    var d;
    var month;
    const months = [];
    for (var i = 5; i > 0; i -= 1) {
      d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      month = monthNames[d.getMonth()];
      months.push(month);
    }
    d = new Date(today.getFullYear(), today.getMonth(), 1);
    month = monthNames[d.getMonth()];
    months.push(month);
    setLebel(months);
  };
  useEffect(() => {
    getMonths()
    getAdminDashboardData().then((res) => setDashboardData(res));
  }, []);
  console.log(dashboardData);
  return (
    <div>
      <MenuNavigator />

      <div className="flex border-round  p-3 m-3">
        <div className="w-5 flex flex-wrap align-items-center justify-content-center">
          <SaleCard
            color="#2575fc"
            text="Total Users"
            data={convertToINR(dashboardData.userCount)}
          />
          <SaleCard
            color="#f43b47"
            text="Fabrics Sales"
            data={convertToINR(dashboardData.totalFabricSales)}
          />
          <SaleCard
            color="#f9d423"
            text="Catalogue Sales"
            data={convertToINR(dashboardData.totalCatalogueSales)}
          />
          <SaleCard
            color="#517fa4"
            text="MTM Sales"
            data={convertToINR(dashboardData.totalMtmSales)}
          />
        </div>
        <div className="w-7   flex align-items-center justify-content-center">
          <CreditThisMonthChart
            showChart={showChart}
            dashboardData={dashboardData}
            creditThisMonth={convertToINR(
              dashboardData.creditThisMonth?.toFixed(2)
            )}
            creditlastMonth={convertToINR(
              dashboardData.creditlastMonth?.toFixed(2)
            )}
          />
          <ToBeRecievedChart
            data={dashboardData}
            amountRecieved={convertToINR(
              dashboardData.amountRecieved?.toFixed(2)
            )}
            amountToBeRecieved={convertToINR(
              dashboardData.amountToBeRecieved?.toFixed(2)
            )}
          />
        </div>
      </div>
      <StatisticsBar data={dashboardData} />
      <SalesBarChart data={dashboardData} labels={lebel} />
      <div className="my-4 text-2xl font-bold ">Recent Orders</div>
      <RecentOrders
        orders={dashboardData.allOrders && dashboardData.allOrders.slice(0, 5)}
      />
    </div>
  );
}

export default AdminDashboard;
