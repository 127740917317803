import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDownloadExcel } from "react-export-table-to-excel";
import MenuNavigator from "../../components/MenuNavigator";
import { getAllFabrics, getAllCatalogues } from "../../api/api";
import { Paginator } from "primereact/paginator";

const AdminPricingDetails = () => {
  const [fabricList, setFabricList] = useState([]);
  const [catalogueList, setCatalogueList] = useState([]);
  const [searchFabric, setSearchFabric] = useState("");
  const [searchCatalogue, setSearchCatalogue] = useState("");
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [basicFirst2, setBasicFirst2] = useState(0);
  const [basicRows2, setBasicRows2] = useState(10);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [count2, setCount2] = useState(0);

  const fabricsDownloadRef = useRef();
  const catalogueDownloadRef = useRef();
  const fabricTableRef = useRef(null);
  const catalogueTableRef = useRef(null);

  const handleFabricPrint = useReactToPrint({
    content: () => fabricTableRef.current,
  });
  const handleCataloguePrint = useReactToPrint({
    content: () => catalogueTableRef.current,
  });
  const handleFabricExcelPrint = useDownloadExcel({
    currentTableRef: fabricTableRef.current,
    filename: "Batavia Fabrics",
    sheet: "Fabrics",
  });
  const handleCatalogueExcelPrint = useDownloadExcel({
    currentTableRef: catalogueTableRef.current,
    filename: "Batavia Catalogues",
    sheet: "Catalogues",
  });
  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
    setCurrentPage(event.page + 1);
  };
  const onBasicPageChange2 = (event) => {
    setBasicFirst2(event.first);
    setBasicRows2(event.rows);
    setCurrentPage2(event.page + 1);
  };
  const imageBodyTemplate = (rowData) => {
    return (
      <img
        style={{ width: "70px", height: "70px" }}
        src={
          rowData.image
            ? rowData.image
            : "https://media.istockphoto.com/vectors/image-preview-icon-picture-placeholder-for-website-or-uiux-design-vector-id1222357475?k=20&m=1222357475&s=170667a&w=0&h=YGycIDbBRAWkZaSvdyUFvotdGfnKhkutJhMOZtIoUKY="
        }
        alt=""
        width="75px"
        className="product-image"
      />
    );
  };
  const getFab = async () => {
    await getAllFabrics(currentPage2,searchFabric).then((data) => {
      console.log("fab", data.allFabrics);
      setFabricList(data?.allFabrics);
      setCount2(data?.fabricCount)
    });
  };
  const getCat = async () => {
    getAllCatalogues(currentPage,searchCatalogue).then((data) => {
      console.log("fab", data.allCatalogues);
      setCatalogueList(data.allCatalogues);
      setCount(data?.catalogueCount)
    });
  };
  useEffect(() => {
    getCat();
  }, [currentPage,searchCatalogue]);
  useEffect(() => {
    getFab();
  }, [currentPage2,searchFabric]);
   // eslint-disable-line react-hooks/exhaustive-deps
  // console.log(catalogueList, fabricList);
  const imageBodyTemplate2 = (rowData) => {
    return (
      <img
        style={{ width: "70px", height: "70px" }}
        src={
          rowData.image
            ? rowData.image
            : "https://media.istockphoto.com/vectors/image-preview-icon-picture-placeholder-for-website-or-uiux-design-vector-id1222357475?k=20&m=1222357475&s=170667a&w=0&h=YGycIDbBRAWkZaSvdyUFvotdGfnKhkutJhMOZtIoUKY="
        }
        alt=""
        className="product-image"
      />
    );
  };

  const checkRollAndCutPrices = (val) => {
    if (!val.retailerCutlengthPrice) val.retailerCutlengthPrice = "N/A";
    if (!val.retailerRolllengthPrice) val.retailerRolllengthPrice = "N/A";
    if (!val.channelPartnerCutlengthPrice)
      val.channelPartnerCutlengthPrice = "N/A";
    if (!val.channelPartnerRolllengthPrice)
      val.channelPartnerRolllengthPrice = "N/A";
    if (!val.architectCutlengthPrice) val.architectCutlengthPrice = "N/A";
    if (!val.architectRolllengthPrice) val.architectRolllengthPrice = "N/A";

    return val;
  };

  const downloadMenuItems = [
    {
      label: "Download as PDF",
      command: handleFabricPrint,
    },
    {
      label: "Download as Excel",
      command: handleFabricExcelPrint.onDownload,
    },
  ];
  const downloadCatalogueMenuItems = [
    {
      label: "Download as PDF",
      command: handleCataloguePrint,
    },
    {
      label: "Download as Excel",
      command: handleCatalogueExcelPrint.onDownload,
    },
  ];

  return (
    <div>
      <MenuNavigator />
      <Divider align="center">
        <h2 className="p-text-center">Fabric Pricing</h2>
      </Divider>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <SplitButton
          style={{ backgroundColor: "#287F80" }}
          label="Download"
          className="p-block  p-mb-2"
          model={downloadMenuItems}
        />
        <div className="col-12" style={{ width: "30%" }}>
          <div className="p-inputgroup">
            <InputText
              value={searchFabric}
              onChange={(e) => setSearchFabric(e.target.value)}
              placeholder="Search By Fabric Or Collection"
            />
            <Button style={{ background: "#287F80" }} label="Search" />
          </div>
        </div>
      </div>
      <div className="card">
        <DataTable
          value={fabricList.filter((val) => {
            if (searchFabric === "") {
              return checkRollAndCutPrices(val);
            } else if (
              val.name.toLowerCase().includes(searchFabric.toLowerCase())
            ) {
              return checkRollAndCutPrices(val);
            } else if (
              val.color.toLowerCase().includes(searchFabric.toLowerCase())
            ) {
              return checkRollAndCutPrices(val);
            } else if (
              val.collectionName
                .toLowerCase()
                .includes(searchFabric.toLowerCase())
            ) {
              return checkRollAndCutPrices(val);
            }
          })}
          ref={fabricsDownloadRef}
          responsiveLayout="scroll"
          sortable
          filterDisplay
          stripedRows
          showGridlines
          rowHover
          // onRowClick={(event) => {
          //   console.log(event);
          //   navigate('/fabrics/324324324234324');
          // }}
        >
          <Column field="name" header="Fabric"></Column>
          <Column
            field="image"
            header="Image"
            body={imageBodyTemplate}
          ></Column>
          {/* <Column body={<img src={""} alt='fabric' />} header="image"></Column> */}
          <Column field="collectionName" header="Collection Name"></Column>

          <Column field="color" header="Color"></Column>
          <Column
            field="retailerCutLengthPrice"
            header="Retailer Cut-Length Price"
          ></Column>
          <Column
            field="retailerRolllengthPrice"
            header="Retailer Roll-Length Price"
          ></Column>
          <Column
            field="channelPartnerCutLengthPrice"
            header="Channel-Partner Cut-Length Price"
          ></Column>
          <Column
            field="channelPartnerRolllengthPrice"
            header="Channel-Partner Roll-Length Price"
          ></Column>
          <Column
            field="architectCutLengthPrice"
            header="Architect Cut-Length Price"
          ></Column>
          <Column
            field="architectRolllengthPrice"
            header="Architect Roll-Length Price"
          ></Column>
          <Column field="fabricType" header="Fabric Type"></Column>
        </DataTable>
        <Paginator
            first={basicFirst2}
            rows={basicRows2}
            totalRecords={count2}
            onPageChange={onBasicPageChange2}
          ></Paginator>
      </div>

      {/* Table to print Excel Format Data */}
      <div style={{ display: "none" }}>
        <table ref={fabricTableRef} border="1">
          <thead>
            <tr>
              <th>Fabric</th>
              <th>Image</th>
              <th>Collection Name</th>
              <th>Color</th>
              <th>Retailer Cut-Length Price</th>
              <th>Retailer Roll-Length Price</th>
              <th>Channel Partner Cut-Length Price</th>
              <th>Channel Partner Roll-Length Price</th>
              <th>Architect Cut-Length Price</th>
              <th>Architect Roll-Length Price</th>
              <th>Fabric Type</th>
            </tr>
          </thead>
          <tbody>
            {fabricList
              .filter((val) => {
                if (searchFabric === "") {
                  return checkRollAndCutPrices(val);
                } else if (
                  val.name.toLowerCase().includes(searchFabric.toLowerCase())
                ) {
                  return checkRollAndCutPrices(val);
                } else if (
                  val.color.toLowerCase().includes(searchFabric.toLowerCase())
                ) {
                  return checkRollAndCutPrices(val);
                } else if (
                  val.collectionName
                    .toLowerCase()
                    .includes(searchFabric.toLowerCase())
                ) {
                  return checkRollAndCutPrices(val);
                }
              })
              .map((val) => (
                <tr>
                  <td>{val.name}</td>
                  <td>{val.image}</td>
                  <td>{val.collectionName}</td>
                  <td>{val.color}</td>
                  <td>{val.retailerCutlengthPrice}</td>
                  <td>{val.retailerRolllengthPrice}</td>
                  <td>{val.channelPartnerCutlengthPrice}</td>
                  <td>{val.channelPartnerRolllengthPrice}</td>
                  <td>{val.architectCutlengthPrice}</td>
                  <td>{val.architectRolllengthPrice}</td>
                  <td>{val.fabricType}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* Table For Fabric Excel Ends */}

      {/* </div> */}
      <Divider align="center">
        <h2 className="p-text-center">Catalogues Pricing</h2>
      </Divider>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <SplitButton
          style={{ background: "#287F80" }}
          label="Download"
          className="p-block  p-mb-2"
          model={downloadCatalogueMenuItems}
        />
        <div className="col-12" style={{ width: "30%" }}>
          <div className="p-inputgroup">
            <InputText
              value={searchCatalogue}
              onChange={(e) => setSearchCatalogue(e.target.value)}
              placeholder="Search Catalogue"
            />
            <Button style={{ background: "#287F80" }} label="Search" />
          </div>
        </div>
      </div>

      <div className="card">
        <DataTable
          responsiveLayout="scroll"
          sortable
          filterDisplay
          stripedRows
          showGridlines
          rowHover
          value={catalogueList.filter((val) => {
            if (searchCatalogue === "") {
              return checkRollAndCutPrices(val);
            } else if (
              val.name.toLowerCase().includes(searchCatalogue.toLowerCase())
            ) {
              return checkRollAndCutPrices(val);
            }
          })}
          ref={catalogueDownloadRef}
        >
          <Column field="name" header="Name"></Column>
          <Column
            field="image"
            header="Image"
            body={imageBodyTemplate2}
          ></Column>

          <Column
            field="retailerCataloguePrice"
            header="Retailer Cut-Length Price"
          ></Column>
          <Column
            field="retailerRolllengthPrice"
            header="Retailer Roll-Length Price"
          ></Column>
          <Column
            field="channelPartnerCataloguePrice"
            header="Channel-Partner Cut-Length Price"
          ></Column>
          <Column
            field="channelPartnerRolllengthPrice"
            header="Channel-Partner Roll-Length Price"
          ></Column>
          <Column
            field="architectCataloguePrice"
            header="Architect Cut-Length Price"
          ></Column>
          <Column
            field="architectRolllengthPrice"
            header="Architect Roll-Length Price"
          ></Column>
        </DataTable>
        <Paginator
            first={basicFirst}
            rows={basicRows}
            totalRecords={count}
            onPageChange={onBasicPageChange}
          ></Paginator>
      </div>

      {/* Table to print Excel Format Data */}
      <div style={{ display: "none" }}>
        <table ref={catalogueTableRef} border="1">
          <thead>
            <tr>
              <th>Name</th>
              <th>Image</th>
              <th>Retailer Cut-Length Price</th>
              <th>Retailer Roll-Length Price</th>
              <th>Channel Partner Cut-Length Price</th>
              <th>Channel Partner Roll-Length Price</th>
              <th>Architect Cut-Length Price</th>
              <th>Architect Roll-Length Price</th>
            </tr>
          </thead>
          <tbody>
            {catalogueList
              .filter((val) => {
                if (searchCatalogue === "") {
                  return checkRollAndCutPrices(val);
                } else if (
                  val.name.toLowerCase().includes(searchCatalogue.toLowerCase())
                ) {
                  return checkRollAndCutPrices(val);
                }
              })
              .map((val) => (
                <tr>
                  <td>{val.name}</td>
                  <td>{val.image}</td>
                  <td>{val.retailerCutlengthPrice}</td>
                  <td>{val.retailerRolllengthPrice}</td>
                  <td>{val.channelPartnerCutlengthPrice}</td>
                  <td>{val.channelPartnerRolllengthPrice}</td>
                  <td>{val.architectCutlengthPrice}</td>
                  <td>{val.architectRolllengthPrice}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* Table For Catalogue Excel Ends */}
    </div>
  );
};

export default AdminPricingDetails;
