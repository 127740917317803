import React, { useEffect, useState, useContext } from "react";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import { useLocation, useNavigate } from "react-router-dom";
import { getFabricDetails } from "../../../api/api";
import placeholder from "../../../assets/placeholder.png";

export const RenderPrice = (currentUser, fabricDetails) => {
  if (currentUser) {
    if (currentUser.userType === "retailer") {
      return fabricDetails.retailerCutLengthPrice;
    } else if (currentUser.userType === "channelPartner") {
      return fabricDetails.channelPartnerCutLengthPrice;
    } else {
      return fabricDetails.architectCutLengthPrice;
    }
  }
};

export const FabricDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [metres, setMetres] = useState(0);
  const [qty, setQty] = useState(1);
  const currentUser = JSON.parse(window.localStorage.getItem("userDetails"));
  const [fabricDetails, setFabricDetails] = useState({});
  const renderedPrice = RenderPrice(currentUser, fabricDetails);
  function dropDownOptions() {
    return <div></div>;
  }

  useEffect(() => {
    getFabricDetails(location.pathname.split("/")[2]).then((data) => {
      setFabricDetails(data);
      console.log("data", data);
    });
  }, [location]);

  return (
    <div>
      <Fieldset
        legend="Fabric Details"
        style={{
          marginTop: "30px",
        }}
      >
        <div
          className="Container"
          style={{
            display: "flex",
            flexDirection: "colunmn",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {console.log(fabricDetails.image)}
          <div className="imageContainer">
            <div className="">
              <Image
                src={
                  fabricDetails.image
                    ? fabricDetails.image
                    : "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=826&t=st=1661248188~exp=1661248788~hmac=64bb7d23be8dcef15ecfef304e7d5bfe293f29bbf5b48fd6cadb282e205f9ddb"
                }
                width="430rem"
              />
            </div>
          </div>

          <div className="descriptionContainer" style={{ marginLeft: "10%" }}>
            <div
              className="fabricTitle"
              style={{
                background: "#287F80",
                color: "white",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "80px",
              }}
            >
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "30px",
                }}
              >
                {" "}
                {fabricDetails.name}
              </div>
              <div>
                <strong
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "20px",
                  }}
                >
                  Price: {renderedPrice}Rs / Per Metre
                </strong>
              </div>
            </div>

            <div className="" style={{ display: "flex", flexDirection: "row" }}>
              <div className="" style={{ margin: "10px" }}>
                <div>
                  <p>
                    <strong>Collection :</strong> {fabricDetails.collectionName}{" "}
                  </p>
                  <p>
                    <strong>HSN :</strong> {fabricDetails.HSN}
                  </p>
                  <p>
                    <strong>Colour :</strong> {fabricDetails.color}{" "}
                  </p>
                  <p>
                    <strong>Composition :</strong> {fabricDetails.composition}{" "}
                  </p>
                  <p>
                    <strong>Quality :</strong> {fabricDetails.quality}{" "}
                  </p>
                  <p>
                    <strong>Fabric Width (cm) :</strong>{" "}
                    {fabricDetails.fabricWidth}{" "}
                  </p>
                </div>
              </div>

              <div className="" style={{ margin: "10px" }}>
                <div>
                  <p>
                    <strong>Fabric Type :</strong> {fabricDetails.fabricType}{" "}
                  </p>
                  <p>
                    <strong>Weight (GLM) :</strong> {fabricDetails.weight}{" "}
                  </p>
                  <p>
                    <strong>Pattern Repeat (cm) :</strong>{" "}
                    {fabricDetails.patternRepeat}{" "}
                  </p>

                  <p>
                    <strong>Fabric Care :</strong> {fabricDetails.fabricCare}
                  </p>
                  <p>
                    <strong>Usage :</strong> {fabricDetails.usage}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <strong
                  style={{
                    height: "38px",
                    display: "flex",
                    flexDirection: "colunmn",
                    alignItems: "center",
                    fontSize: "20px",
                    marginRight: "15px",
                    justifyContent: "center",
                  }}
                >
                  Metres :
                </strong>
                <InputText
                  placeholder="Qty"
                  value={qty}
                  style={{ borderColor: "#287F80", width: "100px" }}
                  onChange={(e) => setQty(e.target.value)}
                />
              </div>
              <Button
                style={{
                  backgroundColor: "#287F80",
                  marginTop: "10px",
                  width: "100%",
                  textAlign: "center",
                }}
                onClick={() => {
                  navigate("fdelivery", {
                    state: { fabricDetails, currentUser, qty, renderedPrice },
                  });
                }}
                label="Buy Now"
              ></Button>
            </div>
          </div>
        </div>

        {/* </Card> */}
      </Fieldset>
    </div>
  );
};
