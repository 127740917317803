import React from "react";
import convertToINR from "./convertToINR";

function StatisticsBar({ data }) {
  console.log(data)
  return (
    <div>
      <div className="my-4 text-2xl font-bold ">Statistics</div>
      <div className="flex flex-wrap">
        <div
          style={{
            boxShadow: "0px 0px 20px rgb(0,0,0,0.2)",
            borderRadius: "10px",
            margin: "10px",
          }}
          className="w-2 h-5rem flex flex-column align-items-center justify-content-center"
        >
          <div>Fabrics Sales</div>
          <div className="font-bold">
            {convertToINR(data.totalFabricSales)}{" "}
          </div>
        </div>
        <div
          style={{
            boxShadow: "0px 0px 20px rgb(0,0,0,0.2)",
            borderRadius: "10px",
            margin: "10px",
          }}
          className="w-2 h-5rem flex flex-column align-items-center justify-content-center"
        >
          <div>Catalogue Sales</div>
          <div className="font-bold">
            {convertToINR(data.totalCatalogueSales)}{" "}
          </div>
        </div>
        <div
          style={{
            boxShadow: "0px 0px 20px rgb(0,0,0,0.2)",
            borderRadius: "10px",
            margin: "10px",
          }}
          className="w-2 h-5rem flex flex-column align-items-center justify-content-center"
        >
          <div>MTM Sales</div>
          <div className="font-bold">{convertToINR(data.totalMtmSales)} </div>
        </div>
      </div>
    </div>
  );
}

export default StatisticsBar;
