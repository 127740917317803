import React from "react";
import { Outlet } from "react-router-dom";
import MenuNavigator from "../../../components/MenuNavigator";

function DefaultFabricOutlet() {
  return (
    <div>
      <MenuNavigator />
      <Outlet />
    </div>
  );
}
function NestedOutlet() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

export { DefaultFabricOutlet, NestedOutlet };
