import React, { useState, useRef } from "react";
import MenuNavigator from "../../../components/MenuNavigator";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { useLocation, useNavigate } from "react-router-dom";
import { displayRazorpay } from "../../customer/PaymentIntegration";
import { useReactToPrint } from "react-to-print";
import { createOrderByAdmin } from "../../../api/api";

function MTMAdminQuotation() {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("state", state);
  const toast = useRef(null);

  const printQuotationRef = useRef();
  const handlePrintQuotation = useReactToPrint({
    content: () => printQuotationRef.current,
    onAfterPrint: () => {
      navigate("/adminMTMO");
    },
  });
  const makeOrder = async () => {
    createOrderByAdmin(
      {
        orderType: "paynow",
        name: "madeToMeasure",
        companyName: state.delivery.companyName,
        deliveryAddress: state.delivery.deliveryAddress,
        state: state.delivery.state,
        pincode: state.delivery.pincode,
        contactNumber: state.delivery.contactNumber,
        modeOfTransport: state.delivery.modeOfTransport,
        optionalTransport: state.delivery.optionalTransport || "",
        qty: "1",
        subOrders: state.stored.map((obj) => ({
          roomType: obj.roomType,
          window: obj.window,
          catalogue: obj.catalogue,
          fabric: obj.fabric,
          accessory: obj.accessory,
          remark: obj.remark,
          stitch: obj.stitch,
          lining: obj.lining,
          type: obj.type,
          fullness: obj.fullness,
          measurement: obj.measurement,
          height: obj.height,
          width: obj.width,
          installationType: obj.installationType,
        })),
      },
      state?.currentUser?._id
    )
      .then((res) => {
        console.log("res", res);
        toast.current.show({
          severity: "success",
          summary: "Order Placed",
          detail:
            "Your Order is Placed. You will be redirected to the Made to Measure Page",
        });
        setTimeout(() => {
          handlePrintQuotation();
        }, 3000);
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "failed to place order!",
          detail: "Your Order couldn't be placed try again!",
        });
      });
  };
  const userDetails = state.currentUser;
  const delivery = state.delivery;

  // const totalProductAmount = () => {
  //     let total = 0;
  //     total = state.qty * state.renderedPrice;
  //     const discount = 12 / 100;
  //     const discountedPrice = total - total * discount;
  //     return Math.round(discountedPrice);
  // };

  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Payment Recieved",
      detail:
        "Your Order is Placed. You will be redirected to the Made to Measure Page",
    });
  };

  const totalProductAmountWithGST = () => {
    // let productAmount = parseInt(state.totalCost);
    let productAmount = Number(state.totalCost);
    return Math.round(0.12 * productAmount + productAmount);
  };

  const products = [...state.stored];
  console.log("products", products);
  const withGST = totalProductAmountWithGST();

  const headerTemplate = <span>Breakup</span>;

  let footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Total:" colSpan={12} />
        <Column footer={state.totalCost} />
      </Row>
      <Row>
        <Column footer="Total with GST (12%):" colSpan={12} />

        <Column footer={totalProductAmountWithGST} />
      </Row>
    </ColumnGroup>
  );

  const FabricTable = (
    <DataTable
      colSpan={13}
      showGridlines
      value={products}
      responsiveLayout="scroll"
      header={headerTemplate}
      // footer={footerTemplate}
      footerColumnGroup={footerGroup}
    >
      <Column header="Sr. No." body={1} exportable></Column>
      <Column field="roomType" header="Room Type"></Column>
      <Column field="window" header="Window"></Column>
      <Column field="fabric" header="Fabric"></Column>
      <Column field="height" header="Height"></Column>
      <Column field="width" header="Width"></Column>
      <Column field="panelHeight" header="Panel Height"></Column>
      <Column field="perPanelQuantity" header="Per Panel Quantity"></Column>
      <Column field="noOFPanel" header="No of Panels"></Column>
      <Column field="quantity" header="Quantity"></Column>
      <Column field="price" header="Price"></Column>
      <Column field="discount" header="Discount(%)"></Column>

      <Column field="cost" header="Total Amount"></Column>
    </DataTable>
  );

  return (
    <div>
      <MenuNavigator />
      <Divider align="center">
        <Toast ref={toast}></Toast>
        <h2 className="p-text-center">Quotation</h2>
      </Divider>

      <div className="p-fluid">
        <Panel header="Order Details">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                marginTop: "20px",
                width: "50%",
                justifyContent: "center",
              }}
            >
              <div style={{}}>
                <p>
                  <strong>Date:</strong> {new Date().toString()}
                </p>
                <p>
                  <strong>To, Company Name:</strong> {userDetails.companyName}
                </p>
                <p>
                  <strong> Customer Name :</strong> {userDetails.name}
                </p>
                <p>
                  <strong> Contact Number:</strong> {userDetails.mobileNumber}
                </p>
                <p>
                  <strong> Address: </strong> {userDetails.address1}
                </p>
                <p>
                  <strong> From: (Batavia Exim)</strong>
                </p>
                <h3>
                  <strong style={{ color: "red" }}>
                    {" "}
                    Current OutStanding :{" "}
                  </strong>
                  {userDetails.currentOutstanding}Rs
                </h3>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "50%",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "60%" }}>
                <p style={{}}>
                  <h2>Delivery Address</h2>
                </p>
                <p>
                  <strong>Company Name: </strong> {delivery.companyName}
                </p>
                <p>
                  <strong>Address: </strong> {delivery.deliveryAddress}
                </p>
                <p>
                  <strong> State: </strong> {delivery.state}
                </p>
                <p>
                  <strong> Pincode: </strong> {delivery.pincode}
                </p>
                <p>
                  <strong>Contact: </strong>
                  {delivery.contactNumber}
                </p>
                <p style={{ display: "flex" }}>
                  <strong>Transport:&nbsp; </strong>
                  {delivery.modeOfTransport === "Any Other" ? (
                    <p> {delivery.optionalTransport}</p>
                  ) : (
                    <p> {delivery.modeOfTransport}</p>
                  )}
                </p>
              </div>
            </div>
          </div>
        </Panel>
        <div className="card"></div>
      </div>
      <div style={{ textAlign: "center" }}>
        {FabricTable}
        <div
          style={{
            justifyContent: "space-between",
            marginTop: "30px",
            marginBottom: "100px",
          }}
        >
          <Button
            style={{ width: "300px", margin: "10px", background: "#287F80" }}
            label="Place Order Behalf of Customer"
            onClick={
              // e.preventDefault()
              // displayRazorpay({ ...state, finalAmount: withGST,showSuccess })
              makeOrder
            }
          ></Button>
          {/* <Button
                        type="submit"
                        style={{ width: "150px", margin: "10px", background: "#287F80" }}
                        label="Take Credit"
                        onClick={() => {
                            navigate("/credit")
                        }}
                    ></Button> */}
        </div>
      </div>

      <div style={{ display: "none" }}>
        {/* Added to format pdf in better way */}
        <div style={{ width: "1325px" }} ref={printQuotationRef}>
          <Panel header="Order Details">
            <div style={{ display: "flex", marginLeft: "50px" }}>
              <div style={{ paddingBottom: "20px", width: "50%" }}>
                <p>
                  <strong>Date:</strong> {new Date().toString()}
                </p>
                <p>
                  <strong>To, Company Name:</strong> {userDetails.companyName}
                </p>
                <p>
                  <strong> Customer Name :</strong> {userDetails.name}
                </p>
                <p>
                  <strong> Contact Number:</strong> {userDetails.mobileNumber}
                </p>
                <p>
                  <strong> Address: </strong> {userDetails.address1}
                </p>
                <p>
                  <strong> From: (Batavia Exim)</strong>
                </p>
                <h3>
                  <strong style={{ color: "red" }}>
                    {" "}
                    Current OutStanding :{" "}
                  </strong>
                  {userDetails.currentOutstanding}Rs
                </h3>
              </div>
              <div style={{ paddingBottom: "20px", width: "50%" }}>
                <p>
                  <h2>Delivery Address</h2>
                </p>
                <p>
                  <strong>Company Name: </strong> {delivery.companyName}
                </p>
                <p>
                  <strong>Address: </strong> {delivery.deliveryAddress}
                </p>
                <p>
                  <strong> State: </strong> {delivery.state}
                </p>
                <p>
                  <strong> Pincode: </strong> {delivery.pincode}
                </p>
                <p>
                  <strong>Contact: </strong>
                  {delivery.contactNumber}
                </p>
                <p style={{ display: "flex" }}>
                  <strong>Transport:&nbsp; </strong>
                  {delivery.modeOfTransport === "Any Other" ? (
                    <div> {delivery.optionalTransport}</div>
                  ) : (
                    <div> {delivery.modeOfTransport}</div>
                  )}
                </p>
              </div>
            </div>
          </Panel>

          {FabricTable}
        </div>
      </div>
    </div>
  );
}

export default MTMAdminQuotation;
