import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import MenuNavigator from "../../../components/MenuNavigator";
import { getMyOrders } from "../../../api/api";
import { Button } from "primereact/button";
import { displayRazorpay } from "./ExpiredOrdersPayment";

function ExpiredOrders() {
  const expiredOrders = JSON.parse(
    window.localStorage.getItem("expiredOrders")
  );
  console.log(expiredOrders);
  const Ids = expiredOrders.map((obj) => obj._id);
  const OrderIdsPayLoad = {
    orders: Ids,
  };

  const productType = (rowData) => {
    if ("fabric" in rowData) {
      return "Fabric";
    } else if ("catalogue" in rowData) {
      return "Catalogue";
    } else {
      return "MTM";
    }
  };
  const productTemplate = (rowData) => {
    if (productType(rowData) === "Fabric") {
      return (
        <div style={{ backgroundColor: "#ECCFFF" }} className="p-1 w-full">
          <span
            style={{
              color: "#694382",
              textTransform: "uppercase",
              padding: ".25em 1rem",
              borderRadius: "3px",
              fontWeight: "700",
              letterSpacing: ".3px",
            }}
          >
            {productType(rowData)}
          </span>
        </div>
      );
    } else if (productType(rowData) === "Catalogue") {
      return (
        <div style={{ backgroundColor: "#FFD8B2" }} className="p-1 w-full">
          <span
            style={{
              color: "#805B36",
              textTransform: "uppercase",
              padding: ".25em 1rem",
              borderRadius: "3px",
              fontWeight: "700",
              letterSpacing: ".3px",
            }}
          >
            {productType(rowData)}
          </span>
        </div>
      );
    } else {
      return (
        <div className="p-1 w-full" style={{ backgroundColor: "#B3E5FC" }}>
          <span
            style={{
              color: "#23547B",
              textTransform: "uppercase",
              padding: ".25em 1rem",
              borderRadius: "3px",
              fontWeight: "700",
              letterSpacing: ".3px",
            }}
          >
            {productType(rowData)}
          </span>
        </div>
      );
    }
  };
  const totalAmmountTemplete = (rowData) => {
    return Number(rowData?.totalAmount).toFixed(2);
  };
  const fullfilledTemplate = (rowData) => {
    if (rowData.trackingId) {
      return (
        <span
          style={{
            backgroundColor: "#FFD8B2",
            color: "#805B36",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          FullFilled
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: "#B3E5FC",
            color: "#23547B",
            textTransform: "uppercase",
            padding: ".25em 1rem",
            borderRadius: "3px",
            fontWeight: "700",
            letterSpacing: ".3px",
          }}
        >
          In Progress
        </span>
      );
    }
  };
  function getTotal() {
    var Total = 0;
    expiredOrders.forEach((obj) => (Total += parseInt(obj.totalAmount)));
    console.log(Total);
    return parseInt(Total);
  }
  return (
    <div>
      <MenuNavigator />
      <Divider align="center">
        <h2 className="p-text-center">Payment Pending Orders</h2>
      </Divider>
      <DataTable
        className="mx-4"
        showGridlines
        value={expiredOrders}
        selectionMode="single"
      >
        <Column field="companyName" header="Company Name"></Column>
        <Column
          headerStyle={{ textAlign: "center" }}
          bodyStyle={{ textAlign: "center", width: "4rem" }}
          body={productTemplate}
          header="Product"
        ></Column>
        <Column field="contactNumber" header="Contact Number"></Column>
        <Column field="modeOfTransport" header="Mode Of Transport"></Column>
        <Column field="pincode" header="Pincode"></Column>
        <Column
          field="totalAmount"
          header="Total Amount"
          body={totalAmmountTemplete}
        ></Column>
        <Column field="paymentId" header="Payment ID"></Column>
        <Column
          bodyStyle={{ textAlign: "center" }}
          body={fullfilledTemplate}
          header="Fullfilled Status"
        ></Column>
      </DataTable>
      <div style={{ textAlign: "center", margin: "30px 0px" }}>
        <h2>Total Outstanding Amount : {getTotal()}</h2>
        <Button
          style={{ background: "#287F80" }}
          onClick={() => {
            displayRazorpay({
              finalAmount: getTotal(),
              orders: OrderIdsPayLoad,
            });
          }}
        >
          Pay Now
        </Button>
      </div>
    </div>
  );
}

export default ExpiredOrders;
