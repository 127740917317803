import React, { useState, useEffect, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import MenuNavigator from "../../components/MenuNavigator";
import { getUserDetails, getAllUsers } from "../../api/api";
import { useLocation } from "react-router-dom";


const ProfileDetails = () => {
  
  console.log((window.localStorage.getItem("userDetails")))
  const [userInfo, setUserInfo] = useState([]);

  useEffect(() => {
    setUserInfo((window.localStorage.getItem("userDetails")))
  }, [])
  return (
    <div>
      <MenuNavigator />
      <Divider align="center">
        <h3 className="p-text-center">Profile Details</h3>
      </Divider>
      <div className="p-fluid">
        <div className="p-field">
          <label htmlFor="email">Email</label>
          <InputText value={userInfo.email} id="email" type="email" />
        </div>
        <div className="p-field">
          <label htmlFor="password">Password</label>
          <InputText id="password" value={userInfo.password} type="text" />
        </div>
        <div className="p-field">
          <label htmlFor="name">Name</label>
          <InputText id="name" value={userInfo.name} type="text" />
        </div>

        <div className="p-field">
          <label htmlFor="mobileNumber">Mobile Number</label>
          <InputText
            id="mobileNumber"
            value={userInfo.mobileNumber}
            type="text"
          />
        </div>

        <div className="p-field">
          <label htmlFor="companyName">Company Name</label>
          <InputText
            id="companyName"
            value={userInfo.companyName}
            type="text"
          />
        </div>
        <div className="p-field">
          <label htmlFor="address1">Address 1</label>
          <InputTextarea
            id="address1"
            value={userInfo.address1}
            type="text"
          />
        </div>
        <div className="p-field">
          <label htmlFor="address2">Address 2</label>
          <InputTextarea
            id="address2"
            value={userInfo.address2}
            type="text"
          />
        </div>
        <div className="p-field">
          <label htmlFor="address3">Address 3</label>
          <InputTextarea
            id="address3"
            value={userInfo.address3}
            type="text"
          />
        </div>
        <div className="p-field">
          <label htmlFor="address4">Address 4</label>
          <InputTextarea
            id="address4"
            value={userInfo.address4}
            type="text"
          />
        </div>
        <div className="p-field">
          <label htmlFor="state">State</label>
          <InputText id="state" value={userInfo.state} type="text" />
        </div>
        <div className="p-field">
          <label htmlFor="country">Country</label>
          <InputText id="country" value={userInfo.country} type="text" />
        </div>
        <div className="p-field">
          <label htmlFor="pincode">PinCode</label>
          <InputText id="pincode" value={userInfo.pincode} type="text" />
        </div>
        <div className="p-field">
          <label htmlFor="GST">GST Number</label>
          <InputText id="GST" value={userInfo.GST} type="text" />
        </div>
        <div className="p-field">
          <label htmlFor="deliveryAddress">Delivery Address 1</label>
          <InputTextarea
            id="deliveryAddress"
            value={userInfo.deliveryAddress1}
            type="text"
          />
        </div>
        <div className="p-field">
          <label htmlFor="deliveryAddress">Delivery Address 2</label>
          <InputTextarea
            id="deliveryAddress"
            value={userInfo.deliveryAddress2}
            type="text"
          />
        </div>
        <div className="p-field">
          <label htmlFor="deliveryAddress">Delivery Address 3</label>
          <InputTextarea
            id="deliveryAddress"
            value={userInfo.deliveryAddress3}
            type="text"
          />
        </div>
        <div className="p-field">
          <label htmlFor="deliveryAddress">Delivery Address 4</label>
          <InputTextarea
            id="deliveryAddress"
            value={userInfo.deliveryAddress4}
            type="text"
          />
        </div>
        <div className="p-field">
          <label htmlFor="deliveryCountry">Delivery Country</label>
          <InputText id="deliveryCountry" value={userInfo.address1} type="text" />
        </div>
        <div className="p-field">
          <label htmlFor="deliveryPincode">Delivery Pincode</label>
          <InputText id="deliveryPincode" value={userInfo.deliveryPincode} type="number" />
        </div>
        <div className="p-field">
          <label htmlFor="deliveryState">Delivery State</label>
          <InputText id="deliveryState" value={userInfo.deliveryState} type="text" />
        </div>

        <div className="p-field">
          <label htmlFor="aadhar">Aadhar number</label>
          <InputText id="aadhar" value={userInfo.aadhar} type="number" />
        </div>

        <div className="p-field">
          <label htmlFor="discountPercentage">Discount Percentage</label>
          <InputText
            id="discountPercentage"
            value={userInfo.discountPercentage}
            type="number"
          />
        </div>
        <div className="p-field">
          <label htmlFor="creditPeriod">Credit Period</label>
          <InputText
            id="creditPeriod"
            value={userInfo.creditPeriod}
            type="number"
          />
        </div>

        <Button label="Email Support" className="p-block text-center"></Button>
      </div>
    </div>
  );
};

export default ProfileDetails;
