import React from "react";
import MenuNavigator from "../../../components/MenuNavigator";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FabricsCart from "./components/FabricsCart";
import CataloguesCart from "./components/CataloguesCart";
import MadeToMeasureCart from "./components/MadeToMeasureCart";
import { Button } from "primereact/button";
import { displayRazorpay } from "../PaymentIntegration";
import { useState } from "react";

function CustomerCart() {
  console.log(useSelector((state) => state));
  const [fabricsTotal, setFabricsTotal] = useState(0);
  const [cateloguesTotal, setCateloguesTotal] = useState(0);
  const navigate = useNavigate();
  const madeToMeasure = useSelector(
    (state) => state.persistedReducer.cartReducer.madeToMeasure
  );
  const fabrics = useSelector(
    (state) => state.persistedReducer.cartReducer.fabrics
  );
  const catelogues = useSelector(
    (state) => state.persistedReducer.cartReducer.catelogues
  );
  const cartlength = catelogues.length + fabrics.length + madeToMeasure.length;
  console.log(cartlength);

  const buttonLabel = () => {
    let label = "";
    if (catelogues.length && fabrics.length) {
      label = "Buy Catelogues and Fabrics";
    } else if (catelogues.length) {
      label = "Buy Catelogues";
    } else {
      label = "Buy Fabrics";
    }
    return label;
  };

  return (
    <div>
      <div className="text-center text-2xl font-bold m-3">Cart Items</div>
      <div className="mx-5">
        {cartlength ? (
          <div>
            <FabricsCart fabricsTotal={setFabricsTotal} />
            <CataloguesCart cateloguesTotal={setCateloguesTotal} />
            <div className="text-center text-xl font-bold ">
              Total : {fabricsTotal + cateloguesTotal} Rs
            </div>
            {catelogues.length + fabrics.length > 0 && (
              <div className="m-4 mb-6 text-center">
                <Button
                  onClick={() => {
                    navigate("cartDelivery", {
                      state: {
                        fabrics,
                        catelogues,
                        isMtm: false,
                      },
                    });
                  }}
                  className="w-3"
                  label={buttonLabel()}
                ></Button>
              </div>
            )}

            <MadeToMeasureCart />
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "#B3E5FC",
              color: "#23547B",
              padding: "1rem 2rem",
              borderRadius: "3px",
              fontWeight: "700",
              letterSpacing: "2px",
              fontSize: "25px",
            }}
            className="text-xl  text-center"
          >
            Cart is Empty Right Now
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomerCart;
