import React, { useState } from 'react';
import { Knob } from 'primereact/knob';
import { Button } from 'primereact/button';
import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import logoSRC from '../../assets/logo.jpeg';
import { useNavigate } from 'react-router';
import MenuNavigator from '../../components/MenuNavigator';

const Dashboard = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div>
        <div className="card">
          <MenuNavigator />
        </div>
      </div>
      <div className="card">
        <div className="p-grid p-formgrid p-text-center p-justify-center">
          <div className="p-field p-col-12 p-md-6">
            <h5>Fullfilled Orders</h5>
            <Knob value={94} readOnly size={200} />
          </div>
          <div className="p-field p-col-12 p-md-6 ">
            <h5>Pending Orders</h5>
            <Knob value={43} readOnly size={200} />
          </div>
          <div className="p-field p-col-12 p-md-6">
            <h5>Made to Measure Pending Orders</h5>
            <Knob value={63} readOnly size={200} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
