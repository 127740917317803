import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import MenuNavigator from "../../../components/MenuNavigator";

import { getAllFabrics } from "../../../api/api";
import { Paginator } from "primereact/paginator";

const ListOfAllFabrics = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [fabricList, setFabricList] = useState([]);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  console.log(fabricList);

  useEffect(() => {
    getAllFabrics(currentPage,searchTerm).then((data) => {
      setFabricList(data.allFabrics);
      setCount(data?.fabricCount)
    });
  }, [currentPage,searchTerm]);
  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
    setCurrentPage(event.page + 1);
  };
  const imageBodyTemplate = (rowData) => {
    return (
      <img
        style={{ width: "100px", height: "100px" }}
        src={rowData.image ? rowData.image : rowData.URL}
        alt={rowData.image}
        // style={{margin:'auto'}}
        width="75px"
        onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
        className="product-image"
      />
    );
  };

  return (
    <div>
      <MenuNavigator />

      <h2 className="p-text-center">All Fabrics</h2>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          style={{ background: "#287F80" }}
          label="Add new Fabric"
          className="p-block  p-mb-2"
          onClick={() => {
            navigate("/fabrics/new");
          }}
        ></Button>
        <div className="col-12" style={{ width: "30%" }}>
          <div className="p-inputgroup">
            <InputText
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search Fabric"
            />
            <Button style={{ background: "#287F80" }} label="Search" />
          </div>
        </div>
      </div>
      {/* <div className="p-fluid"></div> */}
      <div className="card">
        <DataTable
          value={fabricList}
          responsiveLayout="scroll"
          sortable
          filterDisplay
          stripedRows
          showGridlines
          rowHover
          onRowClick={(event) => {
            // console.log(event);
            navigate(`/fabrics/${event.data._id}`);
          }}
        >
          <Column field="name" header="Design Name"></Column>
          <Column field="collectionName" header="Collection Name"></Column>
          <Column field="color" header="Color"></Column>
          <Column body={imageBodyTemplate} header="Image"></Column>
          <Column field="fabricWidth" header="Width"></Column>
          <Column field="fabricType" header="Fabric Type"></Column>
          <Column field="HSN" header="HSN"></Column>
          <Column field="quality" header="Quality"></Column>
          <Column field="weight" header="Weight"></Column>
          {/* <Column field="PatternRepeat" header="PatternRepeat"></Column> */}
          {/* <Column field="usage" header="Usage"></Column> */}

          {/* <Column
            field="fabricURL"
            header="image"
            body={imageBodyTemplate}
          ></Column> */}
          {/* <Column body={<img src={""} alt='fabric' />} header="image"></Column> */}
          {/* <Column
            field="architect_fabric_pricing"
            header="Architect Pricing"
          ></Column>
          <Column
            field="retailer_fabric_pricing"
            header="Retailer Pricing"
          ></Column>
          <Column
            field="channelPartner_fabric_pricing"
            header="Channel PartnerPricing"
          ></Column> */}
          {/* <Column field="staff_fabric_pricing" header="Staff Pricing"></Column> */}
          {/* <Column field="fabrictype" header="Fabric Type"></Column> */}
        </DataTable>
        <Paginator
            first={basicFirst}
            rows={basicRows}
            totalRecords={count}
            onPageChange={onBasicPageChange}
          ></Paginator>
      </div>
    </div>
  );
};

export default ListOfAllFabrics;
