import React, { useState, useRef } from "react";
import { Upload, message } from "antd";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import Resizer from "react-image-file-resizer";

const ImageUploadBtn = ({ intent, id, isDisabled = false, setEvent }) => {
  const [loading, setLoading] = useState(false);
  let uploadOptions;
  const toast = useRef(null);
  const showCatalogueUpdated = () => {
    toast.current.show({ severity: "success", summary: "Catalogue Uploaded!" });
  };
  const showFabrciUpdated = () => {
    toast.current.show({ severity: "success", summary: "Fabric Uploaded!" });
  };
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800,
        600,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });
  if (intent === "catalogue") {
    uploadOptions = {
      showUploadList: false,
      multiple: false,
      accept: "image/*",
      customRequest: async (event) => {
        // setLoading(true);
        // const fd = new FormData();
        // fd.append("image", event.file, event.file.name);

        // const response = await fetch(
        //   `${process.env.REACT_APP_BACKEND_BASE_URL}/catalogues/upload/${id}`,
        //   {
        //     method: "POST",
        //     headers: {
        //       Authorization: window.localStorage.getItem("userId"),
        //     },
        //     body: fd,
        //   }
        // );

        // if (response.status === 200 && response.ok === true) {
        //   setLoading(false);
        //   showCatalogueUpdated();
        //   // message.success("Successfully Uploaded", 2);
        // } else {
        //   setLoading(false);

        //   // message.error("Error Uploading File", 2);
        // }
        // console.log("event", event.file);
        const file = await resizeFile(event.file);
        // console.log("event2", file);
        setEvent(file);
        toast.current.show({ severity: "success", summary: "Image Added!" });
      },
    };
  }

  if (intent === "fabrics") {
    uploadOptions = {
      showUploadList: false,
      multiple: false,
      accept: "image/*",
      customRequest: async (event) => {
        // setLoading(true);
        const file = await resizeFile(event.file);
        setEvent(file);
        toast.current.show({ severity: "success", summary: "Image Added!" });
      },
    };
  }

  return (
    <Upload {...uploadOptions}>
      <Toast ref={toast}></Toast>
      <Button
        style={{ width: "200px", justifyContent: "center" }}
        // loading={loading}
        disabled={isDisabled}
        type={"button"}
      >
        Upload
      </Button>
    </Upload>
  );
};

export default ImageUploadBtn;
