import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import MenuNavigator from "../../../components/MenuNavigator";

import { Toast } from "primereact/toast";
import { addNewUser } from "../../../api/api";

const AddNewUser = () => {
  const [userType, setUserType] = useState(null);
  // const [city, setCity] = useState(null);
  const userTypeOptions = ["architect", "retailer", "channelPartner", "agent"];
  const [formData, setFormData] = useState({});
  // const cityOptions = ['Bangalore', 'Surat', 'Mumbai', 'Delhi'];
  const [creditChecked, setCreditChecked] = useState(true);
  const [checked, setChecked] = useState(false);

  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Success!",
      detail: "User Added",
    });
  };
  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error!",
      detail: message,
    });
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      location: "",
      mobileNumber: "",
      companyName: "",
      address1: "",
      address2: "",
      address3: "",
      address4: "None",
      accessory: "No",
      city: "",
      state: "",
      country: "",
      pincode: "",
      GST: "",
      aadhar: "",
      deliveryAddress1: "",
      deliveryAddress2: "",
      deliveryAddress3: "",
      deliveryAddress4: "None",
      deliveryCity: "",
      deliveryState: "",
      deliveryCountry: "",
      deliveryPincode: "",
      discountPercentage: 0,
      userType: null,
      paymentOption: "",
      is_made_to_measure: false,
    },
    validate: (data) => {
      let errors = {};

      if (!data.name) {
        errors.name = "Name is required.";
      }

      if (!data.email) {
        errors.email = "Email is required.";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = "Invalid email address. E.g. example@email.com";
      }

      if (!data.mobileNumber) {
        errors.mobileNumber = "This field is mandatory";
      }
      if (!data.companyName) {
        errors.companyName = "This field is mandatory";
      }
      if (!data.address1) {
        errors.address1 = "This field is mandatory";
      }
      if (!data.address2) {
        errors.address2 = "This field is mandatory";
      }
      if (!data.address3) {
        errors.address3 = "This field is mandatory";
      }
      if (!data.city) {
        errors.city = "This field is mandatory";
      }
      if (!data.state) {
        errors.state = "This field is mandatory";
      }
      if (!data.country) {
        errors.country = "This field is mandatory";
      }
      if (!data.pincode) {
        errors.pincode = "This field is mandatory";
      }
      if (!data.GST) {
        errors.GST = "This field is mandatory";
      }
      // if (!data.aadhar) {
      //   errors.aadhar = "This field is mandatory";
      // }
      if (!data.deliveryAddress1) {
        errors.deliveryAddress1 = "This field is mandatory";
      }
      if (!data.deliveryAddress2) {
        errors.deliveryAddress2 = "This field is mandatory";
      }
      if (!data.deliveryAddress3) {
        errors.deliveryAddress3 = "This field is mandatory";
      }
      if (!data.deliveryCity) {
        errors.deliveryCity = "This field is mandatory";
      }
      if (!data.deliveryState) {
        errors.deliveryState = "This field is mandatory";
      }
      if (!data.deliveryCountry) {
        errors.deliveryCountry = "This field is mandatory";
      }
      // if (!data.deliveryPincode) {
      //   errors.deliveryPincode = "This field is mandatory";
      // }
      // if (!data.discountPercentage) {
      //   errors.discountPercentage = "This field is mandatory";
      // }

      if (!data.userType) {
        errors.userType = "This field is mandatory";
      }
      // if (!data.paymentOption) {
      //   errors.paymentOption = "This field is mandatory";
      // }

      return errors;
    },
    onSubmit: (data) => {
      const registerData = {};
      console.log(
        Object.keys(data)
          .filter((item) => data[item].length !== 0)
          .map((key) => (registerData[key] = data[key]))
      );
      setFormData(registerData);
      console.log("registerData", registerData);
      addNewUser(registerData)
        .then((data) => {
          console.log(data);
          if (Object.keys(data.data).includes("token")) {
            showSuccess();
            formik.resetForm();
          }
        })
        .catch((e) => {
          console.log(e);
          showError("User Already Exists");
        });
    },
  });
  formik.values.is_made_to_measure = checked;
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  // console.log(formik.values);
  const toast = useRef(null);
  return (
    // <Envelope>
    <div>
      <Divider align="center">
        <Toast ref={toast}></Toast>
        <h2 className="p-text-center">Add New User</h2>
      </Divider>
      <form onSubmit={formik.handleSubmit} className="p-fluid">
        <div
          className="p-fluid"
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <div style={{ width: "30%" }}>
            <div className="p-field">
              <label htmlFor="email">Email*</label>
              <InputText
                id="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className={classNames({ "p-error": isFormFieldValid("email") })}
              />
              {getFormErrorMessage("email")}
            </div>
            <div className="p-field">
              <label htmlFor="name">Name*</label>
              <InputText
                id="name"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
                className={classNames({ "p-error": isFormFieldValid("name") })}
              />
              {getFormErrorMessage("name")}
            </div>

            <div className="p-field">
              <label htmlFor="mobileNumber">Mobile Number*</label>
              <InputText
                id="mobileNumber"
                type={"number"}
                value={formik.values.mobileNumber}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("mobileNumber"),
                })}
              />

              {getFormErrorMessage("mobileNumber")}
            </div>

            <div className="p-field">
              <label htmlFor="companyName">Company Name*</label>
              <InputText
                id="companyName"
                type="text"
                value={formik.values.companyName}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("companyName"),
                })}
              />
              {getFormErrorMessage("companyName")}
            </div>

            <div className="p-field">
              <label htmlFor="address1"> Address(Line1)*</label>
              <InputText
                id="address1"
                type="text"
                value={formik.values.address1}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("address1"),
                })}
              />
              {getFormErrorMessage("address1")}
            </div>
            <div className="p-field">
              <label htmlFor="address2"> Address(Line2)*</label>
              <InputText
                id="address2"
                type="text"
                value={formik.values.address2}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("address2"),
                })}
              />
              {getFormErrorMessage("address2")}
            </div>
            <div className="p-field">
              <label htmlFor="address3"> Address(Line3)*</label>
              <InputText
                id="address3"
                type="text"
                value={formik.values.address3}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("address3"),
                })}
              />
              {getFormErrorMessage("address3")}
            </div>
            <div className="p-field">
              <label htmlFor="city">City*</label>
              <InputText
                id="city"
                type="text"
                value={formik.values.city}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("city"),
                })}
              />
              {getFormErrorMessage("city")}
            </div>
            <div className="p-field">
              <label htmlFor="state">State*</label>
              <InputText
                id="state"
                type="text"
                value={formik.values.state}
                onChange={formik.handleChange}
                className={classNames({ "p-error": isFormFieldValid("state") })}
              />
              {getFormErrorMessage("state")}
            </div>
            <div className="p-field">
              <label htmlFor="country">Country*</label>
              <InputText
                id="country"
                type="text"
                value={formik.values.country}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("country"),
                })}
              />
              {getFormErrorMessage("country")}
            </div>
            <div className="p-field">
              <label htmlFor="pincode">PinCode*</label>
              <InputText
                id="pincode"
                type="number"
                value={formik.values.pincode}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("pincode"),
                })}
              />
              {getFormErrorMessage("pincode")}
            </div>
            <div className="p-field">
              <label htmlFor="usertype">User Type*</label>
              {/* <InputText id="creditPeriod" type="text" /> */}
              <Dropdown
                // optionLabel="name"
                options={userTypeOptions}
                id="userType"
                name="userType"
                placeholder="Select Type of User"
                value={formik.values.userType}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("userType"),
                })}
              />
              {getFormErrorMessage("userType")}
            </div>
            <div className="p-field flex align-items-center">
              <Checkbox
                onChange={(e) => setChecked(e.checked)}
                checked={checked}
              />
              <label className="ml-2 my-2">Made To Measure</label>
            </div>
          </div>
          <div style={{ width: "30%" }}>
            <div className="p-field">
              <label htmlFor="GST">GST Number*</label>
              <InputText
                id="GST"
                type="text"
                value={formik.values.GST}
                onChange={formik.handleChange}
                className={classNames({ "p-error": isFormFieldValid("GST") })}
              />
              {getFormErrorMessage("GST")}
            </div>
            <div className="p-field">
              <label htmlFor="deliveryAddress1">Delivery Address(Line1)*</label>
              <InputText
                id="deliveryAddress1"
                type="text"
                value={formik.values.deliveryAddress1}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("address1"),
                })}
              />
              {getFormErrorMessage("deliveryAddress1")}
            </div>
            <div className="p-field">
              <label htmlFor="deliveryAddress2">Delivery Address(Line2)*</label>
              <InputText
                id="deliveryAddress2"
                type="text"
                value={formik.values.deliveryAddress2}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("deliveryAddress2"),
                })}
              />
              {getFormErrorMessage("address2")}
            </div>
            <div className="p-field">
              <label htmlFor="deliveryAddress3">Delivery Address(Line3)*</label>
              <InputText
                id="deliveryAddress3"
                type="text"
                value={formik.values.deliveryAddress3}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("deliveryAddress3"),
                })}
              />
              {getFormErrorMessage("deliveryAddress3")}
            </div>
            <div className="p-field">
              <label htmlFor="deliveryCity">Delivery City*</label>
              <InputText
                id="deliveryCity"
                type="text"
                value={formik.values.deliveryCity}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("deliveryCity"),
                })}
              />
              {getFormErrorMessage("deliveryCity")}
            </div>

            <div className="p-field">
              <label htmlFor="deliveryState">Delivery State*</label>
              <InputText
                id="deliveryState"
                type="text"
                value={formik.values.deliveryState}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("deliveryState"),
                })}
              />
              {getFormErrorMessage("deliveryState")}
            </div>
            <div className="p-field">
              <label htmlFor="deliveryCountry">Delivery Country*</label>
              <InputText
                id="deliveryCountry"
                type="text"
                value={formik.values.deliveryCountry}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("deliveryCountry"),
                })}
              />
              {getFormErrorMessage("deliveryCountry")}
            </div>
            <div className="p-field">
              <label htmlFor="deliveryPincode">Delivery PinCode</label>
              <InputText
                id="deliveryPincode"
                type="number"
                value={formik.values.deliveryPincode}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("deliveryPincode"),
                })}
              />
              {getFormErrorMessage("deliveryPincode")}
            </div>

            {/* <div className="p-field">
            <label htmlFor="deliveryAddress">Delivery Address</label>
            <InputTextarea
              id="deliveryAddress"
              type="text"
              className={classNames({ 'p-error': isFormFieldValid('name') })}
            />
            {getFormErrorMessage('name')}
          </div> */}
            <div className="p-field">
              <label htmlFor="aadhar">Aadhar number</label>
              <InputText
                id="aadhar"
                type={"number"}
                value={formik.values.aadhar}
                onChange={formik.handleChange}

                // className={classNames({ 'p-error': isFormFieldValid('name') })}
              />
            </div>
            {/* <div className="p-field">
          <label htmlFor="accountType">Account Type</label>
          <InputText id="accountType" type="text" />
        </div> */}
            <div className="p-field">
              <label htmlFor="discountPercentage">
                Discount Percentage(number){" "}
              </label>
              <InputText
                id="discountPercentage"
                type="number"
                value={formik.values.discountPercentage}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("discountPercentage"),
                })}
                min={0}
              />
              {getFormErrorMessage("discountPercentage")}
            </div>
            <div className="p-field">
              <label htmlFor="accessory">Accessory*</label>
              <Dropdown
                options={["Yes", "No"]}
                id="accessory"
                disabled={formik.values.userType !== "architect" && true}
                value={formik.values.accessory}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("accessory"),
                })}
              />
              {getFormErrorMessage("paymentOption")}
            </div>
            <div className="p-field">
              <label htmlFor="paymentOption">Payment Type</label>
              <Dropdown
                optionLabel="name"
                optionValue="value"
                options={[
                  { name: "Pay Now", value: "paynow" },
                  { name: "Credit", value: "credit" },
                  { name: "Advance", value: "advance" },
                ]}
                id="paymentOption"
                value={formik.values.paymentOption}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("paymentOption"),
                })}
              />
              {getFormErrorMessage("paymentOption")}
            </div>

            {formik.values.paymentOption === "credit" ? (
              <div className="p-field my-3">
                <label htmlFor="creditPeriod">
                  Credit Period (In number of days)*
                </label>
                <InputText
                  id="creditPeriod"
                  type="number"
                  value={formik.values.creditPeriod}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-error": isFormFieldValid("creditPeriod"),
                  })}
                  min={0}
                />
                {getFormErrorMessage("creditPeriod")}
              </div>
            ) : (
              ""
            )}
            {formik.values.paymentOption === "advance" ? (
              <div className="p-field my-3">
                <label htmlFor="advancePercentage">
                  Advance Percentage (%)*
                </label>
                <InputText
                  id="advancePercentage"
                  type="number"
                  value={formik.values.advancePercentage}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-error": isFormFieldValid("advancePercentage"),
                  })}
                  min={0}
                />
                {getFormErrorMessage("advancePercentage")}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div style={{ textAlign: "center", margin: "30px 0px" }}>
          <Button
            label="Create User"
            style={{ width: "20%", background: "#287F80" }}
            className="p-block text-center"
            disabled={!(formik.isValid && formik.dirty)}
          ></Button>
        </div>
      </form>
    </div>
  );
};

export default AddNewUser;
