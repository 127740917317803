import React, { useState, useEffect, useRef } from "react";
import { DataView } from "primereact/dataview";
import { Button } from "primereact/button";
import "../EcommerceView.css";
import { InputText } from "primereact/inputtext";
import { getAllCatalogues } from "../../../api/api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Paginator } from "primereact/paginator";
import MenuNavigator from "../../../components/MenuNavigator";
import { addCateloguesToCart } from "../../../redux/cart/cartSlice";
import { Toast } from "primereact/toast";

const ListOfCatalogues = () => {
  const RenderPrice = (currentUser, fabricDetails) => {
    console.log("data:", fabricDetails);
    if (currentUser) {
      console.log("From render Price", currentUser);
      if (currentUser.userType === "retailer") {
        return fabricDetails.retailerCataloguePrice;
      } else if (currentUser.userType === "channelPartner") {
        return fabricDetails.channelPartnerCataloguePrice;
      } else {
        return fabricDetails.architectCataloguePrice;
      }
    }
  };
  function showInfo() {
    toast.current.show({
      severity: "info",
      summary: "Added to Cart",
      detail: "The Catelogue has been added to the cart",
    });
  }
  const toast = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([]);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [layout, setLayout] = useState("grid");
  const currentUser = JSON.parse(window.localStorage.getItem("userDetails"));

  console.log(products);

  useEffect(() => {
    getAllCatalogues(currentPage, searchTerm).then((data) => {
      console.log(data);
      // setProducts(data.allCatalogues);
      setCount(data?.catalogueCount);
      const res = data.allCatalogues?.map((item, index) => {
        return { ...item, quantity: 1, index: index };
      });
      console.log("data ", res);
      // setProducts(data.allCatalogues);
      setProducts(res);
    });
  }, [currentPage, searchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
    setCurrentPage(event.page + 1);
  };

  const increment = (id) => {
    console.log("increment");
    const data = products?.map((item) => {
      if (item?._id === id) {
        return { ...item, quantity: item?.quantity + 1 };
      } else {
        return { ...item };
      }
    });
    setProducts(data);
  };
  const decrement = (id, qua) => {
    console.log("decrement");
    const data = products.map((item) => {
      if (item?._id === id && qua > 1) {
        return { ...item, quantity: item?.quantity - 1 };
      } else {
        return { ...item };
      }
    });
    setProducts(data);
  };

  const renderGridItem = (data) => {
    return (
      <div className="col-12 md:col-3">
        <div className="product-grid-item card">
          <div
            onClick={() => navigate("/catelogue/".concat(data._id))}
            className="product-grid-item-content"
          >
            <img
              style={{ width: "200px", height: "200px" }}
              src={
                data.image
                  ? data.image
                  : "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=826&t=st=1661248188~exp=1661248788~hmac=64bb7d23be8dcef15ecfef304e7d5bfe293f29bbf5b48fd6cadb282e205f9ddb"
              }
              alt={data.name}
            />
            <div className="product-name text-sm">{data.name}</div>
            <span>Rs{RenderPrice(currentUser, data)}</span>
          </div>
          {window.localStorage.getItem("userType") === "sales" ||
          window.localStorage.getItem("userType") === "accounts" ? null : (
            <div className="flex justify-content-center align-items-center">
              <Button
                onClick={() => decrement(data?._id, data?.quantity)}
                className="mx-2 border-circle p-button-outlined"
                label="-"
              ></Button>
              <div className="text-xl mx-1 font-bold">{data?.quantity}</div>
              <Button
                onClick={() => increment(data?._id)}
                className="mx-2 border-circle p-button-outlined"
                label="+"
              ></Button>
            </div>
          )}
          {window.localStorage.getItem("userType") === "sales" ||
          window.localStorage.getItem("userType") === "accounts" ? (
            <div className="text-center p-1 mt-2">
              <Button
                onClick={() => {
                  navigate("/catelogue/".concat(data._id));
                }}
                icon="pi pi-shopping-cart"
                className="w-6"
                label="Click to Buy"
              ></Button>
            </div>
          ) : (
            <div className="text-center p-1 mt-2">
              <Button
                onClick={() => {
                  dispatch(
                    addCateloguesToCart({
                      ...data,
                      price: RenderPrice(currentUser, data),
                    })
                  );
                  showInfo();
                }}
                icon="pi pi-shopping-cart"
                className="w-6"
                label="Add to Cart"
              ></Button>
            </div>
          )}
        </div>
      </div>
    );
  };

  const itemTemplate = (product, layout) => {
    if (!product) {
      return;
    } else if (layout === "grid") return renderGridItem(product);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <h2 className="m-0">Catelogues</h2>
        <div className="col-12" style={{ width: "30%" }}>
          <div className="p-inputgroup">
            <InputText
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search Fabric"
            />
            <Button
              style={{
                backgroundColor: "#287F80",
              }}
              label="Search"
            />
          </div>
        </div>
      </div>
    );
  };
  const header = renderHeader();
  return (
    <div>
      <Toast ref={toast} />
      <div className="dataview-demo m-5">
        <div className="card">
          <DataView
            value={products}
            layout={layout}
            itemTemplate={itemTemplate}
            header={header}
          ></DataView>
          <Paginator
            first={basicFirst}
            rows={basicRows}
            totalRecords={count}
            onPageChange={onBasicPageChange}
          ></Paginator>
        </div>
      </div>
    </div>
  );
};

export default ListOfCatalogues;
