import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TreeTable } from "primereact/treetable";
import MenuNavigator from "../../components/MenuNavigator";
import { getAllFabrics, getAllCatalogues } from "../../api/api";
import { Paginator } from "primereact/paginator";

const UsersPricingDetails = () => {
  const navigate = useNavigate();
  const userType = window.localStorage.getItem("userType");
  const [fabricList, setFabricList] = useState([]);
  const [catalogueList, setCatalogueList] = useState([]);
  const [searchFabric, setSearchFabric] = useState("");
  const [searchCatalogue, setSearchCatalogue] = useState("");
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [basicFirst2, setBasicFirst2] = useState(0);
  const [basicRows2, setBasicRows2] = useState(10);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [count2, setCount2] = useState(0);
  const renderFabricPrice = () => {
    if (userType === "retailer") {
      return (
        <Column
          field="retailerCutLengthPrice"
          header="Retailer Pricing"
        ></Column>
      );
    } else if (userType === "channelPartner") {
      return (
        <Column
          field="channelPartnerCutLengthPrice"
          header="Channel-Partner Pricing"
        ></Column>
      );
    } else {
      return (
        <Column
          field="architectCutLengthPrice"
          header="Channel-Partner Pricing"
        ></Column>
      );
    }
  };
  const renderCataloguePrice = () => {
    if (userType === "retailer") {
      return (
        <Column
          field="retailerCataloguePrice"
          header="Retailer Pricing"
        ></Column>
      );
    } else if (userType === "channelPartner") {
      return (
        <Column
          field="channelPartnerCataloguePrice"
          header="Channel-Partner Pricing"
        ></Column>
      );
    } else {
      return (
        <Column
          field="architectCataloguePrice"
          header="Channel-Partner Pricing"
        ></Column>
      );
    }
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <img
        style={{ width: "70px", height: "70px" }}
        src={rowData.image}
        alt={rowData.image}
        // style={{margin:'auto'}}
        width="75px"
        onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
        className="product-image"
      />
    );
  };

  useEffect(() => {
    getAllCatalogues(currentPage,searchCatalogue).then((data) => {
      setCatalogueList(data.allCatalogues);
      setCount(data?.catalogueCount)
      // console.log("catelouge len", data?.allCatalogues?.length);
    });
  }, [currentPage,searchCatalogue]);
  useEffect(() => {
    getAllFabrics(currentPage2,searchFabric).then((data) => {
      setFabricList(data.allFabrics);
      setCount2(data?.fabricCount)
      // console.log("fabric, len", data.allFabrics?.length);
    });
  }, [currentPage2,searchFabric]); // eslint-disable-line react-hooks/exhaustive-deps
  console.log(catalogueList);

  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
    setCurrentPage(event.page + 1);
  };
  const onBasicPageChange2 = (event) => {
    setBasicFirst2(event.first);
    setBasicRows2(event.rows);
    setCurrentPage2(event.page + 1);
  };
  const imageBodyTemplate2 = (rowData) => {
    return (
      <img
        style={{ width: "70px", height: "70px" }}
        src={rowData.image ? rowData.image : rowData.URL}
        onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
        alt={rowData.image}
        // onClick={(rowData) => {
        //   navigate('/catalogues/435435');
        // }}
        className="product-image"
      />
      // JSON.stringify(rowData.data.image)
    );
  };

  return (
    <div>
      <MenuNavigator />
      <Divider align="center">
        <h2 className="p-text-center">Fabric Pricing</h2>
      </Divider>
      <div className="mx-4">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            style={{ background: "#287F80" }}
            label="Email Support"
            className="p-block  p-mb-2"
            onClick={() => {
              navigate("/fabrics/new");
            }}
          />
          <div className="col-12" style={{ width: "30%" }}>
            <div className="p-inputgroup">
              <InputText
                value={searchFabric}
                onChange={(e) => setSearchFabric(e.target.value)}
                placeholder="Search Fabric"
              />
              <Button style={{ background: "#287F80" }} label="Search" />
            </div>
          </div>
        </div>

        <div className="card">
          <DataTable
            value={fabricList.filter((val) => {
              if (searchFabric === "") {
                return val;
              } else if (
                val.name.toLowerCase().includes(searchFabric.toLowerCase())
              ) {
                return val;
              } else if (
                val.color.toLowerCase().includes(searchFabric.toLowerCase())
              ) {
                return val;
              }
              // else if (
              //   val.designNo.toLowerCase().includes(searchFabric.toLowerCase())
              // ) {
              //   return val;
              // }
            })}
            responsiveLayout="scroll"
            sortable
            filterDisplay
            stripedRows
            showGridlines
            rowHover
            // onRowClick={(event) => {
            //   console.log(event);
            //   navigate('/fabrics/324324324234324');
            // }}
          >
            <Column field="name" header="Fabric"></Column>
            <Column
              field="URL"
              header="Image"
              body={imageBodyTemplate}
            ></Column>
            {/* <Column body={<img src={""} alt='fabric' />} header="image"></Column> */}
            <Column field="collectionName" header="Collection Name"></Column>
            <Column field="color" header="Color"></Column>
            {renderFabricPrice()}

            {/* <Column
            field="architectFabricPrice"
            header="Architect Pricing"
          ></Column> */}
            <Column field="fabricType" header="Fabric Type"></Column>
          </DataTable>
          <Paginator
            first={basicFirst2}
            rows={basicRows2}
            totalRecords={count2}
            onPageChange={onBasicPageChange2}
          ></Paginator>
        </div>
        {/* </div> */}
        <Divider align="center">
          <h2 className="p-text-center">Catalogues Pricing</h2>
        </Divider>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            style={{ background: "#287F80" }}
            label="Email Support"
            className="p-block  p-mb-2"
            onClick={() => {
              navigate("/fabrics/new");
            }}
          />
          <div className="col-12" style={{ width: "30%" }}>
            <div className="p-inputgroup">
              <InputText
                value={searchCatalogue}
                onChange={(e) => setSearchCatalogue(e.target.value)}
                placeholder="Search Catalogue"
              />
              <Button style={{ background: "#287F80" }} label="Search" />
            </div>
          </div>
        </div>

        <div className="card">
          <DataTable
            responsiveLayout="scroll"
            sortable
            filterDisplay
            stripedRows
            showGridlines
            rowHover
            value={catalogueList.filter((val) => {
              if (searchCatalogue === "") {
                return val;
              } else if (
                val.name.toLowerCase().includes(searchCatalogue.toLowerCase())
              ) {
                return val;
              }
            })}
          >
            <Column field="name" header="Name"></Column>
            <Column
              field="image"
              header="Image"
              body={imageBodyTemplate2}
            ></Column>

            {renderCataloguePrice()}
            {/* <Column
            field="architectCataloguePrice"
            header="Architect Price"
          ></Column> */}
          </DataTable>
          <Paginator
            first={basicFirst}
            rows={basicRows}
            totalRecords={count}
            onPageChange={onBasicPageChange}
          ></Paginator>
        </div>
      </div>
    </div>
  );
};

export default UsersPricingDetails;
