import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import MenuNavigator from "../../../components/MenuNavigator";

import { useLocation, useNavigate } from "react-router-dom";
import {
  getFabricDetails,
  deleteFabric,
  updateFabric,
  getAllCatalogues,
} from "../../../api/api";
import ImageUploadBtn from "../../../components/ImageUploadBtn";

const FabricDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const fabricTypeOptions = ["Upholstry", "Sheers", "Curtains", "Contract"];
  const toast = useRef(null);
  const [fabricDetails, setFabricDetails] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [designName, setDesignName] = useState("");
  const [fabricUrl, setFabricUrl] = useState("");
  const [collectionName, setCollectionName] = useState("");
  const [color, setColor] = useState("");
  const [composition, setComposition] = useState("");
  const [quality, setQuality] = useState("");
  const [fabricWidth, setFabricWidth] = useState("");
  const [weight, setWeight] = useState("");
  const [patternRepeat, setPatternRepeat] = useState("");
  const [fabricCare, setFabricCare] = useState("");
  const [usage, setUsage] = useState("");
  const [catalogueAffiliation, setCatalogueAffiliation] = useState("");
  const [HSN, setHSN] = useState("");
  // const [architectPricing, setArchitectPricing] = useState("")
  const [architectCutLengthPricing, setArchitectCutLengthPricing] =
    useState("");
  const [architectRollLengthPricing, setArchitectRollLengthPricing] =
    useState("");
  // const [retailerPricing, setRetailerPricing] = useState("")
  const [retailerCutLengthPricing, setRetailerCutLengthPricing] = useState("");
  const [retailerRollLengthPricing, setRetailerRollLengthPricing] =
    useState("");
  // const [channelPricing, setChannelPricing] = useState("")
  const [channelCutLengthPricing, setChannelCutLengthPricing] = useState("");
  const [channelRollLengthPricing, setChannelRollLengthPricing] = useState("");
  const [fabricType, setFabricType] = useState("");
  const [fabricImage, setFabricImage] = useState(null);
  const [catalogueOptions, setCatalogueOptions] = useState([]);
  const fabricId = location.pathname.split("/")[2];
  const showFabricUpdated = () => {
    toast.current.show({
      severity: "success",
      summary: "Fabric Updated!",
      detail: "You will be Redirected to Fabric Page",
    });
  };
  const showFabricDeleted = () => {
    toast.current.show({
      severity: "success",
      summary: "Fabric Deleted",
      detail: "You will be Redirected to Fabric Page",
    });
  };
  useEffect(() => {
    getFabricDetails(location.pathname.split("/")[2]).then((data) => {
      // const fullCatalogAffiliation = data;
      // const fabricData = {
      //   ...fullCatalogAffiliation,
      //   catalogueAffiliation: fullCatalogAffiliation.catalogueAffiliation.map(
      //     (catalog) => {
      //       return catalog.name;
      //       // return { catalogueName: catalog.name,
      //       //   catalogueId: catalog._id
      //       //  };
      //     }
      //   ),
      // };
      const fabricData = data;
      console.log("fabric data", fabricData);
      setFabricDetails(fabricData);
      setDesignName(fabricData.name);
      setFabricUrl(fabricData.URL);
      setCollectionName(fabricData.collectionName);
      setColor(fabricData.color);
      setComposition(fabricData.composition);
      setQuality(fabricData.quality);
      setFabricWidth(fabricData.fabricWidth);
      setWeight(fabricData.weight);
      setPatternRepeat(fabricData.patternRepeat);
      setFabricCare(fabricData.fabricCare);
      setUsage(fabricData.usage);
      setCatalogueAffiliation(fabricData.catalogueAffiliation);
      // setArchitectPricing("")/*fabricData.architectFabricPrice*/
      setArchitectCutLengthPricing(fabricData?.architectCutLengthPrice);
      setArchitectRollLengthPricing(fabricData?.architectRollLengthPrice);
      // setChannelPricing("")/*fabricData.channelPartnerFabricPrice*/
      setChannelCutLengthPricing(fabricData?.channelPartnerCutLengthPrice);
      setChannelRollLengthPricing(fabricData?.channelPartnerRollLengthPrice);
      // setRetailerPricing("")/*fabricData.retailerFabricPrice*/
      setRetailerCutLengthPricing(fabricData?.retailerCutLengthPrice);
      setRetailerRollLengthPricing(fabricData?.retailerRollLengthPrice);
      setCatalogueAffiliation(
        fabricData?.catalogueAffiliation?.map((item) => item._id)
      );
      setFabricType(fabricData.fabricType);
      setHSN(fabricData.HSN);
    });
    getAllCatalogues()
      .then((data) => {
        console.log("data", data);
        return data.allCatalogues.map((fabric) => fabric);
      })
      .then((data) => {
        setCatalogueOptions(data);
      });
  }, [location]);
  const updateData = () => {
    const formData = new FormData();
    formData.append("name", designName);
    formData.append("URL", fabricUrl);
    formData.append("collectionName", collectionName);
    formData.append("color", color);
    formData.append("composition", composition);
    formData.append("quality", quality);
    formData.append("fabricWidth", fabricWidth);
    formData.append("weight", weight);
    formData.append("patternRepeat", patternRepeat);
    formData.append("fabricCare", fabricCare);
    formData.append("usage", usage);
    formData.append("architectCutLengthPrice", architectCutLengthPricing);
    formData.append("architectRollLengthPrice", architectRollLengthPricing);
    formData.append("retailerCutLengthPrice", retailerCutLengthPricing);
    formData.append("retailerRollLengthPrice", retailerRollLengthPricing);
    formData.append("channelPartnerCutLengthPrice", channelCutLengthPricing);
    formData.append("channelPartnerRollLengthPrice", channelRollLengthPricing);
    formData.append("fabricType", fabricType);
    formData.append("HSN", HSN);
    catalogueAffiliation?.map((item) => {
      formData.append("catalogueAffiliation", item);
    });
    console.log("cat data", catalogueAffiliation);
    // formData.append("HSN", data.HSN);
    if (fabricImage !== null) {
      formData.append("image", fabricImage, fabricImage.name);
    }
    // {
    //   ,
    //   fabricWidth: fabricWidth,
    //   weight: weight,
    //   patternRepeat: patternRepeat,
    //   fabricCare: fabricCare,
    //   usage: usage,
    //   catalogueAffiliation: catalogueAffiliation,
    //   // architectFabricPrice: architectPricing,
    //   architectCutLengthFabricPrice: architectCutLengthPricing,
    //   architectRollLengthFabricPrice: architectRollLengthPricing,
    //   // channelPartnerFabricPrice: channelPricing,
    //   channelPartnerCutLengthFabricPrice: channelCutLengthPricing,
    //   channelPartnerRollLengthFabricPrice: channelRollLengthPricing,
    //   // retailerFabricPrice: retailerPricing,
    //   retailerPartnerCutLengthFabricPrice: retailerCutLengthPricing,
    //   retailerPartnerRollLengthFabricPrice: retailerRollLengthPricing,
    //   fabricType: fabricType,
    // }
    updateFabric(fabricId, formData)
      .then((data) => {
        console.log(data);
        showFabricUpdated();
        setTimeout(() => {
          navigate("/fabrics");
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log("fabric data", fabricImage);
  return (
    <div>
      <MenuNavigator />
      <Divider align="center">
        <Toast ref={toast}></Toast>
        <h2 className="p-text-center">Fabric Details</h2>
      </Divider>
      <div
        className="p-fluid"
        style={{ display: "flex", justifyContent: "space-evenly" }}
      >
        <div style={{ width: "35%" }}>
          <div className="p-field">
            <label htmlFor="Design">Design Name</label>
            <InputText
              disabled={disabled}
              id="Design"
              type="text"
              onChange={(e) => setDesignName(e.target.value)}
              value={designName}
            />
          </div>

          <div className="p-field">
            <label htmlFor="collectionName">Collection Name</label>
            <InputText
              disabled={disabled}
              onChange={(e) => setCollectionName(e.target.value)}
              id="collectionName"
              type="text"
              value={collectionName}
            />
          </div>
          <div className="p-field">
            <label htmlFor="color">Colour</label>
            <InputText
              disabled={disabled}
              onChange={(e) => setColor(e.target.value)}
              id="color"
              type="text"
              value={color}
            />
          </div>
          <div className="p-field">
            <label htmlFor="composition">Composition</label>
            <InputText
              disabled={disabled}
              id="composition"
              onChange={(e) => setComposition(e.target.value)}
              type="text"
              value={composition}
            />
          </div>
          <div className="p-field">
            <label htmlFor="quality">Quality</label>
            <InputText
              disabled={disabled}
              id="quality"
              onChange={(e) => setQuality(e.target.value)}
              type="text"
              value={quality}
            />
          </div>
          <div className="p-field">
            <label htmlFor="fabricWidth">Fabric Width</label>
            <InputText
              disabled={disabled}
              id="fabricWidth"
              onChange={(e) => setFabricWidth(e.target.value)}
              type="text"
              value={fabricWidth}
            />
          </div>
          <div className="p-field">
            <label htmlFor="weight">Weight</label>
            <InputText
              disabled={disabled}
              id="weight"
              onChange={(e) => setWeight(e.target.value)}
              type="text"
              value={weight}
            />
          </div>
          <div className="p-field">
            <label htmlFor="pattern">Pattern Repeat</label>
            <InputText
              disabled={disabled}
              id="pattern"
              onChange={(e) => setPatternRepeat(e.target.value)}
              type="text"
              value={patternRepeat}
            />
          </div>
        </div>
        <div style={{ width: "35%" }}>
          <div className="p-field">
            <label htmlFor="fabricCare">HSN</label>
            <InputText
              disabled={disabled}
              id="HSN"
              onChange={(e) => setHSN(e.target.value)}
              type="text"
              value={HSN}
            />
          </div>
          <div className="p-field">
            <label htmlFor="fabricCare">Fabric Care</label>
            <InputText
              disabled={disabled}
              id="fabricCare"
              onChange={(e) => setFabricCare(e.target.value)}
              type="text"
              value={fabricCare}
            />
          </div>
          <div className="p-field">
            <label htmlFor="usage">Usage</label>
            <InputText
              disabled={disabled}
              id="usage"
              onChange={(e) => setUsage(e.target.value)}
              type="text"
              value={usage}
            />
          </div>
          {fabricDetails.catalogueAffiliation && (
            <div className="p-field">
              <label htmlFor="catalogueAffiliation">
                Catalogue Affiliation
              </label>
              <MultiSelect
                onChange={(e) => setCatalogueAffiliation(e.target.value)}
                value={catalogueAffiliation}
                options={catalogueOptions}
                // options={fabricDetails.catalogueAffiliation}
                // onChange={(e) => setSelectedCatalogues(e.value)}
                // optionLabel="catalogueName"
                // placeholder="Select a Catalogue"
                optionLabel="name"
                optionValue="_id"
                disabled={disabled}
                display="chip"
              />
            </div>
          )}
          <div className="p-field">
            <label htmlFor="architectFabricPrice">
              Architect Fabric Pricing
            </label>
            <div styles={{ display: "flex" }}>
              <InputText
                placeholder="Cut Length Price"
                // disabled={disabled}
                id="architectCutLengthFabricPrice"
                type="number"
                onChange={(e) => setArchitectCutLengthPricing(e.target.value)}
                value={architectCutLengthPricing}
                style={{ width: "50%" }}
                disabled={disabled}
              />
              <InputText
                placeholder="Roll Length Price"
                // disabled={disabled}
                id="architectRollLengthFabricPrice"
                type="number"
                onChange={(e) => setArchitectRollLengthPricing(e.target.value)}
                value={architectRollLengthPricing}
                style={{ width: "50%" }}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="p-field">
            <label htmlFor="retailerFabricPrice">Retailer Fabric Pricing</label>
            <div styles={{ display: "flex" }}>
              <InputText
                placeholder="Cut Length Price"
                // disabled={disabled}
                id="retailerCutLengthFabricPrice"
                onChange={(e) => setRetailerCutLengthPricing(e.target.value)}
                type="number"
                value={retailerCutLengthPricing}
                disabled={disabled}
                style={{ width: "50%" }}
              />
              <InputText
                placeholder="Roll Length Price"
                // disabled={disabled}
                id="retailerRollLengthFabricPrice"
                onChange={(e) => setRetailerRollLengthPricing(e.target.value)}
                type="number"
                value={retailerRollLengthPricing}
                disabled={disabled}
                style={{ width: "50%" }}
              />
            </div>
          </div>
          <div className="p-field">
            <label htmlFor="channelPartnerFabricPrice">
              Channel Partner Fabric Pricing
            </label>
            <div styles={{ display: "flex" }}>
              <InputText
                placeholder="Cut Length Price"
                // disabled={disabled}
                onChange={(e) => setChannelCutLengthPricing(e.target.value)}
                id="channelPartnerCutLengthFabricPrice"
                type="number"
                value={channelCutLengthPricing}
                disabled={disabled}
                style={{ width: "50%" }}
              />
              <InputText
                placeholder="Roll Length Price"
                // disabled={disabled}
                onChange={(e) => setChannelRollLengthPricing(e.target.value)}
                id="channelPartnerRollLengthFabricPrice"
                type="number"
                value={channelRollLengthPricing}
                disabled={disabled}
                style={{ width: "50%" }}
              />
            </div>
          </div>
          {/* <div className="p-field">
          <label htmlFor="staffFabricPrice">Staff Fabric Pricing</label>
          <InputText  disabled={disabled} id="staffFabricPrice" type="text" />
        </div> */}
          <div className="p-field">
            <label htmlFor="fabricType">Fabric Type</label>
            <InputText
              disabled={disabled}
              id="fabricType"
              onChange={(e) => setFabricType(e.target.value)}
              value={fabricType}
              type="text"
            />
            {/* <Dropdown
            id="fabricType"
             disabled={disabled}
            value={fabricDetails.fabricType}
            // value={fabricType}
            // options={fabricTypeOptions}
            // onChange={(e) => setFabricType(e.value)}
            // placeholder="Select Type of Fabric"
          /> */}
          </div>
          <div className="p-field">
            <label htmlFor="uploadFabricImage">Upload Fabric Image</label>
            {/* <ImageUploadBtn
              intent="fabrics"
              id={fabricId}
              isDisabled={disabled}
            /> */}
            <ImageUploadBtn
              txt="Select Image"
              intent="fabrics"
              imageUploaded
              id={fabricId}
              setEvent={setFabricImage}
              isDisabled={disabled}
            />
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ margin: "30px", width: "15%" }}
          label="Back"
          onClick={() => navigate("/fabrics")}
          className="p-block p-button-danger text-center"
        ></Button>
        <Button
          label="Edit"
          onClick={() => setDisabled(false)}
          style={{ margin: "30px", width: "15%" }}
          className="p-block p-button-secondary text-center"
        ></Button>
        <Button
          style={{ margin: "30px", width: "15%", background: "#287F80" }}
          label="Update"
          onClick={() => {
            updateData();
          }}
          className="p-block p-button-primary text-center"
        ></Button>
        <Button
          label="Delete"
          onClick={() => {
            deleteFabric(fabricId).then((data) => console.log(data));
            showFabricDeleted();
            setTimeout(() => {
              navigate("/fabrics");
            }, 2000);
          }}
          style={{ margin: "30px", width: "15%" }}
          className="p-block p-button-danger text-center"
        ></Button>
      </div>
    </div>
  );
};

export default FabricDetails;
