import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { addMadeToMeasureToCart } from "../../../redux/cart/cartSlice";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";

import "./PlaceMadeToMeasureOrder.css";
import MenuNavigator from "../../../components/MenuNavigator";

import {
  getAllCatalogues,
  getAllFabrics,
  getCatalogueDetails,
  getCatalogues,
  getFabricDetails,
  getFabrics,
  getMtmDetails,
} from "../../../api/api";

const PlaceMadeToMeasureOrder = () => {
  const navigate = useNavigate();
  const toast = useRef(null);
  const [tableValues, setTableValues] = useState([]);
  const dispatch = useDispatch();
  const usertype = window.localStorage.getItem("userType");
  const currentUser = JSON.parse(window.localStorage.getItem("userDetails"));
  const [fabPrice, setFabPrice] = useState(0);
  const [ArrayOfFabrics, setAllFabrics] = useState([]);
  const [checked, setChecked] = useState(false);
  const [stitchPrice, setStitchPrice] = useState("0");
  const [installPrice, setInstallPrice] = useState("0");

  const [catalogueTableFieldOptions, setcatalogueTableFieldOptions] = useState(
    []
  );

  const [fabricTableFieldOptions, setFabricTableFieldOptions] = useState([]);
  function showInfo() {
    toast.current.show({
      severity: "info",
      summary: "Added to Cart",
      detail: "Your Made To Measure has been added to the cart",
    });
  }

  const formik = useFormik({
    initialValues: {
      id: (Math.random() + 1).toString(36).substring(7),
      roomType: "",
      window: "",
      catalogue: "",
      fabric: "",
      accessory: "",
      remark: "",
      stitch: "",
      lining: "",
      type: "",
      fullness: "",
      measurement: "",
      height: "",
      width: "",
      panelHeight: 0,
      perPanelQuantity: 0,
      noOFPanel: 0,
      quantity: 0,
      price: "",
      discount: currentUser.discountPercentage,
      // installationCost: 0,
      installationType: "",
      cost: 0,
      liningCost: 0,
      stitchCost: 0,
    },

    validate: (data) => {
      let errors = {};

      if (!data.roomType) {
        errors.roomType = "Required";
      }
      if (!data.window) {
        errors.window = "Required";
      }
      if (!data.catalogue) {
        errors.catalogue = "Required";
      }
      if (!data.fabric) {
        errors.fabric = "Required";
      }
      // if (!data.accessory) {
      //   errors.accessory = "Required";
      // }

      if (!data.stitch) {
        errors.stitch = "Required";
      }
      if (!data.lining) {
        errors.lining = "Required";
      }
      if (!data.type) {
        errors.type = "Required";
      }
      if (!data.height) {
        errors.height = "Required";
      }
      if (!data.width) {
        errors.width = "Required";
      }
      if (!data.fullness) {
        errors.fullness = "Required";
      }
      if (!data.measurement) {
        errors.measurement = "Required";
      }
      // if (!data.installationCost) {
      //   errors.installationCost = "Required";
      // }
      if (!data.installationType) {
        errors.installationType = "Required";
      }
      // if (!data.stitchCost) {
      //   errors.stitchCost = "Required";
      // }
      console.log("Errors", errors);
      return errors;
    },
    onSubmit: (data) => {
      const val = {
        ...data,
        installationCost: installPrice,
        stitchCost: stitchPrice,
      };
      console.log("tableData :", val);
      setTableValues([...tableValues, val]);
      formik.resetForm();
      setFabPrice(0);
      setInstallPrice("0");
      setStitchPrice("0");
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const roomTableFieldOptions = [
    "Foyer",
    "G F L V H",
    "Mezznine F L V H",
    "1st F L V H",
    "2nd F L V H",
    "Formal L V H",
    "Informal L V H",
    "D N H",
    "P B R",
    "M B R",
    "S B R",
    "D B R",
    "K B R 1",
    "K B R 2",
    "Home Theatre",
    "GYM Room",
    "Bar",
    "Office",
    "G B R",
    "Kitchen",
    "Party Room",
    "Conference Room",
    "Gazebo Room",
    "Steam Room",
    "Stairs",
    "Cabin",
    "Corridor",
    "Suits Room",
    "Luxe Room",
    "Dressing Room",
    "Board Room",
    "Electric Room",
    "H R Room",
    "Manager Room",
    "Store Room",
  ];

  const windowTableFieldOptions = [
    "W-1",
    "W-2",
    "W-3",
    "W-4",
    "W-5",
    "W-6",
    "W-7",
    "W-8",
    "W-9",
    "W-10",
    "W-11",
    "W-12",
    "W-13",
    "W-14",
    "W-15",
    "W-16",
    "W-17",
    "W-18",
    "W-19",
    "W-20",
    "W-21",
    "W-22",
    "W-23",
    "W-24",
    "W-25",
    "W-26",
    "W-27",
    "W-28",
    "W-29",
    "W-30",
    "W-31",
    "W-32",
    "W-33",
    "W-34",
    "W-35",
    "W-36",
    "W-37",
    "W-38",
    "W-39",
    "W-40",
    "W-41",
    "W-42",
    "W-43",
    "W-44",
    "W-45",
    "W-46",
    "W-47",
    "W-48",
    "W-49",
    "W-50",
  ];
  const accessoryTableFieldOptions = ["M track", "Soundless", "SS rod"];
  const stitchTableFieldOptions = [
    "Eyelet",
    "Pleated",
    "Eliza",
    "Roman Blinds",
    "Blind System",
  ];
  const liningTableFieldOptions = [
    "None",
    "Liva Dimout",
    "Dimout",
    "Satin",
    "3 Pass Blackout",
  ];
  const installationType = ["In Bangalore", "Outside Bangalore"];
  const typeTableFieldOptions = ["Main", "Shear"];
  const fullnessTableFieldOptions = ["200", "250"];
  const measurementTableFieldOptions = ["in", "cm", "mm"];

  useEffect(() => {
    getCatalogues()
      .then((data) => {
        return data.allCatalogues.map((catalogue) => catalogue);
      })
      .then((catalogueNames) => {
        setcatalogueTableFieldOptions(catalogueNames);
      });
    getFabric();
  }, []);
  const getFabric = async () => {
    getFabrics()
      .then((data) => {
        setAllFabrics(data.allFabrics);
        return data.allFabrics.map((fabric) => fabric);
      })
      .then((fabricNames) => {
        setFabricTableFieldOptions(fabricNames);
      });
  };
  const getFabricunderCatalogue = async (id) => {
    getCatalogueDetails(id)
      .then((data) => {
        setAllFabrics(data.fabricAffiliation);
        return data.fabricAffiliation.map((fabric) => fabric);
      })
      .then((fabricNames) => {
        setFabricTableFieldOptions(fabricNames);
        // if (fabricNames.length === 0) {
        //   getFabric();
        // } else {
        //   setFabricTableFieldOptions(fabricNames);
        // }
      });
  };
  const getStitchingPrice = async (val) => {
    const param = `mainType=Stitching&subType=${val}`;
    getMtmDetails(param)
      .then((res) => {
        console.log("mtm Details", res);
        setStitchPrice(res[0]?.price);
        // formik.values.stitchCost = res[0]?.price;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getInstallationPrice = async (val) => {
    const param = `mainType=Installation&subType=${val}`;
    getMtmDetails(param)
      .then((res) => {
        console.log("mtm Details", res[0]?.price);
        setInstallPrice(res[0]?.price);
        // formik.values.installationCost = res[0]?.price;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const calculateStitchCost = (calculateSQT, calculateRFT) => {
    if (formik.values.stitch === "Eyelet") {
      return 150 * formik.values.noOFPanel;
    } else if (formik.values.stitch === "Pleated") {
      return 245 * formik.values.noOFPanel;
    } else if (formik.values.stitch === "Eliza") {
      return 245 * formik.values.noOFPanel;
    } else if (formik.values.stitch === "Roman Blinds") {
      return Math.round(
        (165 * (formik.values.height * formik.values.width)) / 144
      );
    } else if (formik.values.stitch === "Blind System") {
      return Math.round(365 * (formik.values.width / 12));
    } else {
      return 0;
    }
  };
  const roundValue = (value, digit = 2) => {
    if (digit === 3) {
      return Math.round(value * 1000 + Number.EPSILON) / 1000;
    } else {
      return Math.round(value * 100 + Number.EPSILON) / 100;
    }
  };
  const Panels = (fullness, width) => {
    if (fullness === "250") {
      return Math.ceil(width / 20);
    } else if (fullness === "200") {
      return Math.ceil(width / 25);
    } else {
      return 0;
    }
  };
  const perPanelQuantity = (perPanelHeight) => {
    const wastage = Number(perPanelHeight * 0.15).toFixed(2);
    const PPQ = parseFloat(wastage) + perPanelHeight;

    return roundValue(PPQ, 3);
  };
  const panelHeight = (height) => {
    return roundValue(height / 39.26 + 0.35, 3);
  };

  const calculateInstallationCost = (values, rowIndex) => {
    if (values === "In Bangalore") {
      return 750;
    } else {
      return 1250;
    }
  };
  const calculateLiningCost = (values, quantity) => {
    if (values === "None") {
      return 0;
    } else if (values === "Liva Dimout") {
      return 135 * quantity;
    } else if (values === "Dimout") {
      return 135 * quantity;
    } else if (values === "Satin") {
      return 85 * quantity;
    } else if (values === "3 Pass Blackout") {
      return 240 * quantity;
    } else {
      return 0;
    }
  };
  formik.values.price = fabPrice;

  // useEffect(() => {
  // formik.values.stitchCost = calculateStitchCost();
  if (formik.values.stitch === "Roman Blinds") {
    // return Math.round(
    //   (stitchPrice * (formik.values.height * formik.values.width)) / 144
    // );
    formik.values.stitchCost = Math.round(
      (stitchPrice * (formik.values.height * formik.values.width)) / 144
    );
  } else if (formik.values.stitch === "Blind System") {
    // return Math.round(fabPrice * (formik.values.width / 12));
    formik.values.stitchCost = Math.round(
      stitchPrice * (formik.values.width / 12)
    );
  } else {
    formik.values.stitchCost = Number(stitchPrice * formik.values.noOFPanel);
  }
  formik.values.liningCost = calculateLiningCost(
    formik.values.lining,
    formik.values.perPanelQuantity
  );
  formik.values.cost = (
    Number(
      Number(installPrice) +
        formik.values.stitchCost +
        formik.values.liningCost +
        fabPrice
    ) *
    ((100 - formik.values.discount) / 100)
  ).toFixed(2);
  console.log("total cost: ", formik.values.cost);
  formik.values.price = fabPrice?.toFixed(2);
  formik.values.liningCost = Number(formik.values.liningCost)?.toFixed(2);
  formik.values.panelHeight = Number(formik.values.panelHeight)?.toFixed(2);
  formik.values.perPanelQuantity = Number(
    formik.values.perPanelQuantity
  )?.toFixed(2);
  formik.values.quantity = Number(formik.values.quantity)?.toFixed(2);
  formik.values.stitchCost = Number(formik.values.stitchCost).toFixed(2);

  // }, [formik.values.height, formik.values.width]);

  // console.log(formik.values);
  const getFabPrice = (id) => {
    getFabricDetails(id).then((res) => {
      console.log(
        "res",
        res[usertype.concat("CutLengthPrice")],
        usertype.concat("CutLengthPrice"),
        res[usertype.concat("RollLengthPrice")]
      );

      if (formik.values.quantity < 50) {
        setFabPrice(
          res[usertype.concat("CutLengthPrice")] * formik.values.quantity
        );
        formik.values.price =
          res[usertype.concat("CutLengthPrice")] * formik.values.quantity;
      } else {
        setFabPrice(
          res[usertype.concat("RollLengthPrice")] * formik.values.quantity
        );
        formik.values.price =
          res[usertype.concat("RollLengthPrice")] * formik.values.quantity;
      }
    });
  };

  function getTotal() {
    var Total = 0;
    if (tableValues.length > 0) {
      for (var i = 0; i < tableValues.length; i++) {
        Total = Total + parseFloat(tableValues[i].cost);
      }
    }
    return Total;
  }

  <div className=" container flex mx-5">
    {window.localStorage.getItem("userType") === "architect" ? (
      <div
        className="field-checkbox my-5"
        style={{ fontSize: 18, height: "2rem" }}
      >
        <Checkbox
          inputId="binary"
          checked={checked}
          onChange={(e) => setChecked(e.checked)}
        />
        <label htmlFor="binary">Accessories</label>
      </div>
    ) : (
      ""
    )}
  </div>;

  const mtmForm = (
    <div className="flex border-round surface-200 p-3 m-3">
      <div className="w-8   font-bold p-3 flex align-items-center justify-content-center">
        <div className="inputTablex">
          <div className="flex flex-wrap">
            <div className="my-2 mr-3">
              <label htmlFor="name">
                Room <span style={{ color: "red" }}>*</span>
              </label>
              <div>
                <Dropdown
                  id="roomType"
                  style={{ width: "15rem" }}
                  name="roomType"
                  value={formik.values.roomType}
                  options={roomTableFieldOptions}
                  onChange={formik.handleChange}
                  placeholder="Select a Room"
                />
                <div>{getFormErrorMessage("roomType")}</div>
              </div>
            </div>
            <div className="my-2 mr-3">
              <div>
                <label htmlFor="name">
                  Window <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <Dropdown
                id="window"
                name="window"
                style={{ width: "15rem" }}
                value={formik.values["window"]}
                options={windowTableFieldOptions}
                onChange={formik.handleChange}
                placeholder="Select a Window"
              />
              <div>{getFormErrorMessage("window")}</div>
            </div>
            <div className="my-2 mr-3">
              <div>
                <label htmlFor="name">
                  Catalogue <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <Dropdown
                value={formik.values.catalogue}
                id="catalogue"
                style={{ width: "15rem" }}
                name="catalogue"
                optionLabel="name"
                options={catalogueTableFieldOptions}
                optionValue="_id"
                // onChange={formik.handleChange}
                onChange={(e) => {
                  formik.handleChange(e);
                  getFabricunderCatalogue(e.target.value);
                }}
                placeholder="Select a Catalogue"
              />
              <div>{getFormErrorMessage("catalogue")}</div>
            </div>
            <div className="my-2 mr-3">
              <div>
                <label htmlFor="name">
                  Fabric <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <Dropdown
                id="fabric"
                style={{ width: "15rem" }}
                name="fabric"
                value={formik.values.fabric}
                optionLabel="name"
                options={fabricTableFieldOptions}
                optionValue="_id"
                onChange={(e) => {
                  formik.handleChange(e);
                  getFabPrice(e.target.value);
                }}
                placeholder="Select a Fabric"
              />
              <div>{getFormErrorMessage("fabric")}</div>
            </div>
            {window.localStorage.getItem("accessories") === "Yes" ? (
              <div className="my-2 mr-3">
                <div>
                  <label htmlFor="name">
                    Accessory <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <Dropdown
                  id="accessory"
                  style={{ width: "15rem" }}
                  name="accessory"
                  value={formik.values["accessory"]}
                  options={accessoryTableFieldOptions}
                  onChange={formik.handleChange}
                  placeholder="Select an Accessory"
                />
                <div>{getFormErrorMessage("accessory")}</div>
              </div>
            ) : null}
            <div className="my-2 mr-3">
              <div>
                <label htmlFor="name">
                  Stitch <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <Dropdown
                id="stitch"
                name="stitch"
                style={{ width: "15rem" }}
                value={formik.values["stitch"]}
                options={stitchTableFieldOptions}
                onChange={(e) => {
                  formik.handleChange(e);
                  getStitchingPrice(e.target.value);
                }}
                placeholder="Select a Stitch"
              />
              <div>{getFormErrorMessage("stitch")}</div>
            </div>
            <div className="my-2 mr-3">
              <div>
                <label htmlFor="name">
                  Lining <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <Dropdown
                id="lining"
                name="lining"
                style={{ width: "15rem" }}
                value={formik.values["lining"]}
                options={liningTableFieldOptions}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.values.liningCost = calculateLiningCost(
                    e.target.value,
                    formik.values.quantity
                  );
                }}
                placeholder="Select a Lining"
              />
              <div>{getFormErrorMessage("lining")}</div>
            </div>
            <div className="my-2 mr-3">
              <div>
                <label htmlFor="name">
                  Type <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <Dropdown
                id="type"
                name="type"
                style={{ width: "15rem" }}
                value={formik.values["type"]}
                options={typeTableFieldOptions}
                onChange={formik.handleChange}
                placeholder="Select a Type"
              />
              <div>{getFormErrorMessage("type")}</div>
            </div>
            <div className="my-2 mr-3">
              <div>
                <label htmlFor="name">
                  Installation Type <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <Dropdown
                style={{ width: "15rem" }}
                id="installationType"
                value={formik.values.installationType}
                name="installationType"
                options={installationType}
                placeholder="Installation Type"
                onChange={(e) => {
                  // formik.values.installationCost = calculateInstallationCost(
                  //   e.target.value
                  // );
                  getInstallationPrice(e.target.value);
                  formik.handleChange(e);
                }}
              />
              <div>{getFormErrorMessage("installationType")}</div>
            </div>
            <div className="my-2 mr-3">
              <div>
                <label htmlFor="name">
                  Fullness <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <Dropdown
                id="fullness"
                style={{ width: "15rem" }}
                name="fullness"
                value={formik.values.fullness}
                options={fullnessTableFieldOptions}
                onChange={(e) => {
                  formik.values.noOFPanel = Math.round(
                    Panels(e.target.value, formik.values.width)
                  );
                  formik.handleChange(e);
                }}
                placeholder="Select Fullness"
              />
              <div>{getFormErrorMessage("fullness")}</div>
            </div>
            <div className="my-2 mr-3">
              <div>
                <label htmlFor="name">
                  Measurement Unit <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <Dropdown
                id="measurement"
                name="measurement"
                style={{ width: "15rem" }}
                value={formik.values["measurement"]}
                options={measurementTableFieldOptions}
                onChange={formik.handleChange}
                placeholder="Select Measurement Type"
              />
              <div>{getFormErrorMessage("measurement")}</div>
            </div>
            <div className="my-2 mr-3">
              <div>
                <label htmlFor="name">Remark</label>
              </div>
              <InputText
                id="remark"
                name="remark"
                style={{ width: "15rem" }}
                placeholder="Remarks (Optional)"
                value={formik.values["remark"]}
                onChange={formik.handleChange}
              />
              {getFormErrorMessage("remark")}
            </div>
            <div className="my-2 mr-3">
              <div>
                <label htmlFor="name">
                  Window Height <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <InputText
                id="height"
                placeholder="Height"
                name="height"
                value={formik.values.height}
                style={{ width: "15rem" }}
                onChange={(e) => {
                  formik.values.panelHeight = panelHeight(e.target.value);
                  formik.values.perPanelQuantity = perPanelQuantity(
                    formik.values.panelHeight
                  ).toFixed(2);
                  formik.values.quantity =
                    formik.values.noOFPanel * formik.values.perPanelQuantity;
                  formik.handleChange(e);
                  getFabPrice(formik.values.fabric);
                }}
              />
              <div>{getFormErrorMessage("height")}</div>
            </div>
            <div className="my-2 mr-3">
              <div>
                <label htmlFor="name">
                  Window Width <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <InputText
                id="width"
                style={{ width: "15rem" }}
                name="width"
                placeholder="Width"
                value={formik.values.width}
                onChange={(e) => {
                  formik.values.noOFPanel = Math.round(
                    Panels(formik.values.fullness, e.target.value)
                  ).toFixed(2);
                  formik.values.quantity =
                    formik.values.noOFPanel * formik.values.perPanelQuantity;
                  formik.handleChange(e);
                  getFabPrice(formik.values.fabric);
                }}
              />
              <div>{getFormErrorMessage("width")}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-5 font-bold  p-2 flex align-items-center justify-content-center">
        <div>
          <div className="flex">
            <div className="m-2">
              <div>Panel Height</div>
              <InputText
                disabled
                value={formik.values.panelHeight}
                style={{ width: "8rem" }}
                id="panelHeight"
                name="panelHeight"
                onChange={formik.handleChange}
              />
            </div>
            <div className="m-2">
              <div>Per PanelQuantity</div>
              <InputText
                disabled
                style={{ width: "8rem" }}
                id="perPanelQuantity"
                value={formik.values.perPanelQuantity}
                name="perPanelQuantity"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
              />
            </div>
            <div className="m-2">
              <div>No of Panels</div>
              <InputText
                disabled
                value={Number(formik.values.noOFPanel).toFixed(0)}
                style={{ width: "8rem" }}
                id="noOFPanel"
                name="noOFPanel"
                onChange={(e) => {
                  formik.values.noOFPanel(Math.round(e.target.value));
                }}
              />
            </div>
          </div>
          <div className="flex">
            <div className="m-2">
              <div>Quantity</div>
              <InputText
                disabled
                value={Number(
                  formik.values.noOFPanel * formik.values.perPanelQuantity
                ).toFixed(2)}
                style={{ width: "8rem" }}
                id="quantity"
                name="quantity"
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.values.liningCost = calculateLiningCost(
                    formik.values.lining,
                    e.target.value
                  );
                }}
              />
            </div>
            <div className="m-2">
              <div>Price</div>
              <InputText
                disabled
                value={formik.values.price}
                style={{ width: "8rem" }}
                id="price"
                name="price"
                onChange={formik.handleChange}
              />
            </div>
            <div className="m-2">
              <div>Installation Cost</div>
              <InputText
                disabled
                value={installPrice}
                style={{ width: "8rem" }}
                id="installationCost"
                name="installationCost"
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="flex">
            <div className="m-2">
              <div>Stitching Cost</div>
              <InputText
                disabled
                value={formik.values.stitchCost}
                style={{ width: "8rem" }}
                id="liningCost"
                name="liningCost"
                onChange={formik.handleChange}
              />
            </div>
            <div className="m-2">
              <div>Lining Cost</div>
              <InputText
                disabled
                value={formik.values.liningCost}
                style={{ width: "8rem" }}
                id="liningCost"
                name="liningCost"
                onChange={formik.handleChange}
              />
            </div>

            <div className="m-2">
              <div>Discount (%)</div>
              <InputText
                disabled
                style={{ width: "8rem" }}
                id="discount"
                name="discount"
                onChange={formik.handleChange}
                value={formik.values.discount}
              />
            </div>
          </div>
          <div className="m-2">
            <div>Total Cost (Rs)</div>
            <InputText
              disabled
              value={formik.values.cost}
              style={{ width: "8rem" }}
              id="cost"
              name="cost"
              onChange={formik.handleChange}
            />
          </div>
          <div className="m-2 text-center ">
            <Button className="w-6" type="submit" label="Add to List"></Button>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <MenuNavigator />
      <Toast ref={toast} />
      <Divider align="center">
        <h2 className="text-center">Made to Measure Order</h2>
      </Divider>
      <form className="mx-4" onSubmit={formik.handleSubmit}>
        <div className="datatable-templating-demo" style={{ fontSize: 15 }}>
          <div className="card">
            {mtmForm}
            <Divider align="center">
              <h2 className="p-text-center">Made to Measure Data</h2>
            </Divider>
            <DataTable
              scrollable
              className="text-xs"
              value={tableValues}
              showGridlines
            >
              <Column field="id" header="Id" />

              <Column field="roomType" header="Room"></Column>
              <Column field="window" header="Window" />

              <Column field="accessory" header="Accessory" />

              <Column field="stitch" header="Stitch" />

              <Column header="Lining" field="lining" />

              <Column header="Type" field="type" />
              <Column header="Installation Type" field="installationType" />

              <Column header="Fullness" field="fullness" />

              <Column header="Window Height" field="height" />
              <Column header="Window Width" field="width" />
              <Column header="Per Panel" field="panelHeight" />
              <Column header="Per Panel Quantity" field="perPanelQuantity" />
              <Column header="No of Panels" field="noOFPanel" />

              <Column header="Discount" field="discount" />

              <Column header="Total Cost" field="cost" />
            </DataTable>
          </div>
        </div>
        <div>
          <div
            style={{
              marginTop: "40px",
              textAlign: "center",
              fontSize: "25px",
              fontWeight: "700",
            }}
          >
            Total Cost : {getTotal().toFixed(2)}Rs
          </div>
        </div>
      </form>
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ margin: "20px", width: "15%", background: "#287F80" }}
          onClick={(e) => {
            dispatch(addMadeToMeasureToCart(tableValues));
            showInfo();
          }}
          label="Add to Cart"
          className="p-block text-center"
        ></Button>
        <Button
          style={{ margin: "20px", width: "15%", background: "#287F80" }}
          onClick={(e) => {
            navigate("/MTMaddress", {
              state: {
                stored: tableValues,
                totalCost: getTotal().toFixed(2),
                currentUser,
              },
            });
          }}
          label="Get Quotation"
          className="p-block text-center"
        ></Button>
      </div>
    </div>
  );
};

export default PlaceMadeToMeasureOrder;
