import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import MenuNavigator from "../../../../components/MenuNavigator";
import { postTrackingId } from "../../../../api/api";
import { Toast } from "primereact/toast";

function FabricOrderdetail() {
  const toast = useRef(null);
  const { state } = useLocation();
  const [trackingId, setTrackingId] = useState("");
  const navigate = useNavigate();
  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Success!",
      detail: "Tracking ID Updated, you will redirect to pending orders page",
    });
  };

  console.log(state);
  const fabricDetails = { ...state.fabric, qty: state.qty };
  const headerTemplate = (
    <div
      style={{
        backgroundColor: "#B3E5FC",
        color: "#23547B",
        padding: ".25em 2rem",
        borderRadius: "3px",
        fontWeight: "700",
        letterSpacing: "2px",
        fontSize: "25px",
      }}
    >
      <div>
        Order Placed at : {new Date(state.createdAt).toString().slice(0, 15)} |
        Time:{new Date(state.createdAt).toString().slice(16, 24)}{" "}
      </div>
      <div>Tracking ID :{state.trackingId} </div>
    </div>
  );
  
  return (
    <div>
      <MenuNavigator />
      <Divider align="center">
        <Toast ref={toast} />
        <h2>Fabric Order Details</h2>
        {/* <h2 className="p-text-center">Order Placed at : {(new Date(state.createdAt).toString())} </h2> */}
      </Divider>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        {headerTemplate}
      </div>
      <DataTable showGridlines value={[state.fabric]} responsiveLayout="scroll">
        <Column header="Sr. No." body={1} exportable></Column>
        <Column field="HSN" header="HSN"></Column>
        <Column field="name" header="Fabric"></Column>

        <Column field="color" header="Color"></Column>

        <Column field="composition" header="Composition"></Column>
        <Column field="quality" header="quality"></Column>

        <Column field="patternRepeat" header="Pattern Repeat"></Column>
        <Column field="fabricType" header="Fabric Type"></Column>
        <Column field="fabricCare" header="Fabric Care"></Column>
        <Column field="fabricWidth" header="Fabric Width"></Column>
        <Column field="collectionName" header="Collection Name"></Column>
      </DataTable>
      <Panel>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>
            <h3 style={{ textAlign: "center" }}>
              <h2>Delivery Address</h2>
            </h3>
            <h3>
              <strong>Company Name: </strong> {state.companyName}
            </h3>
            <h3>
              <strong>Address: </strong> {state.deliveryAddress}
            </h3>
            <h3>
              <strong> State: </strong> {state.state}
            </h3>
            <h3>
              <strong> Pincode: </strong> {state.pincode}
            </h3>
            <h3>
              <strong>Contact: </strong>
              {state.contactNumber}
            </h3>
            <h3 style={{ display: "flex" }}>
              <strong>Transport:&nbsp; </strong>
              {state.modeOfTransport === "Any Other" ? (
                <div> {state.optionalTransport}</div>
              ) : (
                <div> {state.modeOfTransport}</div>
              )}
            </h3>
          </div>
          <div>
            <h3 style={{ textAlign: "center", marginTop: "60px" }}>
              <h2>Payment Information</h2>
            </h3>
            <h3>
              <strong style={{ color: "green" }}> Amount : </strong>
              {state.totalAmount}Rs
            </h3>
            <h3 style={{ display: "flex" }}>
              <strong style={{ color: "green" }}> Order Type : </strong>
              {state.orderType === "paynow" ? (
                <div> Pay Now</div>
              ) : (
                <div>{state.orderType}</div>
              )}
            </h3>
            <h3 style={{ display: "flex" }}>
              <strong style={{ color: "green" }}> Payment Status : </strong>
              {state.paymentStatus === "done" ? <div> Paid</div> : <div></div>}
            </h3>
            <h3 style={{ display: "flex" }}>
              <strong style={{ color: "green" }}> Payment ID : </strong>
              {state.paymentId?.length > 1 ? (
                <div> {state.paymentId}</div>
              ) : (
                <div></div>
              )}
            </h3>
          </div>
        </div>
        {(window.localStorage.getItem("userType") === "admin"||window.localStorage.getItem("userType") === "sales"||window.localStorage.getItem("userType") === "accounts")&& state.orderStatus!=="shipped" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "30px",
            }}
          >
            <InputText
              style={{ marginRight: "20px" }}
              placeholder="Enter Tracking ID"
              value={trackingId}
              onChange={(e) => setTrackingId(e.target.value)}
            />
            <Button
              onClick={() => {
                postTrackingId(state._id, { trackingId: trackingId }).then(
                  (res) => {
                    if (res.trackingId) {
                      showSuccess();
                      setTimeout(()=>{
                        navigate("/pendingOrders")
                    },3000)
                    }
                  }
                );
              }}
            >
              Mark as Shipped
            </Button>
          </div>
        )}
      </Panel>
    </div>
  );
}

export default FabricOrderdetail;
