import React, { useState, useContext, useEffect } from "react";
import { Knob } from "primereact/knob";
// import { Button } from "primereact/button";
import { Menubar } from "primereact/menubar";
import { InputText } from "primereact/inputtext";
import logoSRC from "../assets/logo.jpeg"; //../../assets/logo.jpeg
import { useNavigate, useLocation } from "react-router";
import { CheckIsAdmin, CheckIsAdmin2 } from "../guards/IsAdmin";
import { getProfileDetail } from "../api/api";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Badge from "@mui/material/Badge";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AddIcon from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./MenuNavigator.css";
import { Image } from "@mui/icons-material";
import { useSelector } from "react-redux";
const pages = ["Dashboard", "Orders", "Products", "Pricing", "Profile", "Cart"];
const settings = ["Profile", "Orders", "Dashboard", "Logout"];
const MenuNavigator = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const rolls = window.localStorage.getItem("rolls");
  useEffect(() => {
    getProfileDetail().then((data) => {
      window.localStorage.setItem(
        "expiredOrders",
        JSON.stringify(data.expired_credit_period_orders)
      );
      setUserData(data);
      window.localStorage.setItem("rolls", data?.roles);
      window.localStorage.setItem("accessories", data?.accessory);
    });
  }, []);
  console.log("rolls", rolls);
  const [anchorElNav, setAnchorElNav] = useState(null);
  // (React.useState < null) | (HTMLElement > null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [showProducts, setShowProducts] = useState(false);
  // (React.useState < null) | (HTMLElement > null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [anchorE3, setAnchorE3] = React.useState(null);

  const fabrics = useSelector(
    (state) => state.persistedReducer.cartReducer.fabrics
  );
  const catelogues = useSelector(
    (state) => state.persistedReducer.cartReducer.catelogues
  );
  const madeToMeasure = useSelector(
    (state) => state.persistedReducer.cartReducer.madeToMeasure
  );
  const cartItem =
    Number(fabrics.length) +
    Number(catelogues.length) +
    Number(madeToMeasure.length);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorE2);
  const open3 = Boolean(anchorE3);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClick3 = (event) => {
    setAnchorE3(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose2 = () => {
    setAnchorE2(null);
  };
  const handleClose3 = () => {
    setAnchorE3(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  console.log("cart", cartItem);
  const menuOptions_Admin = [
    {
      label: "Dashboard",
      icon: "pi pi-fw pi-user",
      command: () => {
        navigate("/adminDashboard");
      },
    },

    {
      label: "Orders",
      icon: "pi pi-fw pi-file",
      items: [
        {
          label: "All Orders",
          icon: "pi pi-fw pi-plus",
          command: () => {
            navigate("/adminOrders");
          },
        },

        {
          label: "Fullfilled",
          icon: "pi pi-fw pi-plus",
          command: () => {
            navigate("/fullfilledOrders");
          },
        },
        {
          label: "Pending",
          icon: "pi pi-fw pi-plus",
          command: () => {
            navigate("/pendingOrders");
          },
        },

        {
          separator: true,
        },
      ],
    },

    {
      label: "Products",
      icon: "pi pi-fw pi-plus",
      items: [
        {
          label: "Made To Measure",
          icon: "pi pi-fw pi-plus",
          command: () => {
            navigate("/adminMTMO");
          },
        },
        {
          label: "Catalogue",
          icon: "pi pi-fw pi-bookmark",
          command: () => {
            navigate("/catalogues");
          },
        },
        {
          label: "Fabrics",
          icon: "pi pi-fw pi-caret-down",
          command: () => {
            navigate("/fabrics");
          },
        },
      ],
    },

    {
      label: "Pricing",
      icon: "pi pi-fw pi-dollar",
      command: () => {
        navigate("/pricingAdmin");
      },
    },
    {
      label: "Users",
      icon: "pi pi-fw pi-user",
      command: () => {
        navigate("/users");
      },
    },
    {
      label: "Settings",
      icon: "pi pi-fw pi-user",
      command: () => {
        navigate("/settings");
      },
    },
  ];

  const menuOptions_B2B = [
    {
      label: "Dashboard",
      icon: "pi pi-fw pi-user",
      command: () => {
        navigate("/userDashboard");
      },
    },
    {
      label: "Orders",
      icon: "pi pi-fw pi-file",
      items: [
        {
          label: "My Orders",
          icon: "pi pi-fw pi-plus",
          command: () => {
            navigate("/myOrders");
          },
        },
        {
          label: "Fullfilled",
          icon: "pi pi-fw pi-plus",
          command: () => {
            navigate("/myFullfilledOrders");
          },
        },
        {
          label: "Pending",
          icon: "pi pi-fw pi-plus",
          command: () => {
            navigate("/myPendingOrders");
          },
        },
        {
          label: "Payment Pending Orders",
          icon: "pi pi-fw pi-plus",
          command: () => {
            navigate("/expiredOrders");
          },
        },
      ],
    },
    {
      label: "Products",
      icon: "pi pi-fw pi-plus",
      items: [
        {
          label: "Made To Measure",
          icon: "pi pi-fw pi-plus",
          command: () => {
            navigate("/madetomeasure");
          },
        },
        {
          label: "Catalogue",
          icon: "pi pi-fw pi-bookmark",
          command: () => {
            navigate("/catelogue/all");
          },
        },
        {
          label: "Fabrics",
          icon: "pi pi-fw pi-caret-down",
          command: () => {
            navigate("/fabric/all");
          },
        },
      ],
    },

    {
      label: "Pricing",
      icon: "pi pi-fw pi-dollar",
      command: () => {
        navigate("/pricingUsers");
      },
    },
    {
      label: "Profile",
      icon: "pi pi-fw pi-user",
      command: () => {
        navigate("/userprofile");
      },
    },
    {
      label: "Cart",
      icon: "pi pi-fw pi-shopping-cart",
      // icon: `<Badge value="2" severity="success"></Badge>`,
      command: () => {
        navigate("/cart");
      },
    },
  ];

  const startAdmin = (
    <img
      onClick={() => navigate("/adminDashboard")}
      alt="Card"
      height="80"
      className="p-mr-2"
      src={logoSRC}
    />
  );
  const startCustomer = (
    <img
      onClick={() => navigate("/userDashboard")}
      alt="Card"
      height="80"
      className="p-mr-2"
      src={logoSRC}
    />
  );
  // const end = <InputText placeholder="Search" type="text" />;
  const OrderOptions = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <Button
            onClick={() => {
              CheckIsAdmin2() ? navigate("/adminOrders") : navigate("/myOrders");
            }}
            sx={{
              mx: 0.5,
              color: "#495057",
              display: "flex",
              alignItems: "center",
            }}
          >
            <AddIcon style={{ marginRight: 5 }} />
            {CheckIsAdmin2() ? "All Orders" : "My Order"}
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Button
            onClick={() => {
              CheckIsAdmin2()
                ? navigate("/fullfilledOrders")
                : navigate("/myFullfilledOrders");
            }}
            sx={{
              mx: 0.5,
              color: "#495057",
              display: "flex",
              alignItems: "center",
            }}
          >
            <AddIcon style={{ marginRight: 5 }} />
            Fullfilled
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Button
            onClick={() => {
              CheckIsAdmin2()
                ? navigate("/pendingOrders")
                : navigate("/myPendingOrders");
            }}
            sx={{
              mx: 0.5,
              color: "#495057",
              display: "flex",
              alignItems: "center",
            }}
          >
            <AddIcon style={{ marginRight: 5 }} />
            Pending
          </Button>
        </MenuItem>
        {CheckIsAdmin2() ? null : (
          <MenuItem onClick={handleClose}>
            <Button
              onClick={() => {
                navigate("/expiredOrders");
              }}
              sx={{
                mx: 0.5,
                color: "#495057",
                display: "flex",
                alignItems: "center",
              }}
            >
              <AddIcon style={{ marginRight: 5 }} />
              Payment Pending Orders
            </Button>
          </MenuItem>
        )}
      </Menu>
    );
  };
  const ProductOptions = () => {
    return (
      <Menu
        id="basic-menu2"
        anchorEl={anchorE2}
        open={open2}
        onClose={handleClose2}
        MenuListProps={{
          "aria-labelledby": "basic-button2",
        }}
      >
        {userData?.is_made_to_measure || CheckIsAdmin() ? (
          <MenuItem onClick={handleClose2}>
            <Button
              onClick={() => {
                CheckIsAdmin() ||
                window.localStorage.getItem("userType") === "accounts" ||
                window.localStorage.getItem("userType") === "sales"
                  ? navigate("/adminMTMO")
                  : navigate("/madetomeasure");
              }}
              sx={{
                mx: 0.5,
                color: "#495057",
                display: "flex",
                alignItems: "center",
              }}
            >
              <AddIcon style={{ marginRight: 5 }} />
              Made To Measure
            </Button>
          </MenuItem>
        ) : null}
        <MenuItem onClick={handleClose2}>
          <Button
            onClick={() => {
              CheckIsAdmin2()
                ? navigate("/catalogues")
                : navigate("/catelogue/all");
            }}
            sx={{
              mx: 0.5,
              color: "#495057",
              display: "flex",
              alignItems: "center",
            }}
          >
            <BookmarkAddIcon style={{ marginRight: 5 }} />
            Catalouges
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClose2}>
          <Button
            onClick={() => {
              CheckIsAdmin2() ? navigate("/fabrics") : navigate("/fabric/all");
            }}
            sx={{
              mx: 0.5,
              color: "#495057",
              display: "flex",
              alignItems: "center",
            }}
          >
            <BookmarkAddIcon style={{ marginRight: 5 }} />
            Fabrics
          </Button>
        </MenuItem>
      </Menu>
    );
  };
  const OrderProductOptions = () => {
    return (
      <Menu
        id="basic-menu3"
        anchorEl={anchorE3}
        open={open3}
        onClose={handleClose3}
        MenuListProps={{
          "aria-labelledby": "basic-button2",
        }}
      >
        <MenuItem onClick={handleClose3}>
          <Button
            onClick={() => {
              navigate("/catelogue/all");
            }}
            sx={{
              mx: 0.5,
              color: "#495057",
              display: "flex",
              alignItems: "center",
            }}
          >
            <BookmarkAddIcon style={{ marginRight: 5 }} />
           Order Catalouges
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClose3}>
          <Button
            onClick={() => {
              navigate("/fabric/all");
            }}
            sx={{
              mx: 0.5,
              color: "#495057",
              display: "flex",
              alignItems: "center",
            }}
          >
            <BookmarkAddIcon style={{ marginRight: 5 }} />
          Order  Fabrics
          </Button>
        </MenuItem>
      </Menu>
    );
  };
  const MenuOptions = () => {
    return (
      <Menu
        className="menu"
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        <MenuItem
          onClick={() => {
            // handleCloseNavMenu
            CheckIsAdmin()
              ? navigate("/adminDashboard")
              : navigate("/userDashboard");
          }}
        >
          <DashboardIcon style={{ marginRight: 7 }} />
          <Typography textAlign="center">Dashboard</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            // handleCloseNavMenu;
            CheckIsAdmin()
              ? navigate("/pricingAdmin")
              : navigate("/pricingUsers");
          }}
        >
          <AttachMoneyIcon style={{ marginRight: 7 }} />
          <Typography textAlign="center">Pricing</Typography>
        </MenuItem>
        <MenuItem
        // onClick={handleCloseNavMenu}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <InsertDriveFileIcon style={{ marginRight: 7 }} />
              <Typography textAlign="center">Order</Typography>
            </AccordionSummary>
            {CheckIsAdmin() ? (
              <AccordionDetails>
                <Button
                  onClick={() => navigate("/adminOrders")}
                  sx={{
                    my: 2,
                    mx: 0.5,
                    color: "#495057",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AddIcon style={{ marginRight: 5 }} />
                  All Order
                </Button>
                <Button
                  onClick={() => navigate("/fullfilledOrders")}
                  sx={{
                    my: 2,
                    mx: 0.5,
                    color: "#495057",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AddIcon style={{ marginRight: 5 }} />
                  Fullfilled
                </Button>
                <Button
                  onClick={() => navigate("/pendingOrders")}
                  sx={{
                    my: 2,
                    mx: 0.5,
                    color: "#495057",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AddIcon style={{ marginRight: 5 }} />
                  Pending
                </Button>
              </AccordionDetails>
            ) : (
              <AccordionDetails>
                <Button
                  onClick={() => navigate("/myOrders")}
                  sx={{
                    my: 2,
                    mx: 0.5,
                    color: "#495057",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AddIcon style={{ marginRight: 5 }} />
                  My Order
                </Button>
                <Button
                  onClick={() => navigate("/myFullfilledOrders")}
                  sx={{
                    my: 2,
                    mx: 0.5,
                    color: "#495057",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AddIcon style={{ marginRight: 5 }} />
                  Fullfilled
                </Button>
                <Button
                  onClick={() => navigate("/myPendingOrders")}
                  sx={{
                    my: 2,
                    mx: 0.5,
                    color: "#495057",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AddIcon style={{ marginRight: 5 }} />
                  Pending
                </Button>
                <Button
                  onClick={() => navigate("/expiredOrders")}
                  sx={{
                    my: 2,
                    mx: 0.5,
                    color: "#495057",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AddIcon style={{ marginRight: 5 }} />
                  Payment Pending Orders
                </Button>
              </AccordionDetails>
            )}
          </Accordion>
        </MenuItem>
        <MenuItem
        // onClick={handleCloseNavMenu}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <AddIcon style={{ marginRight: 7 }} />
              <Typography textAlign="center">Products</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Button
                onClick={() => {
                  CheckIsAdmin()
                    ? navigate("/adminMTMO")
                    : navigate("/madetomeasure");
                }}
                sx={{
                  mx: 0.5,
                  color: "#495057",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AddIcon style={{ marginRight: 5 }} />
                Made To Measure
              </Button>
              <Button
                onClick={() => {
                  CheckIsAdmin()
                    ? navigate("/catalogues")
                    : navigate("/catelogue/all");
                }}
                sx={{
                  mx: 0.5,
                  color: "#495057",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BookmarkAddIcon style={{ marginRight: 5 }} />
                Catalouges
              </Button>
              <Button
                onClick={() => {
                  CheckIsAdmin()
                    ? navigate("/fabrics")
                    : navigate("/fabric/all");
                }}
                sx={{
                  mx: 0.5,
                  color: "#495057",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BookmarkAddIcon style={{ marginRight: 5 }} />
                Fabrics
              </Button>
            </AccordionDetails>
          </Accordion>
        </MenuItem>
        {CheckIsAdmin() ? (
          <div>
            <MenuItem
              onClick={() => {
                // handleCloseNavMenu;
                navigate("/users");
              }}
            >
              <PersonIcon style={{ marginRight: 7 }} />
              <Typography textAlign="center">Users</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                // handleCloseNavMenu;
                navigate("/settings");
              }}
            >
              <SettingsIcon style={{ marginRight: 7 }} />
              <Typography textAlign="center">Settings</Typography>
            </MenuItem>
          </div>
        ) : (
          <div>
            <MenuItem
              onClick={() => {
                // handleCloseNavMenu;
                navigate("/userprofile");
              }}
            >
              <PersonIcon style={{ marginRight: 7 }} />
              <Typography textAlign="center">Profile</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                // handleCloseNavMenu;
                navigate("/cart");
              }}
            >
              <Badge badgeContent={cartItem} color="error">
                <ShoppingCartIcon style={{ marginRight: 5 }} />
              </Badge>
              <Typography textAlign="center">Cart</Typography>
            </MenuItem>
          </div>
        )}
      </Menu>
    );
  };

  return (
    <div>
      {/* <Menubar
        model={CheckIsAdmin() ? menuOptions_Admin : menuOptions_B2B}
        start={CheckIsAdmin() ? startAdmin : startCustomer}
        end={
          <Button
            style={{
              backgroundColor: "#287F80",
            }}
            label="Logout"
            onClick={() => {
              window.localStorage.clear();

              navigate("/login");
            }}
          />
        }
        //   end={end}
      /> */}
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#f8f9fa",
          border: "1px solid #dee2e6",
          padding: "10px 0",
          boxShadow: "none",
          borderRadius: "3px",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <img
              src={logoSRC}
              alt="Card"
              height="80"
              onClick={() => {
                CheckIsAdmin2()
                  ? navigate("/adminDashboard")
                  : navigate("/userDashboard");
              }}
            />

            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "flex",
                  md: "none",
                },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <MenuOptions />
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "none",
                  md: "flex",
                },
                marginLeft: 10,
              }}
            >
              <Button
                onClick={() => {
                  CheckIsAdmin2()
                    ? navigate("/adminDashboard")
                    : navigate("/userDashboard");
                }}
                sx={{
                  my: 2,
                  mx: 0.5,
                  color: "#495057",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <DashboardIcon style={{ marginRight: 5 }} />
                Dashboard
              </Button>

              {CheckIsAdmin() || rolls.includes("Pricing") || !rolls?.length ? (
                <Button
                  onClick={() => {
                    CheckIsAdmin2()
                      ?
                       navigate("/pricingAdmin")
                      : navigate("/pricingUsers");
                  }}
                  sx={{
                    my: 2,
                    mx: 0.5,
                    color: "#495057",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AttachMoneyIcon style={{ marginRight: 3 }} />
                  Pricing
                </Button>
              ) : null}
              {CheckIsAdmin() || rolls.includes("Orders") || !rolls?.length ? (
                <>
                  <Button
                    // onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      mx: 0.5,
                      color: "#495057",
                      display: "flex",
                      alignItems: "center",
                    }}
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <InsertDriveFileIcon style={{ marginRight: 3 }} />
                    Orders
                  </Button>
                  <OrderOptions />
                </>
              ) : null}

              {CheckIsAdmin() ||
              rolls.includes("Products") ||
              !rolls?.length ? (
                <>
                  <Button
                    sx={{
                      my: 2,
                      mx: 0.5,
                      color: "#495057",
                      display: "flex",
                      alignItems: "center",
                    }}
                    // onClick={() => setShowProducts(true)}
                    id="basic-button2"
                    aria-controls={open ? "basic-menu2" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick2}
                  >
                    <AddIcon style={{ marginRight: 3 }} />
                    Products
                  </Button>
                  <ProductOptions />
                </>
              ) : null}
               {(window.localStorage.getItem("userType")==="sales" || window.localStorage.getItem("userType")==="accounts") &&
              (rolls.includes("Products") ||
              !rolls?.length )? (
                <>
                  <Button
                    sx={{
                      my: 2,
                      mx: 0.5,
                      color: "#495057",
                      display: "flex",
                      alignItems: "center",
                    }}
                    // onClick={() => setShowProducts(true)}
                    id="basic-button2"
                    aria-controls={open ? "basic-menu3" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick3}
                  >
                    <AddIcon style={{ marginRight: 3 }} />
                   Order Products
                  </Button>
                  <OrderProductOptions />
                </>
              ) : null}

              {CheckIsAdmin() ? (
                <Button
                  onClick={() => {
                    navigate("/users");
                  }}
                  sx={{
                    my: 2,
                    mx: 0.5,
                    color: "#495057",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PersonIcon style={{ marginRight: 3 }} />
                  Users
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    navigate("/userprofile");
                  }}
                  sx={{
                    my: 2,
                    mx: 0.5,
                    color: "#495057",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PersonIcon style={{ marginRight: 3 }} />
                  Profile
                </Button>
              )}
              {CheckIsAdmin() ? (
                <Button
                  onClick={() => navigate("/settings")}
                  sx={{
                    my: 2,
                    mx: 0.5,
                    color: "#495057",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <SettingsIcon style={{ marginRight: 3 }} />
                  Settings
                </Button>
              ) : window.localStorage.getItem("userType") == "retailer" ||
                window.localStorage.getItem("userType") == "architect" ||
                window.localStorage.getItem("userType") == "channelPartner" ? (
                <Button
                  onClick={() => navigate("/cart")}
                  sx={{
                    my: 2,
                    mx: 0.5,
                    color: "#495057",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Badge badgeContent={cartItem} color="error">
                    <ShoppingCartIcon style={{ marginRight: 5 }} />
                  </Badge>
                  Cart
                </Button>
              ) : null}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Button
                onClick={() => {
                  window.localStorage.clear();

                  navigate("/login");
                }}
                sx={{
                  my: 2,
                  px: 3,
                  color: "#FFFFFF",
                  display: "block",
                  background: "#287F80",
                }}
              >
                Logout
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default MenuNavigator;
