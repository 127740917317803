import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import MenuNavigator from "../../../components/MenuNavigator";
import { Toast } from "primereact/toast";
import ImageUploadBtn from "../../../components/ImageUploadBtn";
import { addNewCatalogue, getAllFabrics } from "../../../api/api";
import { name } from "ejs";
import { useNavigate } from "react-router-dom";

const AddNewCatalogue = () => {
  const [catalogueId, setCatalogueId] = useState(null);
  const fabrics = ["Sheers", "Upholstry", "Contracts", "Curtains", "Lining"];

  const [formData, setFormData] = useState({});
  const [fabricTableFieldOptions, setfabricTableFieldOptions] = useState([]);
  const [catalogeId, setCatalogeId] = useState("");
  const [catalogueOptions, setCatalogueOptions] = useState([]);
  const [catalogeImage, setCatalogeImage] = useState("");
  const navigate = useNavigate();
  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Success!",
      detail: "Catalogue Added",
    });
  };
  const imageUploaded = () => {
    toast.current.show({
      severity: "success",
      summary: "Success!",
      detail: "Image Uploaded",
    });
  };
  const showError = () => {
    toast.current.show({
      severity: "error",
      summary: "Server Issue!",
      detail: "Server Issue, Please Try Again",
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      URL: "www.google.com",
      HSN: "",
      architectCataloguePrice: "",
      retailerCataloguePrice: "",
      channelPartnerCataloguePrice: "",
      fabricComposition: [],
      fabricAffiliation: [],
    },
    validate: (data) => {
      let errors = {};

      if (!data.name) {
        errors.name = "Name is required.";
      }
      if (!data.HSN) {
        errors.HSN = "This field is mandatory";
      }
      if (!data.architectCataloguePrice) {
        errors.architectCataloguePrice = "This field is mandatory";
      }
      if (!data.retailerCataloguePrice) {
        errors.retailerCataloguePrice = "This field is mandatory";
      }
      if (!data.channelPartnerCataloguePrice) {
        errors.channelPartnerCataloguePrice = "This field is mandatory";
      }
      if (!data.channelPartnerCataloguePrice) {
        errors.channelPartnerCataloguePrice = "This field is mandatory";
      }
      if (!data.fabricComposition.length) {
        errors.fabricComposition = "This field is mandatory";
      }
      // if (!data.fabricAffiliation.length) {
      //   errors.fabricAffiliation = "This field is mandatory";
      // }
      console.log(data);
      return errors;
    },
    onSubmit: (data) => {
      if (catalogeImage === "") {
        toast.current.show({
          severity: "error",
          summary: "Please select Catalogue image!",
          // detail: "Server Issue, Please Try Again",
        });
      } else {
        console.log("catData", data);
        let formData = new FormData();
        setFormData(data);
        data.fabricAffiliation.map((fabric) =>
          formData.append("fabricAffiliation", fabric)
        );
        data.fabricComposition.map((fabric) =>
          formData.append("fabricComposition", fabric)
        );
        formData.append("name", data.name);
        formData.append("URL", data.URL);
        formData.append("HSN", data.HSN);
        formData.append(
          "architectCataloguePrice",
          data.architectCataloguePrice
        );
        formData.append("retailerCataloguePrice", data.retailerCataloguePrice);
        formData.append(
          "channelPartnerCataloguePrice",
          data.channelPartnerCataloguePrice
        );
        formData.append("image", catalogeImage, catalogeImage.name);
        addNewCatalogue(formData).then((data) => {
          if (data.name) {
            console.log(data);
            // setCatalogueId(data._id);
            showSuccess();
            setTimeout(() => {
              navigate("/catalogues");
            }, 1000);
          } else {
            showError();
          }
        });
        formik.resetForm();
      }
    },
  });
  useEffect(() => {
    getAllFabrics()
      .then((data) => {
        // console.log("fabData", data.allFabrics);
        // setAllFabrics(data.allFabrics);
        return data.allFabrics.map((fabric) => fabric);
      })
      .then((fabricNames) => {
        console.log("fabName", fabricNames);
        setfabricTableFieldOptions(fabricNames);
      });
  }, []);
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const toast = useRef(null);

  return (
    <div>
      <MenuNavigator />
      <Divider align="center">
        <Toast ref={toast}></Toast>
        <h2 className="p-text-center">Add New Catalogue</h2>
      </Divider>
      <form onSubmit={formik.handleSubmit} className="p-fluid">
        <div
          className="p-fluid"
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <div style={{ width: "35%" }}>
            <div className="p-field">
              <label htmlFor="name">
                Catalogue Name<span style={{ color: "red" }}> *</span>
              </label>
              <InputText
                id="name"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
                className={classNames({ "p-error": isFormFieldValid("name") })}
              />
              {getFormErrorMessage("name")}
            </div>
            <div className="p-field">
              <label htmlFor="HSN">
                HSN<span style={{ color: "red" }}> *</span>
              </label>
              <InputText
                id="HSN"
                type="text"
                value={formik.values.HSN}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("HSN"),
                })}
              />
              {getFormErrorMessage("HSN")}
            </div>
            <div className="p-field">
              <label htmlFor="architectCataloguePrice">
                Architect Catalogue Pricing
                <span style={{ color: "red" }}> *</span>
              </label>
              <InputText
                id="architectCataloguePrice"
                type="number"
                value={formik.values.architectCataloguePrice}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("architectCataloguePrice"),
                })}
              />
              {getFormErrorMessage("architectCataloguePrice")}
            </div>
            <div className="p-field">
              <label htmlFor="fabricAffiliation">Fabric Affiliation</label>
              <MultiSelect
                // value={selectedCatalogues}
                options={fabricTableFieldOptions}
                // optionLabel="catalogueName"
                // id="catalogueAffiliation"
                // name="catalogueAffiliation"
                // value={formik.values.fabricAffiliation}
                className={classNames({
                  "p-error": isFormFieldValid("catalogueAffiliation"),
                })}
                display="chip"
                id="fabricAffiliation"
                name="fabricAffiliation"
                // style={{ width: "14rem" }}
                value={formik.values.fabricAffiliation}
                optionLabel="name"
                optionValue="_id"
                onChange={formik.handleChange}
                placeholder="Select a Fabric"
                // className={classNames({
                //   "p-error": isFormFieldValid("fabricAffiliation"),
                // })}
              />
              {getFormErrorMessage("fabricAffiliation")}
            </div>
          </div>

          <div style={{ width: "35%" }}>
            <div className="p-field">
              <label htmlFor="retailerCataloguePrice">
                Retailer Catalogue Pricing
                <span style={{ color: "red" }}> *</span>
              </label>
              <InputText
                id="retailerCataloguePrice"
                type="number"
                value={formik.values.retailerCataloguePrice}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("retailerCataloguePrice"),
                })}
              />
              {getFormErrorMessage("retailerCataloguePrice")}
            </div>
            <div className="p-field">
              <label htmlFor="channelPartnerCataloguePrice">
                Channel Partner Catalogue Pricing
                <span style={{ color: "red" }}> *</span>
              </label>
              <InputText
                id="channelPartnerCataloguePrice"
                type="number"
                value={formik.values.channelPartnerCataloguePrice}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("channelPartnerCataloguePrice"),
                })}
              />
              {getFormErrorMessage("channelPartnerCataloguePrice")}
            </div>
            <div className="p-field">
              <label htmlFor="fabricComposition">
                Fabrics Type<span style={{ color: "red" }}> *</span>
              </label>
              <MultiSelect
                // value={selectedFabric}
                options={fabrics}
                // onChange={(e) => setSelectedFabric(e.value)}
                // optionLabel="name"
                // placeholder="Select a Fabric"
                id="fabricComposition"
                display="chip"
                value={formik.values.fabricComposition}
                onChange={formik.handleChange}
                className={classNames({
                  "p-error": isFormFieldValid("fabricComposition"),
                })}
              />
              {getFormErrorMessage("fabricComposition")}
            </div>
          </div>
        </div>
        <div
          style={{ textAlign: "center", marginBottom: "100px" }}
          className="p-field"
        >
          <label style={{ fontSize: "25px" }} htmlFor="uploadCatalogueImage">
            Upload Catalogue Image
          </label>
          <ImageUploadBtn
            txt="Select Image"
            intent="catalogue"
            imageUploaded
            id={catalogeId}
            setEvent={setCatalogeImage}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            style={{ marginTop: "20px", width: "20%", background: "#287F80" }}
            label="Add Catalogue"
            className="p-block text-center"
          ></Button>
        </div>
      </form>
      {/* <Divider align="center">
        <Toast ref={toast}></Toast>
        <h2 className="p-text-center">Upload Image</h2>
      </Divider>
      <div style={{ textAlign: "center" }} className="p-field">
        <label style={{ fontSize: "25px" }} htmlFor="uploadCatalogueImage">
          Upload Catalogue Image
        </label>
        <ImageUploadBtn
          txt="Select Image"
          intent="catalogue"
          imageUploaded
          id={catalogueId}
        />
      </div> */}
    </div>
  );
};

export default AddNewCatalogue;
