import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';

import { DataScroller } from 'primereact/datascroller';
import { Rating } from 'primereact/rating';
// import { ProductService } from '../service/ProductService';
import './PlaceNewOrder.css';
const PlaceNewOrder = () => {
  const [products, setProducts] = useState([
    {
      id: '1000',
      code: 'f230fh0g3',
      name: 'Catalogue 1',
      description: 'Product Description',
      image:
        'https://globaltextilesource.com/storage/product/photo/202011201312121074.jpg',
      price: 65,
      category: 'Catalogues',
      quantity: 24,
      inventoryStatus: 'INSTOCK',
      rating: 5,
    },
    {
      id: '1001',
      code: 'nvklal433',
      name: 'Fabric 1',
      description: 'Product Description',
      image:
        'https://globaltextilesource.com/storage/product/photo/202011201312121074.jpg',
      price: 72,
      category: 'Fabrics',
      quantity: 61,
      inventoryStatus: 'INSTOCK',
      rating: 4,
    },
  ]);
  //   const productService = new ProductService();

  //   useEffect(() => {
  //     productService.getProducts().then((data) => setProducts(data));
  //   }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const itemTemplate = (data) => {
    return (
      <div className="product-item">
        <img
          src={`${data.image}`}
          onError={(e) =>
            (e.target.src =
              'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')
          }
          alt={data.name}
        />
        <div className="product-detail">
          <div className="product-name">{data.name}</div>
          <div className="product-description">{data.description}</div>
          <Rating value={data.rating} readOnly cancel={false}></Rating>
          <i className="pi pi-tag product-category-icon"></i>
          <span className="product-category">{data.category}</span>
        </div>
        <div className="product-action">
          <span className="product-price">${data.price}</span>
          <Button
            icon="pi pi-shopping-cart"
            label="Add to Cart"
            disabled={data.inventoryStatus === 'OUTOFSTOCK'}
          ></Button>
          <span
            className={`product-badge status-${data.inventoryStatus.toLowerCase()}`}
          >
            {data.inventoryStatus}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Divider align="center">
        <h3 className="p-text-center">List Of All Products</h3>
      </Divider>

      <div className="datascroller-demo">
        <div className="card">
          <DataScroller
            value={products}
            itemTemplate={itemTemplate}
            rows={5}
            buffer={0.4}
            // header="List of All Products"
          />
        </div>
      </div>
    </div>

    // <div>
    //   <Divider align="center">
    //     <h3 className="p-text-center">Add New User</h3>
    //   </Divider>
    //   <div className="p-fluid">
    //     <div className="p-field">
    //       <label htmlFor="email">Email</label>
    //       <InputText id="email" type="email" />
    //     </div>
    //     <div className="p-field">
    //       <label htmlFor="password">Password</label>
    //       <InputText id="password" type="text" />
    //     </div>
    //     <div className="p-field">
    //       <label htmlFor="firstName">First Name</label>
    //       <InputText id="firstName" type="text" />
    //     </div>
    //     <div className="p-field">
    //       <label htmlFor="lastName">Last Name</label>
    //       <InputText id="lastName" type="text" />
    //     </div>
    //     <div className="p-field">
    //       <label htmlFor="location">Location</label>
    //       <InputText id="location" type="text" />
    //     </div>
    //     <div className="p-field">
    //       <label htmlFor="mobileNumber">Mobile Number</label>
    //       <InputText id="mobileNumber" type="text" />
    //     </div>
    //     <div className="p-field">
    //       <label htmlFor="officeNumber">Office Phone Number</label>
    //       <InputText id="officeNumber" type="text" />
    //     </div>
    //     <div className="p-field">
    //       <label htmlFor="companyName">Company Name</label>
    //       <InputText id="companyName" type="text" />
    //     </div>
    //     <div className="p-field">
    //       <label htmlFor="billingAddress">Billing Address</label>
    //       <InputTextarea id="billingAddress" type="text" />
    //     </div>
    //     <div className="p-field">
    //       <label htmlFor="city">City</label>
    //       <Dropdown
    //         value={city}
    //         options={cityOptions}
    //         onChange={(e) => setCity(e.value)}
    //         placeholder="Select City"
    //       />
    //     </div>
    //     <div className="p-field">
    //       <label htmlFor="state">State</label>
    //       <InputText id="state" type="text" />
    //     </div>
    //     <div className="p-field">
    //       <label htmlFor="country">Country</label>
    //       <InputText id="country" type="text" />
    //     </div>
    //     <div className="p-field">
    //       <label htmlFor="pincode">PinCode</label>
    //       <InputText id="pincode" type="text" />
    //     </div>
    //     <div className="p-field">
    //       <label htmlFor="GST">GST Number</label>
    //       <InputText id="GST" type="text" />
    //     </div>
    //     <div className="p-field">
    //       <label htmlFor="deliveryAddress">Delivery Address</label>
    //       <InputTextarea id="deliveryAddress" type="text" />
    //     </div>
    //     <div className="p-field">
    //       <label htmlFor="aadhar">Aadhar number</label>
    //       <InputText id="aadhar" type="number" />
    //     </div>
    //     <div className="p-field">
    //       <label htmlFor="accountType">Account Type</label>
    //       <InputText id="accountType" type="text" />
    //     </div>
    //     <div className="p-field">
    //       <label htmlFor="discountPercentage">Discount Percentage</label>
    //       <InputText id="discountPercentage" type="number" />
    //     </div>
    //     <div className="p-field">
    //       <label htmlFor="creditPeriod">Credit Period</label>
    //       <InputText id="creditPeriod" type="number" />
    //     </div>
    //     <div className="p-field">
    //       <label htmlFor="usertype">User Type</label>
    //       {/* <InputText id="creditPeriod" type="text" /> */}
    //       <Dropdown
    //         value={userType}
    //         // optionLabel="name"
    //         options={userTypeOptions}
    //         onChange={(e) => setUserType(e.value)}
    //         placeholder="Select Type of User"
    //       />
    //     </div>

    //     <Button label="Submit" className="p-block text-center"></Button>
    //   </div>
    // </div>
  );
};

export default PlaceNewOrder;
