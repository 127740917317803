import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import MenuNavigator from "../../../components/MenuNavigator";
import { Toast } from "primereact/toast";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCatalogueDetails,
  updateCatalogue,
  deleteCatalogue,
  getAllFabrics,
} from "../../../api/api";
import ImageUploadBtn from "../../../components/ImageUploadBtn";

const CatalogueDetails = () => {
  const toast = useRef(null);
  const [selectedFabric, setSelectedFabric] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const fabrics = ["Sheers", "Upholstry", "Contracts", "Curtains", "Lining"];
  const [catalogueName, setCatalogueName] = useState(null);
  const [catalogueURL, setCatalogueUrl] = useState(null);
  const [hsn, setHsn] = useState(null);
  const [architectPricing, setArchitectPricing] = useState(null);
  const [retailerPricing, setRetailerPricing] = useState(null);
  const [channelPricing, setChannelPricing] = useState(null);
  const [fabricComposition, setFabricComposition] = useState(null);
  const [catalogueImage, setCatalogeImage] = useState(null);
  const [fabricAffiliation, setFabricAffiliation] = useState(null);
  const [fabricTableFieldOptions, setfabricTableFieldOptions] = useState(null);
  let location = useLocation();
  const navigate = useNavigate();
  const showCatalogueUpdated = () => {
    toast.current.show({
      severity: "success",
      summary: "Catalogue Updated!",
      detail: "You will be Redirected to Catalogues Page",
    });
  };
  const showCatalogueDeleted = () => {
    toast.current.show({
      severity: "success",
      summary: "Catalogue Deleted",
      detail: "You will be Redirected to Catalogues Page",
    });
  };

  const [catalogueDetails, setCatalogueDetails] = useState({});
  const catalogueId = location.pathname.split("/")[2];
  useEffect(() => {
    getCatalogueDetails(location.pathname.split("/")[2]).then((data) => {
      setCatalogueDetails(data);
      const catalogueData = data;
      console.log("fab", catalogueData);
      setCatalogueName(catalogueData.name);
      setCatalogueUrl(catalogueData.URL);
      setHsn(catalogueData.HSN);
      setArchitectPricing(catalogueData.architectCataloguePrice);
      setRetailerPricing(catalogueData.retailerCataloguePrice);
      setChannelPricing(catalogueData.channelPartnerCataloguePrice);
      setFabricComposition(catalogueData.fabricComposition);
      const fabData = catalogueData.fabricAffiliation?.map((item) => item._id);
      console.log("fab", fabData);
      setFabricAffiliation(fabData);
    });
    getAllFabrics()
      .then((data) => {
        return data.allFabrics.map((fabric) => fabric);
      })
      .then((fabricNames) => {
        console.log("fabName", fabricNames);
        setfabricTableFieldOptions(fabricNames);
      });
  }, [location]);
  const updateData = () => {
    console.log("update");
    const formData = new FormData();

    formData.append("name", catalogueName);
    formData.append("URL", catalogueURL);
    formData.append("HSN", hsn);
    formData.append("architectCataloguePrice", architectPricing);
    formData.append("retailerCataloguePrice", retailerPricing);
    formData.append("channelPartnerCataloguePrice", channelPricing);
    fabricAffiliation.map((item) => {
      formData.append("fabricAffiliation", item);
    });
    console.log("fab", fabricAffiliation);
    if (catalogueImage !== null) {
      formData.append("image", catalogueImage, catalogueImage.name);
    }
    updateCatalogue(catalogueId, formData).then((data) => {
      console.log(data);
      if (data.image) {
        showCatalogueUpdated();
      }
      setTimeout(() => {
        navigate("/catalogues");
      }, 2000);
    });
  };
  console.log("fabric data", catalogueImage);
  return (
    <div>
      <MenuNavigator />
      <Divider align="center">
        <Toast ref={toast}></Toast>
        <h3 className="p-text-center">Catalogue Details</h3>
      </Divider>
      <div className="p-fluid">
        <div
          className="p-fluid"
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <div style={{ width: "35%" }}>
            <div className="p-field">
              <label htmlFor="catalogueName">Catalogue Name</label>
              <InputText
                id="catalogueName"
                type="text"
                disabled={disabled}
                onChange={(e) => setCatalogueName(e.target.value)}
                value={catalogueName}
              />
            </div>

            <div className="p-field">
              <label htmlFor="HSN">HSN</label>
              <InputText
                id="HSN"
                type="text"
                onChange={(e) => setHsn(e.target.value)}
                value={hsn}
                disabled={disabled}
              />
            </div>
            <div className="p-field">
              <label htmlFor="architectCataloguePrice">
                Architect Catalogue Pricing
              </label>
              <InputText
                onChange={(e) => setArchitectPricing(e.target.value)}
                id="architectCataloguePrice"
                type="text"
                value={architectPricing}
                disabled={disabled}
              />
            </div>
            <div className="p-field">
              <label htmlFor="fabricAffiliation">Fabric Affiliation</label>
              <MultiSelect
                options={fabricTableFieldOptions}
                display="chip"
                id="fabricAffiliation"
                name="fabricAffiliation"
                value={fabricAffiliation}
                optionLabel="name"
                optionValue="_id"
                onChange={(e) => setFabricAffiliation(e.target.value)}
                placeholder="Select Fabric Affiliation"
                disabled={disabled}
              />
            </div>
          </div>
          <div style={{ width: "35%" }}>
            <div className="p-field">
              <label htmlFor="retailerCataloguePrice">
                Retailer Catalogue Pricing
              </label>
              <InputText
                onChange={(e) => setRetailerPricing(e.target.value)}
                id="retailerCataloguePrice"
                type="text"
                value={retailerPricing}
                disabled={disabled}
              />
            </div>
            <div className="p-field">
              <label htmlFor="channelPartnerCataloguePrice">
                Channel Partner Catalogue Pricing
              </label>
              <InputText
                onChange={(e) => setChannelPricing(e.target.value)}
                id="channelPartnerCataloguePrice"
                type="text"
                value={channelPricing}
                disabled={disabled}
              />
            </div>

            <div className="p-field">
              <label htmlFor="fabricSelection">Fabrics Composition</label>
              <MultiSelect
                value={fabricComposition}
                options={fabrics}
                onChange={(e) => setFabricComposition(e.target.value)}
                placeholder="Select a Fabric"
                display="chip"
                disabled={disabled}
              />
            </div>
            <div className="p-field">
              <label htmlFor="uploadCatalogueImage">
                0110 Upload Catalogue Image
              </label>
              {/* <ImageUploadBtn
                intent="catalogue"
                id={catalogueId}
                isDisabled={disabled}
              /> */}
              <ImageUploadBtn
                txt="Select Image"
                intent="catalogue"
                imageUploaded
                id={catalogueId}
                setEvent={setCatalogeImage}
                isDisabled={disabled}
              />
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            style={{ margin: "20px", width: "15%" }}
            label="Back"
            onClick={() => navigate("/catalogues")}
            className="p-block p-button-danger text-center"
          ></Button>
          <Button
            style={{ margin: "20px", width: "15%" }}
            label="Edit"
            onClick={() => setDisabled(false)}
            className="p-block p-button-secondary text-center"
          ></Button>
          <Button
            style={{ margin: "20px", width: "15%", background: "#287F80" }}
            label="Update"
            onClick={() => {
              updateData();
            }}
            className="p-block p-button-primary text-center"
          ></Button>
          <Button
            style={{ margin: "20px", width: "15%" }}
            label="Delete"
            onClick={() => {
              deleteCatalogue(catalogueId).then((data) => console.log(data));
              showCatalogueDeleted();
              setTimeout(() => {
                navigate("/catalogues");
              }, 2000);
            }}
            className="p-block p-button-danger text-center"
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default CatalogueDetails;
