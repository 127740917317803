import React from "react";

function SaleCard({ text, data, color }) {
  return (
    <div
      style={{
        boxShadow: "0px 0px 20px rgb(0,0,0,0.2)",
        borderRadius: "10px",
        margin: "10px",
      }}
      className="w-5 h-13rem flex flex-column align-items-center justify-content-center"
    >
      <div
        style={{ backgroundColor: color }}
        className="border-circle w-6rem h-6rem  flex align-items-center justify-content-center"
      >
        <i className="pi pi-user text-2xl text-white"></i>
      </div>
      <div className="m-2">{text}</div>
      <div className="text-2xl font-bold">{data}</div>
    </div>
  );
}

export default SaleCard;
