import React from "react";
import { Outlet } from "react-router-dom";
import MenuNavigator from "../../../components/MenuNavigator";

function DefaultCatelogueOutlet() {
  return (
    <div>
      <MenuNavigator />
      <Outlet />
    </div>
  );
}
function NestedOutlet() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

export { DefaultCatelogueOutlet, NestedOutlet };
