import React from "react";
import { useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";

function MadeToMeasureCart() {
  const navigate = useNavigate();
  const madeToMeasureCart = useSelector(
    (state) => state.persistedReducer.cartReducer.madeToMeasure
  );
  console.log(madeToMeasureCart);
  const currentUser = JSON.parse(window.localStorage.getItem("userDetails"));
  const sum = madeToMeasureCart.reduce((accumulator, object) => {
    return parseFloat(accumulator + parseFloat(object.cost));
  }, 0);
  const costBody = (data) => {
    return (
      <div className="flex justify-content-center align-items-center font-bold">
        Rs {data.totalPriceWithQty}
      </div>
    );
  };
  const totalProductAmount = () => {
    const discount = parseFloat(currentUser.discountPercentage);

    // const discountedPrice = sum - sum * discount;
    return Math.round(sum);
  };
  const totalProductAmount1 = () => {
    const discount = parseFloat(currentUser.discountPercentage);

    const discountedPrice = sum - sum * discount;
    return parseFloat(sum);
  };
  const totalProductAmountWithGST = () => {
    let productAmount = totalProductAmount1();
    console.log("first", productAmount);
    return Math.round(productAmount + productAmount * 0.12);
  };
  let footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Total:" colSpan={10} />
        <Column footerClassName="text-center" footer={Math.round(sum)} />
      </Row>
      <Row>
        <Column footer="Total with GST (12%):" colSpan={10} />
        <Column
          footerClassName="text-center"
          footer={totalProductAmountWithGST()}
        />
      </Row>
    </ColumnGroup>
  );
  return (
    <div>
      {madeToMeasureCart.length !== 0 && (
        <div>
          <DataTable
            header="Made To Measure"
            size="small"
            className="m-3"
            colSpan={11}
            footerColumnGroup={footerGroup}
            showGridlines
            value={madeToMeasureCart}
          >
            <Column field="roomType" header="Room Type"></Column>
            <Column field="window" header="Window"></Column>
            <Column field="accessory" header="Accessory"></Column>
            <Column field="stitch" header="Stritch"></Column>
            <Column field="lining" header="Lining"></Column>
            <Column field="height" header="Height"></Column>
            <Column field="width" header="Width"></Column>
            <Column field="noOFPanel" header="No of Panels"></Column>
            <Column
              field="perPanelQuantity"
              header="Per Panel Quantity"
            ></Column>
            <Column field="quantity" header="Quantity"></Column>
            <Column
              bodyClassName="text-center"
              field="cost"
              header="Cost"
            ></Column>
          </DataTable>
          <div className="m-4 mb-6 text-center">
            <Button
              onClick={() => {
                navigate("cartDelivery", {
                  state: {
                    madeToMeasureCart,
                    total: sum,
                    totalAfterDiscount: totalProductAmount(),
                    totalAfterGst: totalProductAmountWithGST(),
                    isMtm: true,
                  },
                });
              }}
              className="w-3"
              label="Proceed to Buy"
            ></Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default MadeToMeasureCart;
