import React, { useEffect, useState, useRef, useContext } from "react";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { Card } from "primereact/card";
import LoginImg from "../assets/Login.jpeg";
import { Button } from "primereact/button";
import logoSRC from "../assets/logo.jpeg";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import { useNavigate } from "react-router-dom";
import { login, getProfileDetail } from "../api/api";

const Login = () => {
  const toast = useRef(null);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [displayBasic, setDisplayBasic] = useState(false);
  const [position, setPosition] = useState("center");

  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  //   const myToast = useRef(null);
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => onHide(name)}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => onHide(name)}
          autoFocus
        />
      </div>
    );
  };
  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.email) {
        errors.email = "Email is required.";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = "Invalid email address. E.g. example@email.com";
      }

      if (!data.password) {
        errors.password = "Password is required.";
      }

      return errors;
    },

    onSubmit: async (data) => {
      setFormData(data);

      login(data.email, data.password)
        .then((userVal) => {
          console.log(userVal);
          window.localStorage.setItem("userType", userVal.data.user.userType);
          window.localStorage.setItem("userId", userVal.data.token);
          window.localStorage.setItem("id", userVal.data.user.id);
          window.localStorage.setItem("rolls", userVal?.data?.user?.roles);
          if (userVal.status === 200) {
            getProfileDetail().then((data) => {
              window.localStorage.setItem("userDetails", JSON.stringify(data));
              window.localStorage.setItem(
                "expiredOrders",
                JSON.stringify(data.expired_credit_period_orders)
              );
            });
            if (window.localStorage.getItem("userType") === "admin"||
            window.localStorage.getItem("userType") === "sales"||
            window.localStorage.getItem("userType") === "accounts") {
              navigate("/adminDashboard");
            } else {
              navigate("/userDashboard");
            }
          } else if (userVal.status === 404) {
            showError("User does not exist");
          } else if (userVal.status === 401) {
            showError("Invalid Password");
          } else {
            showError("Contact Administrator");
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            showError();
          }
        });

      formik.resetForm();
    },
  });
  const showError = (info) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: "Invalid Credentials",
      life: 3000,
    });
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const header = (
    <div className="text-center">
      <img alt="Card" src={logoSRC} />
    </div>
  );

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  return (
    <div className="flex">
      <Toast ref={toast} />
      <div className="w-6" style={{ height: "100vh" }}>
        <img alt="" style={{ width: "100%", height: "100vh" }} src={LoginImg} />
      </div>
      <div
        style={{ background: "white", backgroundColor: "#e2ebf0" }}
        className="w-6 flex align-items-center justify-content-center"
      >
        <div className="w-6">
          <div></div>
          {header}
          <div className="">
            <form onSubmit={formik.handleSubmit} className="p-fluid">
              <div className="p-field">
                <label
                  htmlFor="email"
                  className={classNames({
                    "p-error": isFormFieldValid("email"),
                  })}
                >
                  Email
                </label>
                <InputText
                  id="email"
                  style={{ width: "100%" }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  autoFocus
                  className={classNames({
                    "p-invalid": isFormFieldValid("email"),
                  })}
                />
                {getFormErrorMessage("email")}
              </div>

              <div className="p-field">
                <label
                  htmlFor="password"
                  className={classNames({
                    "p-error": isFormFieldValid("email"),
                  })}
                >
                  Password
                </label>
                <Password
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("password"),
                  })}
                  feedback={false}
                />
                {getFormErrorMessage("password")}
              </div>
              <Button
                type="submit"
                label="Login"
                style={{ background: "#3E4242", marginBottom: "8px" }}
                className="mt-2"
                disabled={!(formik.isValid && formik.dirty)}
              />
            </form>
            <div
              style={{
                textAlign: "center",
                marginTop: "1px",
                cursor: "pointer",
              }}
            >
              <p onClick={() => onClick("displayBasic")}>Forgot Password ?</p>
              <Dialog
                header="Forgot Password ?"
                visible={displayBasic}
                style={{
                  width: "30vw",
                  display: "flex",
                  justifyContent: "end",
                }}
                onHide={() => onHide("displayBasic", "right")}
              >
                <form>
                  <label>Email</label>
                  <InputText
                    style={{
                      height: "2.49rem",
                      width: "25rem",
                      display: "flex",
                      marginTop: "0.5rem",
                    }}
                    placeholder="Enter your email"
                  ></InputText>
                  <Button
                    style={{
                      height: "2.49rem",
                      width: "11rem",
                      marginTop: "0.7rem",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    label="Recover Password"
                  />
                </form>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
