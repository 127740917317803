import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fabrics: [],
  catelogues: [],
  madeToMeasure: [],
  cartItem: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addFabricsToCart: (state, action) => {
      const index = state.fabrics.findIndex(
        (el) => el._id === action.payload._id
      );
      if (index > -1) {
        state.fabrics[index].qty += 1;
        state.fabrics[index].totalPriceWithQty += state.fabrics[index].price;
      } else {
        const modifiedPayload = {
          ...action.payload,
          qty: action.payload.quantity,
          totalPriceWithQty: action.payload.price * action.payload.quantity,
        };
        state.fabrics.push(modifiedPayload);
        state.cartItem += 1;
      }
    },
    addCateloguesToCart: (state, action) => {
      const index = state.catelogues.findIndex(
        (el) => el._id === action.payload._id
      );
      if (index > -1) {
        state.catelogues[index].qty += 1;
        state.catelogues[index].totalPriceWithQty +=
          state.catelogues[index].price;
      } else {
        const modifiedPayload = {
          ...action.payload,
          qty: action.payload.quantity,
          totalPriceWithQty: action.payload.price * action.payload.quantity,
        };
        state.catelogues.push(modifiedPayload);
        state.cartItem += 1;
      }
    },
    addMadeToMeasureToCart: (state, action) => {
      state.madeToMeasure.push(...action.payload);
      state.cartItem += 1;
    },
    decrementFabricQuantity: (state, action) => {
      const index = state.fabrics.findIndex(
        (el) => el._id === action.payload._id
      );

      if (state.fabrics[index].qty > 1) {
        state.fabrics[index].qty -= 1;
        state.fabrics[index].totalPriceWithQty -= state.fabrics[index].price;
      } else {
        state.fabrics.splice(index, 1);
        state.cartItem -= 1;
      }
    },
    decrementCatelogueQuantity: (state, action) => {
      const index = state.catelogues.findIndex(
        (el) => el._id === action.payload._id
      );

      if (state.catelogues[index].qty > 1) {
        state.catelogues[index].qty -= 1;
        state.catelogues[index].totalPriceWithQty -=
          state.catelogues[index].price;
      } else {
        state.catelogues.splice(index, 1);
        state.cartItem -= 1;
      }
    },
    decrementMadeToMeasureQuantity: (state, action) => {
      const index = state.madeToMeasure.findIndex(
        (el) => el._id === action.payload._id
      );

      if (state.madeToMeasure[index].qty > 1) {
        state.madeToMeasure[index].qty -= 1;
      } else {
        state.madeToMeasure.splice(index, 1);
        state.cartItem -= 1;
      }
    },
    incrementFabricQuantity: (state, action) => {
      const index = state.fabrics.findIndex(
        (el) => el._id === action.payload._id
      );
      if (index > -1) {
        state.fabrics[index].qty += 1;
        state.fabrics[index].totalPriceWithQty += state.fabrics[index].price;
      }
    },
    incrementCatelogueQuantity: (state, action) => {
      const index = state.catelogues.findIndex(
        (el) => el._id === action.payload._id
      );
      if (index > -1) {
        state.catelogues[index].qty += 1;
        state.catelogues[index].totalPriceWithQty +=
          state.catelogues[index].price;
      }
    },
    incrementMadeToMeasure: (state, action) => {
      const index = state.madeToMeasure.findIndex(
        (el) => el._id === action.payload._id
      );
      if (index > -1) {
        state.madeToMeasure[index].qty += 1;
      }
    },
    clearCatalogue: (state, action) => {
      state.catelogues = [];
    },
    clearMTM: (state, action) => {
      state.madeToMeasure = [];
    },
  },
});

export const {
  addCateloguesToCart,
  addFabricsToCart,
  addMadeToMeasureToCart,
  decrementFabricQuantity,
  decrementCatelogueQuantity,
  decrementMadeToMeasureQuantity,
  incrementFabricQuantity,
  incrementCatelogueQuantity,
  incrementMadeToMeasure,
  clearCatalogue,
  clearMTM,
} = cartSlice.actions;
export default cartSlice.reducer;
