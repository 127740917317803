import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./cart/cartSlice";
import fabricsReducer from "./fabrics/fabricsSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";

const persistCongig = {
  key: "root",
  version: 1,
  storage,
};
const reducer = combineReducers({
  cartReducer,
});

const persistedReducer = persistReducer(persistCongig, reducer);

export const store = configureStore({
  reducer: {
    persistedReducer,
    fabricsReducer,
  },
  // devTools: process.env.NODE_ENV !== "production",
});
