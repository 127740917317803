import React, { useState } from 'react';
import MenuNavigator from '../../components/MenuNavigator';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

const Shipping = () => {
  const shippingOptions = [
    'Store Pick up',
    'SM Transport',
    'Drop off at Logistics',
    'Paid Shipment / Courier',
  ];
  const [chosenShippingOption, setChosenShippingOption] =
    useState('Store Pick up');

  return (
    <div>
      <MenuNavigator />
      <Divider align="center">
        <h3 className="p-text-center">Shipping</h3>
      </Divider>

      <div className="p-fluid">
        <div className="p-field">
          {/* <h5>Basic</h5> */}
          <label htmlFor="shippingMode">Shipping Mode</label>

          <Dropdown
            value={chosenShippingOption}
            options={shippingOptions}
            onChange={(e) => setChosenShippingOption(e.value)}
            // optionLabel="name"
            placeholder="Select a City"
          />
        </div>
        {chosenShippingOption === 'Store Pick up' ? null : (
          <div className="p-field">
            <label htmlFor="deliveryAddress">Delivery Address</label>
            <InputTextarea id="deliveryAddress" type="text" />
          </div>
        )}
        <Button label="Get Quotation" className="p-block text-center"></Button>
      </div>
    </div>
  );
};

export default Shipping;
