import React, { useState, useEffect, useRef } from "react";
import { DataView } from "primereact/dataview";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import { getAllFabrics } from "../../../api/api";
import { useNavigate } from "react-router-dom";
import { fetchFabrics } from "../../../redux/fabrics/fabricsSlice";
import { useDispatch, useSelector } from "react-redux";
import { addFabricsToCart } from "../../../redux/cart/cartSlice";
import { Toast } from "primereact/toast";

const ListOfFabrics = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.fabricsReducer.isLoading);
  const [count, setCount] = useState(0);
  console.log(count);
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([]);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const currentUser = JSON.parse(window.localStorage.getItem("userDetails"));
  const RenderPrice = (currentUser, fabricDetails) => {
    if (currentUser) {
      if (currentUser.userType === "retailer") {
        return fabricDetails.retailerCutLengthPrice;
      } else if (currentUser.userType === "channelPartner") {
        return fabricDetails.channelPartnerCutLengthPrice;
      } else {
        return fabricDetails.architectCutLengthPrice;
      }
    }
  };
  const toast = useRef(null);
  const navigate = useNavigate();

  function showInfo() {
    toast.current.show({
      severity: "info",
      summary: "Added to Cart",
      detail: "The Fabric has been added to the cart",
    });
  }
  useEffect(() => {
    getAllFabrics(currentPage,searchTerm).then((res) => {
      console.log("data 1", res.allFabrics?.length, res.fabricCount);
      setCount(res.fabricCount);
      // const data = res.allFabrics?.map((item, index) => {
      //   // console.log("first", item);
      //   return { ...item, quantity: 1, index: index };
      // });
      // console.log("data ", data);
      setProducts(res.allFabrics);
    });
  }, [currentPage,searchTerm]);

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <h2 className="m-0">Fabrics</h2>
        <div
          className="col-12"
          style={{ minWidth: "15rem", width: "25rem", flexShrink: 1 }}
        >
          <div className="p-inputgroup">
            <InputText
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search Fabric"
            />
            <Button
              style={{
                backgroundColor: "#287F80",
              }}
              label="Search"
            />
          </div>
        </div>
      </div>
    );
  };
  const renderGridItem = (data, index) => {
    return (
      <div className="col-12 md:col-3 sm-col-2 ">
        <div className="product-grid-item card">
          <div
            onClick={() =>
              navigate(
                "/fabric/".concat(data._id)
                // {
                //   state: { fabData: data },
                // }
              )
            }
            className="product-grid-item-content"
          >
            <img
              style={{ width: "150px", height: "150px", cursor: "pointer" }}
              src={
                data.image
                  ? data.image
                  : "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=826&t=st=1661248188~exp=1661248788~hmac=64bb7d23be8dcef15ecfef304e7d5bfe293f29bbf5b48fd6cadb282e205f9ddb"
              }
              alt={data.name}
            />
            <div className="product-name text-sm">{data.name}</div>
            <span>Rs{RenderPrice(currentUser, data)}</span>
          </div>
          {/* <div className="flex justify-content-center align-items-center">
            <Button
              onClick={() => decrement(data?._id, data?.quantity)}
              className="mx-2 border-circle p-button-outlined"
              label="-"
            ></Button>
            <div className="text-xl mx-1 font-bold">{data?.quantity}</div>
            <Button
              onClick={() => increment(data?._id)}
              className="mx-2 border-circle p-button-outlined"
              label="+"
            ></Button>
          </div> */}
          <div className="text-center p-1 mt-2">
            <Button
              // onClick={() => {
              //   // dispatch(
              //   //   addFabricsToCart({
              //   //     ...data,
              //   //     price: RenderPrice(currentUser, data),
              //   //   })
              //   // );
              //   // showInfo();
              // }}
              onClick={() => navigate("/fabric/".concat(data._id))}
              icon="pi pi-shopping-cart"
              style={{ width: "200px" }}
              label="Click to Buy"
            ></Button>
          </div>
        </div>
      </div>
    );
  };
  const itemTemplate = (product, layout) => {
    if (!product) {
      return;
    }

    if (layout === "list") return renderGridItem(product);
    else if (layout === "grid") return renderGridItem(product);
  };
  const header = renderHeader();
  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
    setCurrentPage(event.page + 1);
  };
  const increment = (id) => {
    console.log("increment");
    const data = products?.map((item) => {
      if (item?._id === id) {
        return { ...item, quantity: item?.quantity + 1 };
      } else {
        return { ...item };
      }
    });
    setProducts(data);
  };
  const decrement = (id, qua) => {
    console.log("decrement");
    const data = products.map((item) => {
      if (item?._id === id && qua > 1) {
        return { ...item, quantity: item?.quantity - 1 };
      } else {
        return { ...item };
      }
    });
    setProducts(data);
  };
  // console.log("page", currentPage);
  return (
    <div>
      {isLoading && (
        <div className="text-center m-3 text-xl font-bold ">Loading...</div>
      )}
      <Toast ref={toast} />
      <div className="dataview-demo m-5">
        <div className="card">
          <DataView
            value={products}
            layout={"grid"}
            header={header}
            itemTemplate={itemTemplate}
          ></DataView>
          <Paginator
            first={basicFirst}
            rows={basicRows}
            totalRecords={count}
            onPageChange={onBasicPageChange}
          ></Paginator>
        </div>
      </div>
    </div>
  );
};

export default ListOfFabrics;
