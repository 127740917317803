import React, { useState, useEffect } from 'react'
import MenuNavigator from '../../../components/MenuNavigator'
import { Divider } from 'primereact/divider'
import AllOrders from "../../../assets/AllOrders.jpg"
import CatalogueOrders from "../../../assets/CatalogueOrders.jpg"
import FabricOrders from "../../../assets/FabricOrders.jpg"
import MTMOrders from "../../../assets/MTMOrders.jpg"
import { useNavigate } from "react-router-dom";
import { getAllOrders } from '../../../api/api';

function AdminOrders() {
  const navigate = useNavigate()
  const [ordersList, setOrdersList] = useState([]);
  useEffect(() => {
    getAllOrders().then(data => setOrdersList(data))
  }, [])
  console.log(ordersList)
  return (
    <div>
      <MenuNavigator />
      <Divider align="center">
        <h1 className="p-text-center">All Orders</h1>
      </Divider>
      <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "40px" }} >
        <div style={{ textAlign: "center", width: "20%", cursor: "pointer", border: "2px solid black", borderRadius: "20px" }} onClick={() => { navigate("/allOrders", {/* state: ordersList */}) }}>
          <h2> All Orders</h2>
          <img alt="" style={{ width: "100%" }} src={AllOrders} />
        </div>
        <div style={{ textAlign: "center", width: "20%", cursor: "pointer", border: "2px solid black", borderRadius: "20px" }} onClick={() => { navigate("/allFabricsOrders", {/* state: ordersList */}) }}>
          <h2> Fabrics Orders</h2>
          <img alt="" style={{ width: "100%" }} src={FabricOrders} />
        </div>
        <div style={{ textAlign: "center", width: "20%", cursor: "pointer", border: "2px solid black", borderRadius: "20px" }} onClick={() => { navigate("/allCatalogueOrders", {/* state: ordersList */}) }}>
          <h2> Catalogue Orders</h2>
          <img alt="" style={{ width: "100%" }} src={CatalogueOrders} />
        </div>
        <div style={{ textAlign: "center", width: "20%", cursor: "pointer", border: "2px solid black", borderRadius: "20px" }} onClick={() => { navigate("/allMTMOrders", {/* state: ordersList */}) }}>
          <h2> MTM Orders</h2>
          <img alt="" style={{ width: "100%" }} src={MTMOrders} />
        </div>

      </div>


    </div>
  )
}

export default AdminOrders