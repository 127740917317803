import React, { useEffect, useState, useRef } from "react";
import MenuNavigator from "../../components/MenuNavigator";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { getProfileDetail, updateProfile } from "../../api/api";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";

function UserProfile() {
  const toast = useRef(null);
  const userId = window.localStorage.getItem("userId");
  const id = window.localStorage.getItem("id");
  const [userInfo, setUserInfo] = useState([]);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [name, setName] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [address3, setAddress3] = useState(null);
  const [address4, setAddress4] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);
  const [pincode, setPincode] = useState(null);
  const [GST, setGST] = useState(null);
  const [deliveryAddress1, setDeliveryAddress1] = useState(null);
  const [deliveryAddress2, setDeliveryAddress2] = useState(null);
  const [deliveryAddress3, setDeliveryAddress3] = useState(null);
  const [deliveryAddress4, setDeliveryAddress4] = useState(null);
  const [deliveryState, setDeliveryState] = useState(null);
  const [deliveryCountry, setDeliveryCountry] = useState(null);
  const [deliveryPincode, setDeliveryPincode] = useState(null);
  const [aadhar, setAadhar] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState(null);
  const [creditPeriod, setCreditPeriod] = useState(null);

  useEffect(() => {
    getProfileDetail().then((data) => {
      window.localStorage.setItem(
        "expiredOrders",
        JSON.stringify(data.expired_credit_period_orders)
      );
      setUserInfo(data);
      console.log("Profil Data", data);
      setEmail(data.email);
      setPassword(data.password);
      setName(data.name);
      setMobile(data.mobileNumber);
      setCompanyName(data.companyName);
      setAddress1(data.address1);
      setAddress2(data.address2);
      setAddress3(data.address3);
      setAddress4(data.address4);
      setState(data.state);
      setCountry(data.country);
      setPincode(data.pincode);
      setEmail(data.email);
      setGST(data.GST);
      setDeliveryAddress1(data.deliveryAddress1);
      setDeliveryAddress2(data.deliveryAddress2);
      setDeliveryAddress3(data.deliveryAddress3);
      setDeliveryAddress4(data.deliveryAddress4);
      setDeliveryState(data.deliveryState);
      setDeliveryCountry(data.deliveryCountry);
      setDeliveryPincode(data.deliveryPincode);
      setAadhar(data.aadhar);
      setDiscountPercentage(data.discountPercentage);
      setCreditPeriod(data.creditPeriod);
    });
  }, []);

  const showuserUpdated = () => {
    toast.current.show({
      severity: "success",
      summary: "User Details Updated!",
    });
  };
  console.log(userInfo.expired_credit_period_orders);
  return (
    <div>
      <MenuNavigator />
      {userInfo.expired_credit_period_orders &&
        userInfo.expired_credit_period_orders.length > 0 && (
          <div
            style={{
              backgroundColor: "#B3E5FC",
              color: "#23547B",
              marginTop: "20px",
              padding: ".55em 2rem",
              borderRadius: "3px",
              fontWeight: "700",
              letterSpacing: "2px",
              fontSize: "25px",
              textAlign: "center",
            }}
          >
            <div>
              Please Pay the OutStanding Amount to Proceed with New Orders
            </div>
            <Button
              onClick={() => {
                navigate("/expiredOrders");
              }}
              style={{ marginTop: "10px", background: "#287F80" }}
            >
              View Expired Orders
            </Button>{" "}
          </div>
        )}
      <Divider align="center">
        <Toast ref={toast}></Toast>
        <h2 className="p-text-center">Profile</h2>
      </Divider>

      <div
        style={{
          display: "flex",
          backgroundColor: "",
          marginTop: "10px",
          justifyContent: "center",
        }}
      >
        <div>
          <img
            alt=""
            style={{ height: "400px" }}
            src="https://img.freepik.com/free-vector/profile-interface-concept-illustration_114360-2850.jpg?w=900&t=st=1649135082~exp=1649135682~hmac=7893b205708e05847530acb475d7d10522660fa6c15e861f1bc3f82adf18db05"
          />
        </div>
        <div style={{ width: "70%" }}>
          <div
            className="p-fluid"
            style={{
              fontSize: "15px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <div style={{ width: "35%" }}>
              <div className="">
                <label htmlFor="email">Email</label>
                <InputText
                  style={{ height: "30px", marginBottom: "10px" }}
                  disabled={disabled}
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="">
                <label htmlFor="name">Name</label>
                <InputText
                  style={{ height: "30px", marginBottom: "10px" }}
                  disabled={disabled}
                  id="name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="">
                <label htmlFor="mobileNumber">Mobile Number</label>
                <InputText
                  style={{ height: "30px", marginBottom: "10px" }}
                  disabled={disabled}
                  id="mobileNumber"
                  type="text"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>
              <div className="">
                <label htmlFor="companyName">Company Name</label>
                <InputText
                  style={{ height: "30px", marginBottom: "10px" }}
                  disabled={disabled}
                  id="companyName"
                  type="text"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>

              <div className="">
                <label htmlFor="address1"> Address(Line1)</label>
                <InputText
                  style={{ height: "30px", marginBottom: "10px" }}
                  id="address1"
                  type="text"
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  disabled={disabled}
                />
              </div>
              <div className="">
                <label htmlFor="address2"> Address(Line2)</label>
                <InputText
                  style={{ height: "30px", marginBottom: "10px" }}
                  id="address2"
                  type="text"
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                  disabled={disabled}
                />
              </div>

              <div className="">
                <label htmlFor="state">State</label>
                <InputText
                  style={{ height: "30px", marginBottom: "10px" }}
                  id="state"
                  type="text"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  disabled={disabled}
                />
              </div>
              <div className="">
                <label htmlFor="country">Country</label>
                <InputText
                  style={{ height: "30px", marginBottom: "10px" }}
                  id="country"
                  type="text"
                  value={country}
                  disabled={disabled}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
            </div>

            <div style={{ width: "35%" }}>
              <div className="">
                {password?.length > 0 && password?.length < 6 ? (
                  <label htmlFor="GST" style={{ color: "red" }}>
                    Update Password
                  </label>
                ) : (
                  <label htmlFor="GST">Update Password</label>
                )}
                <InputText
                  style={{ height: "30px", marginBottom: "10px" }}
                  id="GST"
                  type="text"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={disabled}
                />
              </div>
              <div className="">
                <label htmlFor="GST">GST Number</label>
                <InputText
                  style={{ height: "30px", marginBottom: "10px" }}
                  id="GST"
                  type="text"
                  value={GST}
                  onChange={(e) => setGST(e.target.value)}
                  disabled={disabled}
                />
              </div>
              <div className="">
                <label htmlFor="deliveryAddress1">
                  Delivery Address(Line1)
                </label>
                <InputText
                  style={{ height: "30px", marginBottom: "10px" }}
                  id="deliveryAddress1"
                  type="text"
                  value={deliveryAddress1}
                  onChange={(e) => setDeliveryAddress1(e.target.value)}
                  disabled={disabled}
                />
              </div>
              <div className="">
                <label htmlFor="deliveryAddress2">
                  Delivery Address(Line2)
                </label>
                <InputText
                  style={{ height: "30px", marginBottom: "10px" }}
                  id="deliveryAddress2"
                  type="text"
                  value={deliveryAddress2}
                  onChange={(e) => setDeliveryAddress2(e.target.value)}
                  disabled={disabled}
                />
              </div>

              <div className="">
                <label htmlFor="deliveryState">Delivery State</label>
                <InputText
                  style={{ height: "30px", marginBottom: "10px" }}
                  id="deliveryState"
                  type="text"
                  value={deliveryState}
                  onChange={(e) => setDeliveryState(e.target.value)}
                  disabled={disabled}
                />
              </div>
              <div className="">
                <label htmlFor="deliveryCountry">Delivery Country</label>
                <InputText
                  style={{ height: "30px", marginBottom: "10px" }}
                  id="deliveryCountry"
                  type="text"
                  value={deliveryCountry}
                  onChange={(e) => setDeliveryCountry(e.target.value)}
                  disabled={disabled}
                />
              </div>
              <div className="">
                <label htmlFor="deliveryPincode">Delivery PinCode</label>
                <InputText
                  style={{ height: "30px", marginBottom: "10px" }}
                  id="deliveryPincode"
                  type="text"
                  value={deliveryPincode}
                  onChange={(e) => setDeliveryPincode(e.target.value)}
                  disabled={disabled}
                />
              </div>

              <div className="">
                <label htmlFor="aadhar">Aadhar number</label>
                <InputText
                  style={{ height: "30px", marginBottom: "10px" }}
                  id="aadhar"
                  value={aadhar}
                  onChange={(e) => setAadhar(e.target.value)}
                  type="text"
                  disabled={disabled}
                />
              </div>
              <div className="">
                <label htmlFor="pincode">PinCode</label>
                <InputText
                  style={{ height: "30px", marginBottom: "10px" }}
                  id="pincode"
                  type="text"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              style={{ width: "15%", margin: "25px" }}
              label="Edit"
              onClick={() => setDisabled(false)}
              className="p-block p-button-secondary text-center"
            ></Button>
            <Button
              style={{ width: "15%", margin: "25px", background: "#287F80" }}
              label="Update"
              onClick={() => {
                if (password?.length > 0 && password.length < 6) {
                  toast.current.show({
                    severity: "error",
                    summary: "Password must have atleast 6 characters!",
                  });
                } else {
                  updateProfile({
                    email: email,
                    password: password,
                    name: name,
                    mobileNumber: mobile,
                    companyName: companyName,
                    address1: address1,
                    address2: address2,
                    address3: address3,
                    address4: address4,
                    state: state,
                    country: country,
                    pincode: pincode,
                    GST: GST,
                    deliveryAddress1: deliveryAddress1,
                    deliveryAddress2: deliveryAddress2,
                    deliveryAddress3: deliveryAddress3,
                    deliveryAddress4: deliveryAddress4,
                    deliveryState: deliveryState,
                    deliveryCountry: deliveryCountry,
                    deliveryPincode: deliveryPincode,
                    aadhar: aadhar,
                    discountPercentage: discountPercentage,
                  }).then((data) => {
                    console.log(data);
                    if (data.companyName) {
                      showuserUpdated();
                    }
                  });
                }
              }}
              className="p-block p-button-primary text-center"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
