import React from 'react';

const Envelope = ({ children }) => {
  return (
    <div className="">
      <div className="">
        {children}
      </div>
    </div>
  );
};

export default Envelope;
