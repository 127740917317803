import React, { useEffect, useState, useRef } from "react";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { useLocation, useNavigate } from "react-router-dom";
import { displayRazorpay } from "../PaymentIntegration";
import { Toast } from "primereact/toast";
import { createOrder, createOrderByAdmin } from "../../../api/api";
import { Dialog } from "primereact/dialog";
import { useReactToPrint } from "react-to-print";

const FabricQuotation = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state);
  const paymentOption = JSON.parse(
    window.localStorage.getItem("userDetails")
  ).paymentOption;
  const userDetails = state.currentUser;
  const delivery = state.delivery;
  const expiredOrders = JSON.parse(
    window.localStorage.getItem("expiredOrders")
  );

  const totalProductAmount = () => {
    let total = 0;
    const amount = getAmount();
    total = state.qty * amount;
    const discount = state.currentUser.discountPercentage / 100;
    const discountedPrice = total - total * discount;
    return Math.round(discountedPrice);
  };
  const totalProductAmount2 = () => {
    let total = 0;
    const amount = getAmount();
    total = state.qty * amount;
    const discount = state.currentUser.discountPercentage / 100;
    const discountedPrice = total - total * discount;
    return discountedPrice;
  };
  const toast = useRef(null);
  const orderRef = useRef(null);
  const showSuccess = () => {
    toast.current.show({
      severity: "success",
      summary: "Payment Recieved",
      detail:
        "Your Order is Placed. You will be redirected to the Fabrics Page",
    });
  };
  const error = (message) => {
    toast.current.show({
      severity: "error",
      summary: message,
      detail: "Please try Again",
    });
  };
  const move = () => {
    navigate("/userDashboard");
  };
  const totalProductAmountWithGST = () => {
    let productAmount = totalProductAmount2();
    return Math.round(0.12 * productAmount + productAmount);
  };
  const getAmount = () => {
    if (state?.currentUser?.userType === "retailer") {
      if (state.qty < 50) {
        return state?.fabricDetails?.retailerCutLengthPrice;
      } else {
        return state?.fabricDetails?.retailerRollLengthPrice;
      }
    } else if (state?.currentUser?.userType === "architect") {
      if (state.qty < 50) {
        return state?.fabricDetails?.architectCutLengthPrice;
      } else {
        return state?.fabricDetails?.architectRollLengthPrice;
      }
    } else {
      if (state.qty < 50) {
        return state?.fabricDetails?.channelPartnerCutLengthPrice;
      } else {
        return state?.fabricDetails?.channelPartnerRollLengthPrice;
      }
    }
  };
  const products = [
    {
      hsn: state.fabricDetails.HSN,
      productcode: "Code5",
      name: state.fabricDetails.name,
      quantity: state.qty,
      amount: getAmount(),
      discount: state.currentUser.discountPercentage,

      collectionName: state.fabricDetails.collectionName,

      totalAmt: totalProductAmount(),
    },
  ];
  const withGST = totalProductAmountWithGST();
  const currentAmtWithCreditAmt = parseInt(withGST) + 0;
  const headerTemplate = <span>Breakup</span>;
  const data = { ...state, finalAmount: currentAmtWithCreditAmt, showSuccess };

  let footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Total:" colSpan={7} />
        <Column footer={totalProductAmount} />
      </Row>
      <Row>
        <Column footer="Total with GST (12%):" colSpan={7} />

        <Column footer={totalProductAmountWithGST} />
      </Row>
      <Row>
        <Column footer="Total with Outstanding Amount:" colSpan={7} />

        <Column footer={currentAmtWithCreditAmt} colSpan={1} />
      </Row>
    </ColumnGroup>
  );

  const FabricTable = (
    <DataTable
      colSpan={11}
      showGridlines
      value={products}
      responsiveLayout="scroll"
      header={headerTemplate}
      // footer={footerTemplate}
      footerColumnGroup={footerGroup}
    >
      <Column header="Sr. No." body={1} exportable></Column>
      <Column field="hsn" header="HSN Code"></Column>
      <Column field="name" header="Fabric Name"></Column>
      <Column field="collectionName" header="Collection Name"></Column>
      <Column field="quantity" header="Quantity"></Column>

      <Column field="amount" header="Price"></Column>
      <Column field="discount" header="Discount(%)"></Column>

      <Column field="totalAmt" header="Total Amount"></Column>
    </DataTable>
  );
  const [displayBasic, setDisplayBasic] = useState(false);

  const [position, setPosition] = useState("center");

  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };
  const handlePrintQuotation = useReactToPrint({
    content: () => orderRef.current,
    onAfterPrint: () => {
      navigate("/fabric/all");
    },
  });
  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => onHide(name)}
          className="p-button-text"
        />
        <Button
          label="Pay Now"
          icon="pi pi-check"
          onClick={() => {
            onHide(name);
            navigate("/expiredOrders");
          }}
          autoFocus
        />
      </div>
    );
  };

  const paynowButton = (
    <Button
      style={{ width: "150px", margin: "10px", background: "#287F80" }}
      label="Pay Now"
      onClick={(e) => {
        e.preventDefault();

        if (expiredOrders.length > 0) {
          onClick("displayBasic");
        } else {
          createOrder({
            orderType: "paynow",
            name: "fabric",
            fabric: data.fabricDetails._id,
            companyName: data.delivery.companyName,
            deliveryAddress: data.delivery.deliveryAddress,
            state: data.delivery.state,
            pincode: data.delivery.pincode,
            contactNumber: data.delivery.contactNumber,
            modeOfTransport: data.delivery.modeOfTransport,
            optionalTransport: data.delivery.optionalTransport
              ? data.delivery.optionalTransport
              : "",
            qty: data.qty,
            totalAmount: data.finalAmount,
          })
            .then((res) => {
              displayRazorpay({
                data: res,
                finalAmount: res.razorpayOrder.amount,
                success: showSuccess,
                error: error,
                navigate: move,
              });
            })
            .catch(() => error("Couldn't able to Create Order"));
        }
      }}
    ></Button>
  );
  const advanceButton = (
    <Button
      style={{ width: "150px", margin: "10px", background: "#287F80" }}
      label="Take Advance"
      onClick={(e) => {
        e.preventDefault();

        if (expiredOrders.length > 0) {
          onClick("displayBasic");
        } else {
          createOrder({
            orderType: "advance",
            name: "fabric",
            fabric: data.fabricDetails._id,
            companyName: data.delivery.companyName,
            deliveryAddress: data.delivery.deliveryAddress,
            state: data.delivery.state,
            pincode: data.delivery.pincode,
            contactNumber: data.delivery.contactNumber,
            modeOfTransport: data.delivery.modeOfTransport,
            optionalTransport: data.delivery.optionalTransport
              ? data.delivery.optionalTransport
              : "",
            qty: data.qty,
            totalAmount: data.finalAmount,
          })
            .then((res) => {
              displayRazorpay({
                data: res,
                finalAmount: res.razorpayOrder.amount,
                success: showSuccess,
                error: error,
                navigate: move,
              });
            })
            .catch(() => error("Couldn't able to Create Order"));
        }
      }}
    ></Button>
  );
  const creditButton = (
    <Button
      type="submit"
      style={{ width: "150px", margin: "10px", background: "#287F80" }}
      label="Take Credit"
      onClick={() => {
        if (expiredOrders.length > 0) {
          onClick("displayBasic");
        } else {
          createOrder({
            orderType: "credit",
            name: "fabric",
            fabric: data.fabricDetails._id,
            companyName: data.delivery.companyName,
            deliveryAddress: data.delivery.deliveryAddress,
            state: data.delivery.state,
            pincode: data.delivery.pincode,
            contactNumber: data.delivery.contactNumber,
            modeOfTransport: data.delivery.modeOfTransport,
            optionalTransport: data.delivery.optionalTransport
              ? data.delivery.optionalTransport
              : "",
            qty: data.qty,
            totalAmount: data.finalAmount,
          }).then((res) => {
            console.log(res);
            if (res.orderType === "credit") {
              navigate("/credit");
            }
          });
        }
      }}
    ></Button>
  );

  return (
    <div className="mx-4">
      <Divider align="center">
        <Toast ref={toast}></Toast>
        <h2 className="p-text-center">Quotation</h2>
      </Divider>
      <Dialog
        header="Payment Alert"
        visible={displayBasic}
        style={{ width: "50vw" }}
        footer={renderFooter("displayBasic")}
        onHide={() => onHide("displayBasic")}
      >
        <p>Please Clear Your OutStanding Amount to Make New Orders</p>
      </Dialog>
      <div ref={orderRef}>
        <div className="p-fluid">
          <Panel header="Order Details">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  width: "50%",
                  justifyContent: "center",
                }}
              >
                <div style={{}}>
                  <p>
                    <strong>Date:</strong> {new Date().toString()}
                  </p>
                  <p>
                    <strong>To, Company Name:</strong> {userDetails.companyName}
                  </p>
                  <p>
                    <strong> Customer Name :</strong> {userDetails.name}
                  </p>
                  <p>
                    <strong> Contact Number:</strong> {userDetails.mobileNumber}
                  </p>
                  <p>
                    <strong> Address: </strong> {userDetails.address1}
                  </p>
                  <p>
                    <strong> From: (Batavia Exim)</strong>
                  </p>
                  <h3>
                    <strong style={{ color: "red" }}>
                      {" "}
                      Current OutStanding :{" "}
                    </strong>
                    {userDetails.currentOutstanding}Rs
                  </h3>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "50%",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: "60%" }}>
                  <p style={{}}>
                    <h2>Delivery Address</h2>
                  </p>
                  <p>
                    <strong>Company Name: </strong> {delivery.companyName}
                  </p>
                  <p>
                    <strong>Address: </strong> {delivery.deliveryAddress}
                  </p>
                  <p>
                    <strong> State: </strong> {delivery.state}
                  </p>
                  <p>
                    <strong> Pincode: </strong> {delivery.pincode}
                  </p>
                  <p>
                    <strong>Contact: </strong>
                    {delivery.contactNumber}
                  </p>
                  <p style={{ display: "flex" }}>
                    <strong>Transport:&nbsp; </strong>
                    {delivery.modeOfTransport === "Any Other" ? (
                      <p> {delivery.optionalTransport}</p>
                    ) : (
                      <p> {delivery.modeOfTransport}</p>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </Panel>
          <div className="card"></div>
        </div>
        <div style={{ textAlign: "center" }}>{FabricTable}</div>
      </div>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        {window.localStorage.getItem("userType") === "sales" ||
        window.localStorage.getItem("userType") === "accounts" ? (
          <Button
            style={{ width: "300px", margin: "10px", background: "#287F80" }}
            label="Place Order Behalf of Customer"
            onClick={(e) => {
              e.preventDefault();
              if (expiredOrders.length > 0) {
                onClick("displayBasic");
              } else {
                createOrderByAdmin(
                  {
                    orderType: "paynow",
                    name: "fabric",
                    fabric: data.fabricDetails._id,
                    companyName: data.delivery.companyName,
                    deliveryAddress: data.delivery.deliveryAddress,
                    state: data.delivery.state,
                    pincode: data.delivery.pincode,
                    contactNumber: data.delivery.contactNumber,
                    modeOfTransport: data.delivery.modeOfTransport,
                    optionalTransport: data.delivery.optionalTransport
                      ? data.delivery.optionalTransport
                      : "",
                    qty: data.qty,
                    totalAmount: data.finalAmount,
                  },
                  userDetails._id
                )
                  .then((res) => {
                    console.log("res", res);
                    toast.current.show({
                      severity: "success",
                      summary: "Order Placed",
                      detail:
                        "Your Order is Placed. You will be redirected to the Fabrics Page",
                    });
                    setTimeout(() => {
                      handlePrintQuotation();
                    }, 3000);
                  })
                  .catch((err) => {
                    toast.current.show({
                      severity: "error",
                      summary: "failed to place order!",
                      detail: "Your Order couldn't be placed try again!",
                    });
                  });
              }
            }}
          ></Button>
        ) : (
          <div
            style={{
              justifyContent: "space-between",
              marginTop: "30px",
              marginBottom: "100px",
            }}
          >
            {paynowButton}
            {console.log(paymentOption)}
            {paymentOption === "advance" && advanceButton}
            {paymentOption === "credit" && creditButton()}
          </div>
        )}
      </div>
    </div>
  );
};

export default FabricQuotation;
