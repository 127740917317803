import React, { useState, useEffect, createContext, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import MenuNavigator from "../../../components/MenuNavigator";
import { getUserDetails, updateUser } from "../../../api/api";
import { deleteUser } from "../../../api/api";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { MultiSelect } from "primereact/multiselect";
import { Checkbox } from "primereact/checkbox";

const UserDetails = () => {
  const toast = useRef(null);
  const [userInfo, setUserInfo] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [name, setName] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [address3, setAddress3] = useState(null);

  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);
  const [pincode, setPincode] = useState(null);
  const [GST, setGST] = useState(null);
  const [deliveryAddress1, setDeliveryAddress1] = useState(null);
  const [deliveryAddress2, setDeliveryAddress2] = useState(null);
  const [deliveryAddress3, setDeliveryAddress3] = useState(null);
  const [deliveryState, setDeliveryState] = useState(null);
  const [deliveryCountry, setDeliveryCountry] = useState(null);
  const [deliveryPincode, setDeliveryPincode] = useState(null);
  const [aadhar, setAadhar] = useState(null);
  const [discountPercentage, setDiscountPercentage] = useState(null);
  const [creditPeriod, setCreditPeriod] = useState(0);
  const [accessory, setAccessory] = useState(null);
  const [userType, setUserType] = useState(null);
  const [paymentOption, setPaymentOption] = useState(null);
  const [city, setCity] = useState("");
  const [deliveryCity, setDeliveryCity] = useState("");
  const [advancePercentage, setAdvancePercentage] = useState("");
  const [roles, setRoles] = useState(null);
  const [checked, setChecked] = useState(false);

  const rolls = ["Pricing", "Orders", "Products", "Settings"];

  let location = useLocation();
  const navigate = useNavigate();
  const id = location.pathname.split("/")[2];
  const userTypeOptions = [
    { name: "Admin", value: "admin" },
    { name: "Architect", value: "architect" },
    { name: "Retailer", value: "retailer" },
    { name: "Channel Partner", value: "channelPartner" },
    { name: "Accounts", value: "accounts" },
  ];

  const showUserDeleted = () => {
    toast.current.show({
      severity: "success",
      summary: "User Deleted!",
      detail: "You will be Redirected to All Users Page",
    });
  };
  const showuserUpdated = () => {
    toast.current.show({
      severity: "success",
      summary: "User Details Updated!",
      detail: "You will be Redirected to All Users Page",
    });
  };

  useEffect(() => {
    getUserDetails(location.pathname.split("/")[2]).then((data) => {
      setUserInfo(data);

      const userData = data;
      console.log(userData);
      setEmail(userData.email);
      setPassword(userData.password);
      setName(userData.name);
      setMobile(userData.mobileNumber);
      setCompanyName(userData.companyName);
      setAddress1(userData.address1);
      setAddress2(userData.address2);
      setAddress3(userData.address3);
      setCity(userData.city);
      setState(userData.state);
      setCountry(userData.country);
      setPincode(userData.pincode);
      setEmail(userData.email);
      setGST(userData.GST);
      setUserType(userData.userType);
      setDeliveryAddress1(userData.deliveryAddress1);
      setDeliveryAddress2(userData.deliveryAddress2);
      setDeliveryAddress3(userData.deliveryAddress3);
      setDeliveryCity(userData.deliveryCity);
      setDeliveryState(userData.deliveryState);
      setDeliveryCountry(userData.deliveryCountry);
      setDeliveryPincode(userData.deliveryPincode);
      setAadhar(userData.aadhar);
      setDiscountPercentage(userData.discountPercentage);
      setCreditPeriod(userData.creditPeriod);
      setAccessory(userData.accessory);
      setPaymentOption(userData.paymentOption);
      setAdvancePercentage(userData.advancePercentage);
      setRoles(userData?.roles);
      setChecked(userData?.is_made_to_measure);
    });
  }, [location]);

  return (
    // <Envelope>
    <div>
      <MenuNavigator />
      {userInfo !== null ? (
        <div>
          <Divider align="center">
            <Toast ref={toast}></Toast>
            <h2 className="p-text-center">
              User Details : {userInfo?.companyName}
            </h2>
          </Divider>

          {userInfo?.userType === "accounts" ||
          userInfo?.userType === "sales" ||
          userInfo?.userType === "admin" ? (
            <div
              className="p-fluid"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <div style={{ width: "35%" }}>
                <div className="p-field">
                  <label htmlFor="email">Email</label>
                  <InputText
                    disabled={disabled}
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="p-field">
                  <label htmlFor="name">Name</label>
                  <InputText
                    disabled={disabled}
                    id="name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="mobileNumber">Mobile Number</label>
                  <InputText
                    disabled={disabled}
                    id="mobileNumber"
                    type="text"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </div>

                <div className="p-field">
                  <label htmlFor="userType">User Type</label>
                  <Dropdown
                    id="userType"
                    name="userType"
                    disabled={disabled}
                    options={userTypeOptions}
                    optionLabel="name"
                    optionValue="value"
                    onChange={(e) => setUserType(e.value)}
                    placeholder="Select Type of User"
                    value={userType}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="roles">User Rolls</label>
                  <MultiSelect
                    options={rolls}
                    disabled={disabled}
                    placeholder="Select user roll"
                    id="roles"
                    display="chip"
                    value={roles}
                    onChange={(e) => {
                      setRoles(e.value);
                    }}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="accessory">Accessory</label>
                  <Dropdown
                    options={["Yes", "No"]}
                    id="accessory"
                    disabled={disabled}
                    value={accessory}
                    onChange={(e) => setAccessory(e.target.value)}
                  />
                </div>
                <div className="p-field flex align-items-center">
                  <Checkbox
                    onChange={(e) => setChecked(e.checked)}
                    checked={checked}
                    disabled={disabled}
                  />
                  <label className="ml-2 my-2">Made To Measure</label>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="p-fluid"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <div style={{ width: "35%" }}>
                <div className="p-field">
                  <label htmlFor="email">Email</label>
                  <InputText
                    disabled={disabled}
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="p-field">
                  <label htmlFor="name">Name</label>
                  <InputText
                    disabled={disabled}
                    id="name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="mobileNumber">Mobile Number</label>
                  <InputText
                    disabled={disabled}
                    id="mobileNumber"
                    type="text"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="companyName">Company Name</label>
                  <InputText
                    disabled={disabled}
                    id="companyName"
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>

                <div className="p-field">
                  <label htmlFor="address1"> Address(Line1)</label>
                  <InputText
                    id="address1"
                    type="text"
                    value={address1}
                    onChange={(e) => setAddress1(e.target.value)}
                    disabled={disabled}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="address2"> Address(Line2)</label>
                  <InputText
                    id="address2"
                    type="text"
                    value={address2}
                    onChange={(e) => setAddress2(e.target.value)}
                    disabled={disabled}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="address2"> Address(Line3)</label>
                  <InputText
                    id="address3"
                    type="text"
                    value={address3}
                    onChange={(e) => setAddress3(e.target.value)}
                    disabled={disabled}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="city">City</label>
                  <InputText
                    id="city"
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    disabled={disabled}
                  />
                </div>

                <div className="p-field">
                  <label htmlFor="state">State</label>
                  <InputText
                    id="state"
                    type="text"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    disabled={disabled}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="country">Country</label>
                  <InputText
                    id="country"
                    type="text"
                    value={country}
                    disabled={disabled}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="pincode">PinCode</label>
                  <InputText
                    id="pincode"
                    type="text"
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                    disabled={disabled}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="userType">User Type</label>
                  <Dropdown
                    id="userType"
                    name="userType"
                    disabled={disabled}
                    options={userTypeOptions}
                    optionLabel="name"
                    optionValue="value"
                    onChange={(e) => setUserType(e.value)}
                    placeholder="Select Type of User"
                    value={userType}
                  />
                  {/* <InputText disabled value={userInfo.userType} /> */}
                </div>
                <div className="p-field">
                  <label htmlFor="roles">User Rolls</label>
                  <MultiSelect
                    options={rolls}
                    disabled={disabled}
                    placeholder="Select user roll"
                    id="roles"
                    display="chip"
                    value={roles}
                    onChange={(e) => {
                      setRoles(e.value);
                    }}
                  />
                </div>
              </div>
              <div style={{ width: "35%" }}>
                <div className="p-field">
                  <label htmlFor="GST">GST Number</label>
                  <InputText
                    id="GST"
                    type="text"
                    value={GST}
                    onChange={(e) => setGST(e.target.value)}
                    disabled={disabled}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="deliveryAddress1">
                    Delivery Address(Line 1)
                  </label>
                  <InputText
                    id="deliveryAddress1"
                    type="text"
                    value={deliveryAddress1}
                    onChange={(e) => setDeliveryAddress1(e.target.value)}
                    disabled={disabled}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="deliveryAddress2">
                    Delivery Address(Line 2)
                  </label>
                  <InputText
                    id="deliveryAddress2"
                    type="text"
                    value={deliveryAddress2}
                    onChange={(e) => setDeliveryAddress2(e.target.value)}
                    disabled={disabled}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="deliveryAddress3">
                    Delivery Address(Line 3)
                  </label>
                  <InputText
                    id="deliveryAddress3"
                    type="text"
                    value={deliveryAddress3}
                    onChange={(e) => setDeliveryAddress3(e.target.value)}
                    disabled={disabled}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="deliveryCity">Delivery City</label>
                  <InputText
                    id="deliveryCity"
                    type="text"
                    value={deliveryCity}
                    onChange={(e) => setDeliveryCity(e.target.value)}
                    disabled={disabled}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="deliveryState">Delivery State</label>
                  <InputText
                    id="deliveryState"
                    type="text"
                    value={deliveryState}
                    onChange={(e) => setDeliveryState(e.target.value)}
                    disabled={disabled}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="deliveryCountry">Delivery Country</label>
                  <InputText
                    id="deliveryCountry"
                    type="text"
                    value={deliveryCountry}
                    onChange={(e) => setDeliveryCountry(e.target.value)}
                    disabled={disabled}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="deliveryPincode">Delivery PinCode</label>
                  <InputText
                    id="deliveryPincode"
                    type="text"
                    value={deliveryPincode}
                    onChange={(e) => setDeliveryPincode(e.target.value)}
                    disabled={disabled}
                  />
                </div>

                <div className="p-field">
                  <label htmlFor="aadhar">Aadhar number</label>
                  <InputText
                    id="aadhar"
                    value={aadhar}
                    onChange={(e) => setAadhar(e.target.value)}
                    type="text"
                    disabled={disabled}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="discountPercentage">
                    Discount Percentage(number){" "}
                  </label>
                  <InputText
                    id="discountPercentage"
                    type="number"
                    disabled={disabled}
                    value={discountPercentage}
                    onChange={(e) => setDiscountPercentage(e.target.value)}
                  />
                </div>

                <div className="p-field">
                  <label htmlFor="accessory">Accessory*</label>
                  <Dropdown
                    options={["Yes", "No"]}
                    id="accessory"
                    disabled={
                      userType === "architect" && !disabled ? false : true
                    }
                    value={accessory}
                    onChange={(e) => setAccessory(e.target.value)}
                  />
                </div>
                <div className="p-field">
                  <label htmlFor="paymentOption">Payment Type*</label>
                  <Dropdown
                    optionLabel="name"
                    optionValue="value"
                    disabled={disabled}
                    options={[
                      { name: "Pay Now", value: "paynow" },
                      { name: "Credit", value: "credit" },
                      { name: "Advance", value: "advance" },
                    ]}
                    id="paymentOption"
                    value={paymentOption}
                    onChange={(e) => setPaymentOption(e.value)}
                  />
                </div>
                <div className="p-field flex align-items-center">
                  <Checkbox
                    onChange={(e) => setChecked(e.checked)}
                    checked={checked}
                    disabled={disabled}
                  />
                  <label className="ml-2 my-2">Made To Measure</label>
                </div>
                {paymentOption === "credit" ? (
                  <div className="p-field my-3">
                    <label htmlFor="creditPeriod">
                      Credit Period (In number of days)*
                    </label>
                    <InputText
                      disabled={disabled}
                      id="creditPeriod"
                      type="number"
                      value={creditPeriod}
                      onChange={(e) => setCreditPeriod(e.target.value)}
                    />
                  </div>
                ) : (
                  ""
                )}
                {paymentOption === "advance" ? (
                  <div className="p-field my-3">
                    <label htmlFor="advancePercentage">
                      Advance Percentage (%)*
                    </label>
                    <InputText
                      disabled={disabled}
                      id="advancePercentage"
                      type="number"
                      value={advancePercentage}
                      onChange={(e) => setAdvancePercentage(e.target.value)}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              style={{ margin: "20px", width: "15%" }}
              label="Back"
              onClick={() => navigate("/users")}
              className="p-block p-button-danger text-center"
            ></Button>
            <Button
              style={{ margin: "20px", width: "15%" }}
              label="Edit"
              onClick={() => setDisabled(false)}
              className="p-block p-button-secondary text-center"
            ></Button>
            <Button
              style={{ margin: "20px", width: "15%", background: "#287F80" }}
              label="Update"
              onClick={() => {
                updateUser(id, {
                  email: email,
                  password: password,
                  name: name,
                  mobileNumber: mobile,
                  companyName: companyName,
                  address1: address1,
                  address2: address2,
                  address3: address3,
                  city: city,
                  state: state,
                  country: country,
                  pincode: pincode,
                  GST: GST,
                  deliveryAddress1: deliveryAddress1,
                  deliveryAddress2: deliveryAddress2,
                  deliveryAddress3: deliveryAddress3,
                  deliveryCity: deliveryCity,
                  deliveryState: deliveryState,
                  deliveryCountry: deliveryCountry,
                  deliveryPincode: deliveryPincode,
                  aadhar: aadhar,
                  discountPercentage: discountPercentage,
                  creditPeriod: creditPeriod,
                  userType: userType,
                  roles: roles,
                  is_made_to_measure: checked,
                  paymentOption,
                  accessory,
                  advancePercentage,
                }).then((data) => {
                  console.log(data);
                  if (data.companyName) {
                    showuserUpdated();
                    setTimeout(() => {
                      navigate("/users");
                    }, 2000);
                  }
                });
              }}
              className="p-block p-button-primary text-center"
            ></Button>
            <Button
              style={{ margin: "20px", width: "15%" }}
              label="Delete"
              onClick={() => {
                deleteUser(id).then((data) => {
                  console.log(data);
                  if (data.msg) {
                    showUserDeleted();
                    setTimeout(() => {
                      navigate("/users");
                    }, 2000);
                  }
                });
              }}
              className="p-block p-button-danger text-center"
            ></Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default UserDetails;
