import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import MenuNavigator from "../../../components/MenuNavigator";
import { SplitButton } from "primereact/splitbutton";
import { getAllUsers, searchUsers } from "../../../api/api";

const ListOfAllUsers = () => {
  const navigate = useNavigate();
  const [totalRecords, setTotalRecords] = useState(0);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [userList, setUserList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [userType, setUserType] = useState("User Type");
  const [userType2, setUserType2] = useState("User Type");

  useEffect(() => {
    getAllUsers(currentPage, 10, userType2,null).then((data) => {
      console.log("data", data);
      setUserList(data.allUsers);
      setTotalRecords(data.userCount);
    });
  }, [currentPage, userType2]);
  useEffect(() => {
// if(searchTerm.length>=0){
  searchUsers(searchTerm).then((data) => {
    console.log("data", data);
    setUserList(data.allUsers);
    setTotalRecords(data.userCount);
  });
// }
  }, [searchTerm]);
  const items = [
    {
      label: "Admin",
      command: () => {
        setUserType("Admin");
        setUserType2("admin");
      },
    },
    {
      label: "Architect",
      command: () => {
        setUserType("Architect");
        setUserType2("architect");
      },
    },
    {
      label: "Agent",
      command: () => {
        setUserType("Agent");
        setUserType2("agent");
      },
    },
    {
      label: "Retailer",
      command: () => {
        setUserType("Retailer");
        setUserType2("retailer");
      },
    },
    {
      label: "Channel Partner",
      command: () => {
        setUserType("Channel Partner");
        setUserType2("channelPartner");
      },
    },
    {
      label: "Sales",
      command: () => {
        setUserType("Sales");
        setUserType2("sales");
      },
    },
    {
      label: "Accounts",
      command: () => {
        setUserType("Accounts");
        setUserType2("accounts");
      },
    },
  ];
  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setBasicRows(event.rows);
    setCurrentPage(event.page+1);
  };
  return (
    <div>
      <MenuNavigator />
      <Divider align="center">
        <h2 className="p-text-center">All Users</h2>
      </Divider>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          style={{ background: "#287F80", height: "35px" }}
          label="Add new User"
          // className="p-block  p-mb-2"

          onClick={() => {
            navigate("/users/new");
          }}
        />
        <div
          className="col-12"
          style={{
            width: "45%",
            display: "flex",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <SplitButton
            label={userType}
            icon="pi pi-filter"
            model={items}
            style={{
              width:
                userType === "Channel Partner" || userType === "Common Man"
                  ? "400px"
                  : "300px",
            }}
          />
          <div className="p-inputgroup" style={{ height: "35px" }}>
            <InputText
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search By Name, Email, State, Country"
            />
            <Button style={{ background: "#287F80" }} label="Search" />
          </div>
        </div>
      </div>

      {/* <div className="p-fluid"></div> */}
      <div className="card">
        <DataTable
          value={userList}
          responsiveLayout="scroll"
          sortable
          filterDisplay
          stripedRows
          showGridlines
          rowHover
          onRowClick={(event) => {
            navigate(`/users/${event.data._id}`);
          }}
        >
          <Column field="companyName" header="Company"></Column>
          <Column field="name" header="Name"></Column>
          <Column field="userType" header="User Type"></Column>
          <Column field="email" header="Email"></Column>
          <Column field="creditPeriod" header="Credit Period"></Column>
          <Column field="mobileNumber" header="Mobile"></Column>
          <Column field="state" header="State"></Column>
          <Column field="country" header="Country"></Column>
          <Column
            field="expired_credit_period_orders.length"
            header="Expired Orders"
          ></Column>
        </DataTable>
          <Paginator
            first={basicFirst}
            totalRecords={totalRecords}
            rows={basicRows}
            onPageChange={onBasicPageChange}
          ></Paginator>
      </div>
    </div>
  );
};

export default ListOfAllUsers;
