import React, { useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

function CreditThisMonthChart({ creditlastMonth, creditThisMonth }) {
  const thisMonth = parseInt(creditThisMonth);
  const lastMonth = parseInt(creditlastMonth);
  ChartJS.register(ArcElement, Tooltip, Legend);
  const data = {
    labels: ["This Month", "Last Month"],
    datasets: [
      {
        label: "This Month",
        data: [thisMonth, lastMonth],
        backgroundColor: ["#FF6384", "#36A2EB"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div
      style={{
        boxShadow: "0px 0px 20px rgb(0,0,0,0.2)",
        borderRadius: "10px",
        margin: "10px",
      }}
      className="card justify-content-center w-6 "
    >
      <div className="my-4 text-2xl text-center ">Credit This Month</div>
      <Doughnut data={data} />
      <div className="m-2 flex flex-column align-items-center justify-content-center">
        <div>Last Month : {creditThisMonth}</div>
        <div>This Month : {creditlastMonth}</div>
      </div>
    </div>
  );
}

export default CreditThisMonthChart;
