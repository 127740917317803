import React, { useEffect, useState } from "react";
import MenuNavigator from "../../../components/MenuNavigator";
import SaleCard from "./components/SaleCard";
import convertToINR from "../../admin/dashboard/components/convertToINR";
import { getUserDashboardData } from "../../../api/api";
import RecentOrders from "./components/RecentOrders";
function UsersDashboard() {
  const [dashboardData, setDashboardData] = useState([]);

  useEffect(() => {
    getUserDashboardData().then((res) => setDashboardData(res));
  }, []);

  console.log("userDashboard", dashboardData);
  return (
    <div>
      <MenuNavigator />
      <div className="flex border-round  p-3 m-3">
        <div className="w-5 flex flex-wrap align-items-center justify-content-center">
          <SaleCard
            color="#2575fc"
            text="Pending Payment"
            data={convertToINR(
              dashboardData.pendingPayment
                ? dashboardData.pendingPayment.toFixed(0)
                : 0
            )}
          />
          <SaleCard
            color="#f43b47"
            text="Fabrics Sales"
            data={convertToINR(
              dashboardData.totalFabricSales
                ? dashboardData.totalFabricSales.toFixed(0)
                : 0
            )}
          />
          <SaleCard
            color="#f9d423"
            text="Catalogue Sales"
            data={convertToINR(
              dashboardData.totalCatalogueSales
                ? dashboardData.totalCatalogueSales.toFixed(0)
                : 0
            )}
          />
          <SaleCard
            color="#517fa4"
            text="MTM Sales"
            data={convertToINR(
              dashboardData.totalMtmSales
                ? dashboardData.totalMtmSales.toFixed(0)
                : 0
            )}
          />
        </div>
        <div
          style={{
            boxShadow: "0px 0px 20px rgb(0,0,0,0.2)",
            borderRadius: "10px",
            margin: "10px",
          }}
          className="w-7 "
        >
          <div className="text-center">
            <div className="m-3 text-xl font-bold text-center ">
              Recent Uploads
            </div>
            <div className=" flex align-items-center justify-content-evenly">
              <div className="flex flex-column">
                <strong className="m-1"> Catalogue</strong>
                <img
                  style={{
                    height: "300px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "270px",
                    objectFit: "contain",
                  }}
                  alt=""
                  src={dashboardData.catalogueImage}
                />{" "}
              </div>
              <div className="flex flex-column">
                <strong className="m-1"> Fabric</strong>
                <img
                  style={{
                    height: "300px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "270px",
                    objectFit: "contain",
                  }}
                  alt=""
                  src={dashboardData.fabricImage}
                />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-2 mx-6 text-2xl font-bold ">Statistics</div>
      <div className="mx-5">
        <RecentOrders orders={dashboardData.allOrders?.slice(0, 5)} />
      </div>
    </div>
  );
}

export default UsersDashboard;
