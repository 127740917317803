import React from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import MenuNavigator from "../../components/MenuNavigator";
import { useNavigate } from "react-router-dom";

const Credit = () => {
  const navigate = useNavigate();
  return (
    <div>
      <MenuNavigator />
      <div style={{ textAlign: "center",marginTop:"100px" }}>
        <img style={{ height: "350px" }} alt="" src="https://img.freepik.com/free-vector/order-confirmed-concept-illustration_114360-1449.jpg?t=st=1649155271~exp=1649155871~hmac=bedfce3f53c58b2f515f57403e82626ed3ab20bd9367eb60f67826fce3fc48ac&w=900" />
        <div style={{ fontSize: "30px", marginTop: "10px", fontWeight: "600" }}>
          Your Order is Placed!
        </div>
        <div style={{ fontSize: "20px", margin: "10px" }}>
          Pay the Credit Amout within 30 Days
        </div>

        <Button
        style={{background: "#287F80"}}
          onClick={() => {
            navigate("/fabric");
          }}
        >
          Go to Home
        </Button>
      </div>
    </div>
  );
};
export default Credit;
